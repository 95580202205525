import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { useForm } from 'react-hook-form';
import { FaTimes } from 'react-icons/fa';
import { ReactComponent as ImageAddIcon } from '../../assets/svg/ImageAddIcon.svg';
import { Button, TextField } from '@mui/material';
import { createFont, getImage, updateFont, uploadFile, uploadFontFile } from '../../server/server';
import { baseUrl } from '../../server/connections';
import { checkAllowedExtensionsForFonts } from '../../Constants';

function AddFontsModal({ modalIsOpen, closeModal, font, reloadFonts }: any) {
	const [image, setImage] = useState<string | undefined>(undefined);
	const [selectedFile, setSelectedFile] = useState<any>(undefined);
	const [removedPhoto, setRemovedPhoto] = useState(false);
	const [processing, setProcessing] = useState(false);

	const { register, handleSubmit, reset, formState, getValues, setValue } = useForm({
		defaultValues: {
			name: '',
		},
	});
	const { isDirty, isValid, errors } = formState;

	useEffect(() => {
		if (modalIsOpen) {
			ReactModal.setAppElement('#root');
		}
	}, [modalIsOpen]);

	useEffect(() => {
		if (!!font?.id) {
			setValue('name', font.name);
			if (!!font.image) {
				fetchImg(font.image);
			}
		}
	}, [font]);

	const fetchImg = async (imgUrl: string) => {
		await fetch(baseUrl + '/product/file/' + imgUrl)
			.then((res) => res.blob())
			.then((blob) => {
				const objectUrl = URL.createObjectURL(blob);
				setImage(objectUrl);
				return () => {
					console.log('remove img');
					URL.revokeObjectURL(objectUrl);
				};
			});
	};

	useEffect(() => {
		if (!selectedFile) {
			setImage(undefined);
			console.log('undefined img');
			return;
		}
		console.log('set img selected file je: ', selectedFile);
		const objectUrl = URL.createObjectURL(selectedFile);
		setImage(objectUrl);
		setRemovedPhoto(false);
		// return () => {
		// 	console.log("remove img trigerovan u create conteern modelu");
		// 	URL.revokeObjectURL(objectUrl);
		// };
	}, [selectedFile]);

	const press_upload = () => {
		document.getElementById('photo_upload_modal')?.click();
	};

	const closeImage = () => {
		setImage(undefined);
		setSelectedFile(undefined);
		setRemovedPhoto(true);
	};

	const onImageChange = (e: any) => {
		console.log(image);
		if (!e.target.files || e.target.files.length === 0) {
			setSelectedFile(undefined);
			return;
		}
		let file = e.target.files[0];
		if (file.size > 15728640) {
			alert('File is too big!');
			setSelectedFile(undefined);
			return;
		}
		console.log('Selected file : ', file);
		if (!checkAllowedExtensionsForFonts(file.name)) {
			alert('Type of file is not allowed!');
			return;
		}
		setSelectedFile(file);
	};
	const resetInputValue = (e: any) => {
		e.target.value = null;
	};

	const onSubmit = async () => {
		if (!!selectedFile) {
			const resp = await uploadFontFile(selectedFile);
			if (resp.status == 201) {
				if (!!font?.id) {
					let data = {
						id: font.id,
						name: getValues('name'),
						fileId: resp.data.id,
					};
					editFont(data);
				} else {
					let data = {
						name: getValues('name'),
						fileId: resp.data.id,
					};
					createNewFont(data);
				}
			} else {
				return;
			}
		} else if (!!image && !!font?.id) {
			let data = {
				id: font.id,
				name: getValues('name'),
				fileId: font?.image,
			};
			editFont(data);
		}
	};
	const createNewFont = async (data: any) => {
		const respSymbol = await createFont(data);
		if (respSymbol.status === 201) {
			closeModalAndReloadFonts();
		}
	};
	const editFont = async (data: any) => {
		const respSymbol = await updateFont(data);
		if (respSymbol.status === 201) {
			closeModalAndReloadFonts();
		}
	};

	const closeModalAndReloadFonts = () => {
		reloadFonts();
		closeModal();
	};

	return (
		<ReactModal
			ariaHideApp={false}
			isOpen={modalIsOpen}
			onAfterOpen={() => {}}
			onAfterClose={() => {
				reset();
				closeImage();
			}}
			onRequestClose={closeModal}
			className="no-scroll"
			style={{
				overlay: {
					zIndex: 5,
					backdropFilter: 'brightness(30%)',
				},
				content: {
					padding: 0,
					opacity: 1,
					width: 600,
					margin: 'auto',
					backgroundColor: 'transparent',
					height: '100%',
					maxHeight: '100%',
					border: 'none',
					boxShadow: 'none',
					overflow: 'auto',
					paddingTop: 15,
					paddingBottom: 15,
				},
			}}
		>
			<div className="   max-h-[600px] w-full  ">
				<form onSubmit={handleSubmit(onSubmit)} className=" flex flex-1 flex-col gap-5 rounded-[10px] bg-babyBlue  px-[40px] py-[30px] ">
					<div className="flex w-full flex-col ">
						<p className="text-[30px] font-semibold leading-[38px] text-black">{!!font?.id ? 'Edit font' : 'Add new font'}</p>
					</div>
					<div className="">
						<TextField {...register('name', { required: true })} style={{ width: '100%' }} id="filled-required" label="Font name" defaultValue="Enter font name" variant="filled" />
					</div>
					<div className=" mt-5 items-center">
						<div className="flex flex-col rounded-xl py-4">
							<p className=" text-base text-black">Upload font file:</p>
							<div className="bg-white flex flex-row items-center gap-3 p-2 rounded-md mt-2">
								<Button
									variant="outlined"
									onClick={(e: any) => {
										e.preventDefault();
										press_upload();
									}}
									style={{ backgroundColor: '#eaeaea', color: '#777', border: '1px solid #777777' }}
								>
									Choose file
								</Button>

								<p>{!!selectedFile?.name ? selectedFile.name : !!image ? '' : 'No file chosen'}</p>
							</div>
							<p className="mt-3 text-base text-black">Supported font file extensions are: OTF, TTF, WOFF, WOFF2</p>
						</div>

						<input title="Add Video/Photo" style={{ display: 'none' }} accept=".otf, .ttf" type="file" id="photo_upload_modal" onChange={onImageChange} onClick={resetInputValue} />
					</div>
					<div className="flex w-full flex-row justify-end gap-8 mt-10  ">
						<Button
							variant="contained"
							onClick={(e: any) => {
								e.preventDefault();
								closeModal();
							}}
							style={{ backgroundColor: '#2ec4b6', color: 'white' }}
						>
							Cancel
						</Button>
						<Button variant="contained" type="submit" style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
							Submit
						</Button>
					</div>
				</form>
			</div>
		</ReactModal>
	);
}

export default AddFontsModal;
