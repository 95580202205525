import { Button, TextField } from '@mui/material';
import React from 'react';
import { FaBorderAll, FaTable } from 'react-icons/fa';

function MediaNavbar({
	isBulk,
	isGrid,
	handleBulkSelect,
	handleIsGrid,
	rowSelectionModel,
	deleteSelectedFiles,
	searchQuery,
	handleSearchQueryChange,
}: {
	isBulk: boolean;
	isGrid: boolean;
	handleBulkSelect: any;
	handleIsGrid: any;
	rowSelectionModel: any;
	deleteSelectedFiles: any;
	searchQuery: string;
	handleSearchQueryChange: any;
}) {
	return (
		<div className="flex flex-row items-center justify-between border border-[#aeaeae] p-2 mt-5 shadow-md">
			<div className="flex flex-row items-center gap-5 ml-5">
				{!isBulk && (
					<div className="cursor-pointer " title="Table view" onClick={() => handleIsGrid(false)}>
						<FaTable size={'30px'} color={isGrid ? '#aeaeae' : '#21187F'} />
					</div>
				)}
				{!isBulk && (
					<div className="cursor-pointer " title="Grid view" onClick={() => handleIsGrid(true)}>
						<FaBorderAll size={'30px'} color={isGrid ? '#21187F' : '#aeaeae'} />
					</div>
				)}
				{!isBulk && isGrid && (
					<div>
						<Button variant="outlined" onClick={() => handleBulkSelect(true)} style={{ backgroundColor: '#eaeaea', color: '#777', border: '1px solid #777777' }}>
							Bulk select
						</Button>
					</div>
				)}
				{isBulk && (
					<div>
						<Button variant="outlined" onClick={() => handleBulkSelect(false)} style={{ backgroundColor: '#eaeaea', color: '#777', border: '1px solid #777777' }}>
							Cancel bulk select
						</Button>
					</div>
				)}
			</div>
			<div className="flex flex-row items-center gap-5">
				{rowSelectionModel.length > 0 && (
					<div onClick={() => deleteSelectedFiles()}>
						<Button variant="contained" onClick={() => {}} style={{ backgroundColor: '#D24D57', color: 'white' }}>
							Delete selected files
						</Button>
					</div>
				)}
				<TextField
					label="Search"
					value={searchQuery}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						handleSearchQueryChange(event);
					}}
				/>
			</div>
		</div>
	);
}

export default MediaNavbar;
