import React, { useEffect, useState } from 'react';
import { countries, fullCountryCodes, fullCountryNames } from '../../Constants';
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { async } from 'q';
import { createCountries, createZone, editZone, getCountries, getCurrencies, getImage, getZoneById, uploadFile } from '../../server/server';
import { Button, FormHelperText, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useLocation, useParams } from 'react-router-dom';
import ShippingMethodsList from './ShippingMethodsList';
import { Currency } from '../../model/currency';
import Loading from '../../common/Loading';
import AddShippingOptionModal from './AddShippingOptionModal';
import { set } from 'date-fns';
import AddProductsImageModal from '../products/new-product/AddProductsImageModal';
import { image } from '@uiw/react-md-editor';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

function getStyles(name: string, personName: string[], theme: Theme) {
	return {
		fontWeight: personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
	};
}
function ZoneScreen() {
	const theme = useTheme();
	let { id } = useParams();
	const [selectedCountriesList, setSelectedCountriesList] = useState<string[]>([]);
	const [zone, setZone] = useState<any>(null);
	const [countries, setCountries] = useState<any[]>([]);
	const [currencies, setCurrencies] = useState<Currency[]>([]);
	const [selectedCurrency, setSelectedCurrency] = useState<any>('');
	const [processing, setProcessing] = useState(false);
	const [addOptionModalOpen, setAddOptionModalOpen] = useState(false);
	const [selectedOption, setSelectedOption] = useState(null);
	const [imageId, setImageId] = useState('');
	const [fileModalOpen, setFileModalOpen] = useState(false);
	const { register, handleSubmit, reset, formState, watch, getValues, setValue } = useForm({
		defaultValues: {
			zone_name: '',
			taxPercentage: '0.0',
		},
	});
	const { isDirty, isValid, errors } = formState;
	const zone_name = watch('zone_name');
	const taxPercentage = watch('taxPercentage');

	useEffect(() => {
		if (id != 'new') loadZone();
		loadCountries();
		loadCurrencies();
	}, []);

	useEffect(() => {
		if (zone?.id) {
			setValue('zone_name', zone.name);
			setValue('taxPercentage', zone.taxPercentage);
		}
	}, [zone]);

	const loadCurrencies = async () => {
		try {
			const response = await getCurrencies();
			setCurrencies(response.data);
		} catch (error) {
			console.error('Error fetching currencies:', error);
		}
	};

	const loadZone = async () => {
		const resp = await getZoneById(!!zone ? zone.id : id);
		if (resp.status === 200) {
			let zoneData = resp.data;
			setZone(zoneData);
			setSelectedCountriesList(zoneData?.countries?.map((item: any) => item.name));
			setSelectedCurrency(zoneData?.currency?.id);
			setImageId(zoneData?.icon?.id);
		}
	};
	const loadCountries = async () => {
		const resp = await getCountries();
		if (resp.status === 200) {
			if (resp.data?.length === 0) {
				//automatski popunjava bazu sa scriptom countries koja je na backu todo izbaciti kad dodje vreme!!!
				const response = await createCountries([]);
				if (response.status === 201) {
					setCountries(response.data);
				}
			} else {
				setCountries(resp.data);
			}
		}
	};

	const handleChange = (event: SelectChangeEvent<typeof selectedCountriesList>) => {
		const {
			target: { value },
		} = event;
		setSelectedCountriesList(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value.split(',') : value
		);
	};
	const handleCurrencyChange = (currencyId: string) => {
		setSelectedCurrency(currencyId);
	};

	const onSubmit = async () => {
		if (!selectedCurrency) {
			alert('You have not selected a currency');
			return;
		}
		let selectedCountries: any[] = [];
		countries.map((item: any) => {
			selectedCountriesList.map((name: string) => {
				if (item.name === name) {
					selectedCountries.push(item);
				}
			});
		});
		const data: any = {
			zone_name: getValues('zone_name'),
			countries: selectedCountries,
			currency: selectedCurrency,
			taxPercentage: parseFloat(taxPercentage),
			iconId: imageId,
		};
		if (!zone?.id) {
			createNewZone(data);
		} else {
			data.id = zone?.id;
			updateZone(data);
		}
	};
	const createNewZone = async (data: any) => {
		setProcessing(true);
		const resp = await createZone(data);
		if (resp.status === 201) {
			setZone(resp.data);
			setProcessing(false);
			alert('The zone has been successfully created.');
		} else {
			setProcessing(false);
		}
	};

	const updateZone = async (data: any) => {
		setProcessing(true);
		const resp = await editZone(data);
		if (resp.status === 201) {
			setZone(resp.data);
			setProcessing(false);
			alert('The zone has been successfully updated.');
		} else {
			setProcessing(false);
		}
	};

	const closeAddOptionModal = () => {
		setSelectedOption(null);
		setAddOptionModalOpen(false);
	};

	const openAddShippingOptionsModal = (option: any) => {
		setSelectedOption(option);
		setAddOptionModalOpen(true);
	};

	const handleImageChange = async (file: any) => {
		const resp = await uploadFile({ file: file });
		if (resp.status === 201) {
			setFileModalOpen(false);
			setImageId(resp.data.id);
		}
	};

	const openAddFileModal = () => {
		setFileModalOpen(true);
	};

	return (
		<div className="py-[30px] px-2 overflow-auto gray-small-scroll h-full">
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="grid grid-cols-6 gap-5">
					<div className="flex flex-row items-center">
						<p>Zone name</p>
					</div>
					<div className="col-span-5 ">
						<TextField className=" background-transparent " value={zone_name} {...register('zone_name', { required: 'Field is required' })} helperText={errors.zone_name?.message} error={!!errors.zone_name} style={{ width: 700 }} label="Zone name" variant="filled" />
					</div>
				</div>
				<div className="grid grid-cols-6 gap-5">
					<div className="flex flex-row items-center">
						<p>Tax percentage</p>
					</div>
					<div className="flex items-center gap-1">
						<TextField className=" background-transparent " value={taxPercentage} {...register('taxPercentage', { required: 'Field is required' })} helperText={errors.taxPercentage?.message} error={!!errors.taxPercentage} style={{ width: 150 }} label="Tax percentage" variant="filled" />
						<span className="pt-3 text-[#6f6f6f]">%</span>
					</div>
				</div>
				<div className="mt-10 grid grid-cols-6 gap-5">
					<div className="flex flex-row items-center">
						<p>Zone countries</p>
					</div>
					<div className="col-span-5 ">
						<FormControl sx={{ width: '100%' }}>
							<InputLabel id="demo-multiple-name-label">Name</InputLabel>
							<Select labelId="demo-multiple-name-label" id="demo-multiple-name" multiple value={selectedCountriesList} onChange={handleChange} input={<OutlinedInput label="Name" />} MenuProps={MenuProps}>
								{countries.map((country: any) => (
									<MenuItem key={country.id} value={country.name} style={getStyles(country.name, selectedCountriesList, theme)}>
										{country.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
				</div>
				<div className="mt-10 grid grid-cols-6 gap-5">
					<div className="flex flex-row items-center">
						<p>Zone Icon</p>
					</div>
					<div className="col-span-5 ">
						<FormControl>
							<div className="flex flex-row items-top gap-[20px]">
								<div>
									<Button onClick={openAddFileModal} variant="contained" color="primary" size="medium" className="w-full py-2 text-l font-bold min-h-[50px]">
										Add zone icon
									</Button>
									<FormHelperText>Choose a icon for the zone</FormHelperText>
								</div>
								<div>
									{imageId && (
										<div className="mt-2">
											<img src={getImage(imageId)} className="object-contain w-[60px]" alt="Selected Flag" />
										</div>
									)}
								</div>
							</div>
						</FormControl>
						<AddProductsImageModal modalIsOpen={fileModalOpen} closeModal={() => setFileModalOpen(false)} setSelectedFileToList={handleImageChange} isWithinLanguageModal={true} />
					</div>
				</div>
				<div className="mt-10 grid grid-cols-6 gap-5">
					<div className="flex flex-row items-center">
						<p>Currency</p>
					</div>
					<div className="col-span-5 ">
						<div className="w-[150px]">
							<FormControl fullWidth>
								<InputLabel>Currency</InputLabel>
								<Select label="Currency" value={selectedCurrency} onChange={(e: any) => handleCurrencyChange(e.target.value)}>
									{currencies.map((currency) => (
										<MenuItem key={currency.id} value={currency.id}>
											{currency?.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</div>
					</div>
				</div>
				<div className="mt-10 grid grid-cols-6 gap-5">
					<div className="flex flex-row items-center">
						<p>Shipping methods</p>
					</div>
					<div className="col-span-5 ">
						<ShippingMethodsList zone={zone} reloadOptions={loadZone} openAddShippingOptionsModal={openAddShippingOptionsModal} />
					</div>
				</div>
				<div className="flex  w-full flex-col mt-10 border border-mediumGrey rounded-md p-[30px] gap-5">
					<div className="flex flex-row items-end justify-end gap-10">
						<Button variant="contained" onClick={() => window.history.back()} style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
							Go back
						</Button>
						<Button variant="contained" disabled={processing} type="submit" style={{ backgroundColor: '#2ec4b6', color: 'white', minWidth: 100 }}>
							{processing ? <Loading height={18} color="#ffffff" /> : <p>Submit</p>}
						</Button>
					</div>
				</div>
			</form>
			<AddShippingOptionModal modalIsOpen={addOptionModalOpen} closeModal={closeAddOptionModal} zone={zone} option={selectedOption} reloadOptions={loadZone} />
		</div>
	);
}

export default ZoneScreen;
