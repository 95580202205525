import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './assets/fonts/fonts.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import AppProviders from './components/AppProvider';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const env = process.env.REACT_APP_ENV;
console.log(`ENV: ${env}`);

root.render(
	<AppProviders>
		<BrowserRouter>
			<App />
			<span style={{ fontFamily: 'Amarante' }}></span>
		</BrowserRouter>
	</AppProviders>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
