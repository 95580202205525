import { DataGrid } from '@mui/x-data-grid';
import React, { useState } from 'react';

function FontPicker({ fonts, onlyOneAllowed = false, setSelectedFonts = () => {}, selectedFonts }: { fonts: any[]; onlyOneAllowed?: boolean; setSelectedFonts: (font: any) => void; selectedFonts: any[] }) {
	const selectFont = (font: any) => {
		if (onlyOneAllowed) {
			setSelectedFonts([font]);
		} else {
			if (selectedFontsId.includes(font.id)) {
				if (selectedFonts.length > 1) setSelectedFonts(selectedFonts.filter((e) => e.id !== font.id));
			} else {
				setSelectedFonts([...selectedFonts, font]);
			}
		}
	};

	const selectedFontsId = selectedFonts.map((e) => e.id);
	return (
		<div>
			<div>
				<p>Choose fonts</p>
			</div>
			<div className="border overflow-hidden rounded border-[#c4c4c4] mt-[10px]">
				<table className=" w-full rounded">
					<thead className="rounded">
						<tr className="border-b rounded border-b-[#c4c4c4]">
							<th className="w-4  p-4"></th>
							<th className=" text-left p-4">Name</th>
						</tr>
					</thead>
					<tbody>
						{fonts.map((font) => (
							<tr key={font.id} className="cursor-pointer hover:bg-[#c4c4c466] transition-all ease-in-out duration-200" style={{ fontFamily: font.name }} onClick={() => selectFont(font)}>
								<td className="w-4 p-4">
									<div className="w-4 h-4 border rounded flex items-center justify-center">{selectedFontsId.includes(font.id) && <div className=" w-[10px] h-[10px] bg-black rounded-[3px]"></div>}</div>
								</td>
								<td className="px-4 py-2">{font.name}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default FontPicker;
