import { Button, FormControlLabel, Switch, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ReactComponent as DeleteIcon } from '../../assets/svg/DeleteIcon.svg';
import { FaEdit, FaTimes } from 'react-icons/fa';
import { DragDropContext } from 'react-beautiful-dnd';
import { Droppable } from 'react-beautiful-dnd';
import { Draggable } from 'react-beautiful-dnd';
import AddShippingOptionModal from './AddShippingOptionModal';
import { ShippingOptionType } from '../../model/enums';
import { deleteShippingOptionById, editShippingOption, updateShippingOptionsList } from '../../server/server';

function ShippingMethodsList({ zone, reloadOptions, openAddShippingOptionsModal }: { zone: any; reloadOptions: any; openAddShippingOptionsModal: any }) {
	const [methodsList, setMethodsList] = useState([]);
	const [selectedOption, setSelectedOption] = useState(null);

	const openAddShippingOptionModal = (option?: any) => {
		if (!zone) {
			alert('Zone is not created!');
			return;
		}
		if (!!option) openAddShippingOptionsModal(option);
		else openAddShippingOptionsModal(null);
	};

	useEffect(() => {
		if (!!zone) setMethodsList(zone?.shippingOptions?.sort((a: any, b: any) => a.ordinal - b.ordinal));
	}, [zone]);

	const editOptionsList = async (data: any) => {
		const resp = await updateShippingOptionsList(data);
		if (resp.status === 201) {
			console.log('zone ', resp.data);
			reloadOptions();
		}
	};

	const deleteShippingOption = async (id: string) => {
		if (window.confirm('Do you want to delete the shipping option?')) {
			const resp = await deleteShippingOptionById(id);
			if (resp.status === 200) {
				reloadOptions();
			}
		}
	};

	const updateShippingOption = (item: any) => {
		let newItem = { ...item, used: !item.used, zoneId: zone?.id };
		delete newItem['deletedAt'];
		editOption(newItem);
	};

	const editOption = async (data: any) => {
		const resp = await editShippingOption(data);
		if (resp.status === 201) {
			console.log('zone ', resp.data);
			reloadOptions();
		}
	};

	const handleDrop = (droppedItem: any) => {
		// Ignore drop outside droppable container
		if (!droppedItem.destination) return;
		var updatedList = [...methodsList];
		// Remove dragged item
		const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
		// Add dropped item
		updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
		let newReorderList: any = updatedList.map((item: any, index: number) => {
			return { ...item, ordinal: index + 1 };
		});
		// Update State
		editOptionsList(newReorderList);
		setMethodsList(newReorderList);
	};

	return (
		<div className="flex  w-full flex-col mt-10 border border-mediumGrey rounded-md p-[30px] gap-5 ">
			<div className=" w-full flex flex-row gap-5 ">
				<div className="flex  h-full  flex-col  w-full ">
					<div className="flex w-full flex-row items-center justify-between">
						<p className="text-darkGrey text-xl px-2">List of shipping methods </p>
						<Button variant="contained" onClick={() => openAddShippingOptionModal()} style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
							Add New
						</Button>
					</div>
					<div className=" mt-3 max-h-[350px] overflow-auto gray-small-scroll pb-[15px] pr-2">
						{methodsList?.length !== 0 && (
							<div className="grid grid-cols-12 gap-1 ">
								<p className="col-span-1 text-darkGrey text-lg">Position</p>
								<p className="col-span-3 text-darkGrey text-lg">Title</p>
								<p className="col-span-1 text-darkGrey text-lg">Enabled</p>
								<p className="col-span-5 text-darkGrey text-lg">Description</p>
								<p className="col-span-2 text-darkGrey text-lg">Estimated delivery time</p>
							</div>
						)}
						<DragDropContext onDragEnd={handleDrop}>
							<Droppable droppableId="list-container">
								{(provided) => (
									<div {...provided.droppableProps} ref={provided.innerRef}>
										{methodsList?.map((item: any, index: number) => {
											return (
												<Draggable key={index.toString()} draggableId={index.toString()} index={index}>
													{(provided) => (
														<div className="grid grid-cols-12 gap-1 bg-white transition-colors hover:bg-babyBlue  border border-mediumGrey mt-2 p-1" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
															<div className="flex flex-row items-center pl-4">{index + 1}</div>
															<div className="flex flex-row items-center justify-between col-span-3 p-[6px]">
																<p>{item.name}</p>
																<div className="cursor-pointer mr-5 flex flex-row items-center gap-5">
																	<div></div>
																	<div onClick={() => openAddShippingOptionModal(item)}>
																		<FaEdit style={{ width: 20, height: 20 }} />
																	</div>
																	<div onClick={() => deleteShippingOption(item.id)}>
																		<DeleteIcon />
																	</div>
																</div>
															</div>
															<div className="col-span-1 text-darkGrey text-lg flex flex-row items-center">
																<FormControlLabel control={<Switch checked={item.used} onChange={(e: any) => updateShippingOption(item)} />} label="" />
															</div>
															<div className="col-span-5 text-darkGrey text-lg flex flex-col gap-[2px]">
																<p>{ShippingOptionType[item.type]}</p>
																<p>{item.description}</p>
															</div>
															<div className="col-span-2 text-darkGrey text-lg flex flex-col gap-[2px]">
																<p>{item.estimatedTime}</p>
															</div>
														</div>
													)}
												</Draggable>
											);
										})}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</DragDropContext>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ShippingMethodsList;
