import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import { Button, TextField } from '@mui/material';
import Loading from '../../../common/Loading';
import { checkAllowedExtensions } from '../../../Constants';

function AddProductsImageModal({ modalIsOpen, closeModal, setSelectedFileToList, loading }: any) {
	const [image, setImage] = useState<string | undefined>(undefined);
	const [selectedFile, setSelectedFile] = useState<any>(undefined);
	const [ordinal, setOrdinal] = useState(0);
	const [removedPhoto, setRemovedPhoto] = useState(false);

	useEffect(() => {
		if (modalIsOpen) {
			ReactModal.setAppElement('#root');
		}
	}, [modalIsOpen]);

	useEffect(() => {
		if (!selectedFile) {
			setImage(undefined);
			return;
		}
		console.log('set img selected file je: ', selectedFile);
		const objectUrl = URL.createObjectURL(selectedFile);
		setImage(objectUrl);
		setRemovedPhoto(false);
		// return () => {
		// 	console.log("remove img trigerovan u create conteern modelu");
		// 	URL.revokeObjectURL(objectUrl);
		// };
	}, [selectedFile]);

	const press_upload = () => {
		document.getElementById('photo_upload_modal')?.click();
	};

	const closeImage = () => {
		setImage(undefined);
		setSelectedFile(undefined);
		setRemovedPhoto(true);
	};

	const onImageChange = (e: any) => {
		console.log(image);
		if (!e.target.files || e.target.files.length === 0) {
			setSelectedFile(undefined);
			return;
		}
		let file = e.target.files[0];
		if (file.size > 15728640) {
			alert('File is too big!');
			setSelectedFile(undefined);
			return;
		}
		console.log('Selected file : ', file);
		if (!checkAllowedExtensions(file.name)) {
			alert('Type of file is not allowed!');
			return;
		}
		setSelectedFile(file);
	};
	const resetInputValue = (e: any) => {
		e.target.value = null;
	};

	const onSubmit = async () => {
		if (!!selectedFile) setSelectedFileToList(selectedFile);
	};

	return (
		<ReactModal
			ariaHideApp={false}
			closeTimeoutMS={600}
			isOpen={modalIsOpen}
			onAfterOpen={() => { }}
			onAfterClose={() => closeImage()}
			onRequestClose={closeModal}
			className="no-scroll"
			style={{
				overlay: {
					zIndex: 7,
					backdropFilter: 'brightness(30%)',
				},
				content: {
					padding: 0,
					opacity: 1,
					width: 600,
					margin: 'auto',
					backgroundColor: 'transparent',
					height: '100%',
					maxHeight: '100%',
					border: 'none',
					boxShadow: 'none',
					overflow: 'auto',
					paddingTop: 15,
					paddingBottom: 15,
				},
			}}
		>
			<div className="   max-h-[600px] w-full  ">
				<div className=" flex flex-1 flex-col gap-5 rounded-[10px] bg-babyBlue  px-[40px] py-[30px] ">
					<div className="flex w-full flex-col ">
						<p className="text-[30px] font-semibold leading-[38px] text-black">{'Add new photo'}</p>
					</div>
					{/* <div className="">
						<TextField type='number'  value={ordinal} onChange={(e: any) => setOrdinal(e.target.value)} style={{ width: '100%' }} label="Position(optional)" variant="filled" />
					</div> */}
					<div>
						<div className="flex flex-col rounded-xl bg-containerGray py-2">
							<p className=" text-base text-black">Upload image file:</p>
							<div className="bg-white flex flex-row items-center gap-3 p-2 rounded-md mt-2">
								<Button variant="outlined" onClick={() => press_upload()} style={{ backgroundColor: '#eaeaea', color: '#777', border: '1px solid #777777' }}>
									Choose file
								</Button>

								<p>{!!selectedFile?.name ? selectedFile.name : !!image ? '' : 'No file chosen'}</p>
							</div>
							<p className="mt-3 text-base text-black">Supported image file extensions are: *.png, *.jpg, *.gif ,*.svg, *.mp4, *.avi</p>
							{image && (
								<div>
									<p className=" text-base text-black">Preview:</p>
									<div className="relative flex max-h-[180px]  flex-col items-center justify-center rounded-xl bg-containerGray py-8">
										<div onClick={() => closeImage()} className="absolute top-0 right-0 cursor-pointer rounded-[20px] bg-white p-[10px] opacity-40">
											<FaTimes style={{ width: 20, height: 20, stroke: 'white', display: 'block' }} />
										</div>
										{selectedFile.name.endsWith('.mp4') || selectedFile.name.endsWith('.avi') ?
											<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', maxWidth: '75%', minHeight: 150, marginTop: 10 }}>
												<video style={{ pointerEvents: "none", width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center' }} muted>
													<source src={image} type="video/mp4" />
													Your browser does not support the video tag.
												</video>
											</div>
											: <img style={{ objectFit: 'contain', maxWidth: '75%', minHeight: 150, marginTop: 10 }} src={image} alt="" />
										}

									</div>
								</div>
							)}
						</div>

						<input title="Add Video/Photo" style={{ display: 'none' }} accept="video/*,image/*" type="file" id="photo_upload_modal" onChange={onImageChange} onClick={resetInputValue} />
					</div>
					<div className="flex w-full flex-row justify-end gap-8 mt-10  ">
						<Button variant="contained" onClick={() => closeModal()} style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
							Cancel
						</Button>
						<Button variant="contained" disabled={loading} onClick={() => onSubmit()} style={{ backgroundColor: '#2ec4b6', color: 'white', minWidth: 100 }}>
							{loading ? <Loading height={18} color="#ffffff" /> : <p>Submit</p>}
						</Button>
					</div>
				</div>
			</div>
		</ReactModal>
	);
}

export default AddProductsImageModal;
