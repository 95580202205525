import React, { useEffect } from 'react';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import Loading from '../../common/Loading';
const ConfigurationBody = ({ onReady = () => {}, image = '', loading }: { onReady?: any; image?: string; loading?: boolean }) => {
	return (
		<div className="w-full h-full">
			{!loading && (
				<FabricJSCanvas
					onReady={(c) => {
						c.preserveObjectStacking = true;
						return onReady(c);
					}}
					className="h-full w-full fabric-container flex items-center justify-center"
				/>
			)}

			{loading && (
				<div className="flex-1 flex items-center justify-center h-full">
					<Loading />
				</div>
			)}
		</div>
	);
};

export default ConfigurationBody;
