import React, { useEffect, useState } from 'react';
import { TextField, Button, Grid, Modal, Backdrop, Fade, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import { createPaymentType, editPaymentType, getPaymentTypeById, getPaymentTypes, getZones } from '../../server/server';
import { PaymentType, Zone } from '../order/OrderInterfaces';

interface AddPaymentTypeModalProps {
	open: boolean;
	onClose: () => void;
	isEdit?: boolean;
	paymentTypeId?: any;
}

const AddPaymentTypeModal: React.FC<AddPaymentTypeModalProps> = ({ open, onClose, isEdit, paymentTypeId }) => {
	const [paymentType, setPaymentType] = useState({
		name: '',
		priority: '',
		zones: [''],
		description: '',
	});

	const [nameValid, setNameValid] = useState(true);
	const [priorityValid, setPriorityValid] = useState(true);
	const [zonesValid, setZonesValid] = useState(true);
	const [descriptionValid, setDescriptionValid] = useState(true);

	const [zones, setZones] = useState<Zone[] | undefined>([]);
	const [selectedZones, setSelectedZones] = useState<string[]>([]);
	const [paymentTypes, setPaymentTypes] = useState<PaymentType[]>();

	useEffect(() => {
		if (isEdit && paymentTypeId) {
			fetchPaymentTypeDetails();
		}
	}, [isEdit, paymentTypeId]);

	useEffect(() => {
		if (!open) {
			setPaymentType({
				name: '',
				priority: '',
				zones: [''],
				description: '',
			});
			setSelectedZones([]);
		}
	}, [open]);

	const fetchPaymentTypeDetails = async () => {
		const resp = await getPaymentTypeById(paymentTypeId);

		console.log(resp);

		if (resp.status == 200) {
			setPaymentType({
				name: resp.data.name,
				priority: resp.data.priority.toString(),
				zones: resp.data.zones.map((zone: any) => zone.id),
				description: resp.data.description,
			});
			setSelectedZones(resp.data.zones.map((zone: any) => zone.id));
		} else {
			alert(`Error fetching payment type: ${resp.status}`);
			onClose();
		}
	};

	const handleModalClose = () => {
		setPaymentType((prevPaymentType) => ({
			...prevPaymentType,
			name: '',
			priority: '',
			zones: [],
			description: '',
		}));

		onClose();
	};

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		setPaymentType({
			...paymentType,
			[name]: value,
		});

		switch (name) {
			case 'name':
				setNameValid(true);
				break;
			case 'priority':
				const isValidPriority = /^\d+$/.test(value) && parseInt(value, 10) > 0;
				setPriorityValid(isValidPriority);
				break;
			case 'zones':
				setZonesValid(true);
				break;
			case 'description':
				setDescriptionValid(true);
				break;
			default:
				break;
		}
	};

	const handleSubmit = async (e: any) => {
		e.preventDefault();

		const isNameValid = paymentType.name.trim() !== '';
		const isPriorityValid = paymentType.priority.trim() !== '';
		const isZonesValid = selectedZones.length > 0;
		const isDescriptionValid = paymentType.description.trim() !== '';

		setNameValid(isNameValid);
		setPriorityValid(isPriorityValid);
		setZonesValid(isZonesValid);
		setDescriptionValid(isDescriptionValid);

		if (isEdit) {
			if (isNameValid && isPriorityValid && isZonesValid && isDescriptionValid) {
				const payload = {
					name: paymentType.name,
					priority: parseInt(paymentType.priority, 10),
					zones: selectedZones.map((zoneId) => ({ id: zoneId })),
					description: paymentType.description,
				};

				const resp = await editPaymentType(paymentTypeId, payload);

				if (resp.status === 200) {
					alert(`Payment type updated successfully.`);
					setPaymentType({
						name: '',
						priority: '',
						zones: [''],
						description: '',
					});
					setSelectedZones([]);
					onClose();
				} else {
					console.error(`Error updating payment type: ${resp.status}`);
				}
			}
		} else {
			if (isNameValid && isPriorityValid && isZonesValid && isDescriptionValid) {
				const payload = {
					name: paymentType.name,
					priority: parseInt(paymentType.priority, 10),
					zones: selectedZones.map((zoneId) => ({ id: zoneId })),
					description: paymentType.description,
				};

				const resp = await createPaymentType(payload);
				if (resp.status === 201) {
					alert(`Payment type created successfully.`);
					setPaymentType({
						name: '',
						priority: '',
						zones: [''],
						description: '',
					});
					setSelectedZones([]);
					onClose();
				}
			}
		}
	};

	useEffect(() => {
		fetchZones();
		fetchPaymentTypes();
	}, []);

	const fetchZones = async () => {
		const resp = await getZones();

		if (resp.status == 200) {
			setZones(resp.data);
		} else {
			alert(`Error fetching zones: ${resp.status}`);
		}
	};

	const fetchPaymentTypes = async () => {
		const resp = await getPaymentTypes();

		if (resp.status == 200) {
			setPaymentTypes(resp.data);
		} else {
			alert(`Error fetching payment types: ${resp.status}`);
		}
	};

	return (
		<Dialog
			open={open}
			onClose={(event, reason) => {
				if (reason === 'backdropClick') {
					onClose();
				}
			}}
			fullWidth
			maxWidth="md"
		>
			<DialogTitle>{isEdit ? 'Edit Payment Type' : 'Add Payment Type'}</DialogTitle>
			<form onSubmit={handleSubmit}>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField label="Name *" variant="outlined" fullWidth name="name" value={paymentType.name} onChange={handleChange} error={!nameValid} helperText={!nameValid && 'Name is required'} />
						</Grid>
						<Grid item xs={12}>
							<TextField
								label="Priority *"
								variant="outlined"
								fullWidth
								type="text"
								name="priority"
								value={paymentType.priority}
								onChange={handleChange}
								onInput={(e) => {
									const inputValue = (e.target as HTMLInputElement).value;
									if (parseInt(inputValue, 10) < 0) {
										(e.target as HTMLInputElement).value = '0';
										e.preventDefault();
									}
								}}
								error={!priorityValid}
								helperText={!priorityValid && 'Priority is required and must be a number greater than 0'}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormControl fullWidth variant="outlined">
								<InputLabel id="simple-select-label">Zones *</InputLabel>
								<Select labelId="simple-select-label" label={'Zones *'} multiple value={selectedZones} onChange={(e) => setSelectedZones(e.target.value as string[])} name="zones" error={!zonesValid}>
									{zones?.map((zone: any) => (
										<MenuItem key={zone.id} value={zone.id} disabled={zone.disabled} style={{ color: 'black' }}>
											{zone.name}
										</MenuItem>
									))}
								</Select>
								<FormHelperText error={!zonesValid}>{!zonesValid && 'At least one zone is required'}</FormHelperText>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<TextField label="Description *" variant="outlined" fullWidth name="description" value={paymentType.description} onChange={handleChange} error={!descriptionValid} helperText={!descriptionValid && 'Description is required'} />
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleModalClose} color="primary">
						Cancel
					</Button>
					<Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
						{isEdit ? 'Edit' : 'Submit'}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default AddPaymentTypeModal;
