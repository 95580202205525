import React, { useEffect, useState } from 'react';
import { TEXT_COLORS } from '../../Constants';
import '../../assets/style/Sidebar.css';
import { TextField } from '@mui/material';

function ColorPicker({ color, onChange }: { color: string; onChange: (color: string) => void }) {
	const [colorHex, setColorHex] = useState(color);

	useEffect(() => {
		setColorHex(color);
	}, [color]);

	const changeColorHex = (text: string) => {
		setColorHex(text);
	};
	return (
		<div>
			<div className="flex flex-row flex-wrap gap-[10px]  items-center ">
				{TEXT_COLORS.map((_color) => (
					<ColorButton
						color={_color}
						selected={colorHex === _color}
						onSelect={(color) => {
							onChange(color);
							setColorHex(color);
						}}
						key={_color}
					/>
				))}
				<TextField
					label="Hex"
					defaultValue={10}
					className="flex-1"
					variant="outlined"
					value={colorHex}
					onChange={(e) => changeColorHex(e.target.value)}
					onBlur={() => {
						onChange(colorHex);
					}}
					onSubmit={() => {
						onChange(colorHex);
					}}
					onSubmitCapture={() => {
						onChange(colorHex);
					}}
				/>
			</div>
		</div>
	);
}

const ColorButton = ({ selected, color = TEXT_COLORS[0], onSelect }: { selected?: boolean; color: string; onSelect: (color: string) => void }) => {
	return (
		<button className={`color-button-ring ${selected ? 'selected' : ''}`} onClick={() => onSelect(color)}>
			<div
				className="color-button"
				style={{
					backgroundColor: color,
					borderColor: color === '#FFFFFF' ? '#ECECEC' : color,
				}}
			></div>
		</button>
	);
};

export default ColorPicker;
