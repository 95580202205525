import { Button } from '@mui/material';
import React, { useState } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import AddGroupOptionsModal from './AddGroupOptionsModal';
import { deleteGroupById } from '../../../server/server';

function GroupOptions({ commercialGroups, selectedGroup, handleSelectGroup, loadGroups, product, handleDeleteGroup }: { commercialGroups: []; selectedGroup: any; handleSelectGroup: any; loadGroups: any; product: any; handleDeleteGroup: any }) {
	const [addGroupOptionsOpen, setAddGroupOptionsOpen] = useState(false);
	const [groupForModal, setGroupForModal] = useState<any>(null);

	const closeAddGroupOptionsModal = () => {
		setGroupForModal(null);
		setAddGroupOptionsOpen(false);
	};

	const addNewGroup = () => {
		if (!product?.id) {
			alert('Product is not created');
			return;
		}
		if (commercialGroups.length >= 2) {
			alert('Only two linked groups can be created');
			return;
		}
		setGroupForModal(null);
		setAddGroupOptionsOpen(true);
	};
	const selectGroup = (group: any) => {
		handleSelectGroup(group);
	};

	const editGroup = (group: any) => {
		setGroupForModal(group);
		setAddGroupOptionsOpen(true);
	};

	const deleteGroup = async (id: string) => {
		if (window.confirm('Do you want to delete this group?')) {
			const resp = await deleteGroupById(id);
			if (resp.status === 200) {
				alert('Group deleted successfully!');
				handleDeleteGroup();
			}
		}
	};
	return (
		<div className="flex flex-col">
			<p className="text-black text-xl ">Group options</p>
			<div className="h-[200px] w-full mt-2 ">
				{commercialGroups.map((group: any, index: number) => (
					<div
						className={`flex w-full flex-row items-center justify-between p-2 mb-1 gap-5 border border-gray rounded-lg cursor-pointer ${selectedGroup?.id == group.id ? 'bg-babyBlue' : 'bg-white'}`}
						onClick={() => {
							selectGroup(group);
						}}
						key={group.id}
					>
						<div>
							<p className="text-black text-base">Group name: {group?.name}</p>
							<p className="text-black text-sm">Product name: {product?.name}</p>
						</div>
						<div className="flex flex-row items-center gap-3">
							<div
								onClick={(e) => {
									e.stopPropagation();
									editGroup(group);
								}}
							>
								<FaEdit style={{ width: 20, height: 20 }} />
							</div>
							<div
								onClick={(e) => {
									e.stopPropagation();
									deleteGroup(group.id);
								}}
							>
								<FaTrash style={{ width: 20, height: 20, color: '#D24D57' }} />
							</div>
						</div>
					</div>
				))}
			</div>
			<div className="mt-5">
				<Button variant="outlined" style={{ backgroundColor: '#eaeaea', color: '#555', fontWeight: 600, border: '1px solid #777777' }} onClick={() => addNewGroup()}>
					Create group
				</Button>
			</div>
			<AddGroupOptionsModal modalIsOpen={addGroupOptionsOpen} closeModal={closeAddGroupOptionsModal} groups={commercialGroups} groupForModal={groupForModal} selectedGroup={selectedGroup} product={product} reloadGroups={loadGroups} />
		</div>
	);
}

export default GroupOptions;
