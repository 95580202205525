import { Button } from '@mui/material';
import { GridColDef, GridActionsCellItem, DataGrid, GridRowSelectionModel } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { deleteAllowedFontsById, getAllowedFonts, getImage } from '../../../server/server';
import AddFontsModal from './AddFontsModal';

function FontChooseList({ product, setProduct }: { product: any; setProduct: any }) {
	const [isLoading, setIsLoading] = useState(false);
	const [fonts, setFonts] = useState([]);
	const [addFontsModalOpen, setAddFontsModalOpen] = useState(false);

	useEffect(() => {
		if (!!product?.id) {
			loadFontsList();
		}
	}, []);

	const loadFontsList = async () => {
		setIsLoading(true);
		const resp = await getAllowedFonts(product.id);
		if (resp.status === 200) {
			setFonts(resp.data);
			setIsLoading(false);
		}
	};

	const deleteFont = async (item: any) => {
		if (window.confirm('Do you want to remove this product font?')) {
			const resp = await deleteAllowedFontsById(item.allowedFontsId);
			if (resp.status === 200) {
				console.log('deleted ', resp.data);
				loadFontsList();
			}
		}
	};

	const openAddFontsModal = () => {
		if (!product.id) {
			alert('You must create a product first!');
			return;
		}
		setAddFontsModalOpen(true);
	};
	const closeAddFontsModal = () => {
		setAddFontsModalOpen(false);
	};

	const columns: GridColDef[] = [
		{ field: 'name', headerName: 'Name', width: 200, flex: 1, cellClassName: ' font-name ' },
		{ field: 'sample', headerName: 'Font sample', filterable: false, sortable: false, width: 200, flex: 2, renderCell: (params) => <p style={{ fontFamily: params.row?.name, fontSize: 20 }}>Quick brown fox jumps over the lazy dog</p> },
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			width: 120,
			getActions: (params) => [<GridActionsCellItem icon={<FaTrash style={{ width: 20, height: 20, color: '#D24D57' }} />} label="Delete" onClick={() => deleteFont(params.row)} />],
		},
	];

	return (
		<div className="flex  w-full flex-col mt-6 border border-mediumGrey rounded-md p-[30px] gap-5">
			<div className="flex flex-row items-center justify-between  ">
				<p className="text-black text-lg ">Choose fonts</p>
				<Button variant="contained" onClick={() => openAddFontsModal()} style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
					Add allowed fonts
				</Button>
			</div>
			<div className="mt-5 h-fit w-full">
				<DataGrid
					autoHeight
					loading={isLoading}
					disableRowSelectionOnClick={true}
					rows={fonts}
					columns={[...columns]}
					initialState={{
						pagination: {
							paginationModel: { pageSize: 8, page: 0 },
						},
					}}
					pageSizeOptions={[8, 16, 24]}
					disableColumnFilter
					disableColumnMenu
					showCellVerticalBorder={false}
				/>
			</div>
			<AddFontsModal modalIsOpen={addFontsModalOpen} closeModal={closeAddFontsModal} product={product} reloadFonts={loadFontsList} />
		</div>
	);
}

export default FontChooseList;
