import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { createOrderComment, createOrderStatusLog, deleteOrderComment, deleteOrderItem, editOrderStatus, getAllOrderStatuses, getImage, getOrderById, getOrderItemsByOrderId } from '../../server/server';
import { Button, Card, FormControl, FormLabel, InputLabel, Link, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { Order, OrderComment, OrderItem, OrderStatusEnum, PaymentStatusEnum, ShippingStatusEnum, StatusLog } from './OrderInterfaces';
import { FaAngleDown, FaAngleUp, FaPencilAlt, FaTrash, FaTruck } from 'react-icons/fa';
import EditAddressModal from '../customer/modals/EditAddressModal';
import { Address } from '../customer/modals/ManageCustomerModal';
import { useUser } from '../../auth/UseUser';
import { DataGrid, GridActionsCellItem, GridColDef, GridRowId } from '@mui/x-data-grid';
import OrderStatusLogs from './orderDetails/OrderStatusLogs';
import OrderItemsTable from './orderDetails/OrderItemsTable';
import OrderNotes from './orderDetails/OrderNotes';
import ShippingAddress from './orderDetails/ShippingAddress';
import PreviewImagesModal from '../modals/PreviewImagesModal';
import { set } from 'date-fns';

const defaultComment: OrderComment = {
	id: '',
	commentText: '',
	createdAt: '',
	isPrivate: false,
	createdBy: '',
	orderId: '',
};

const ViewSingleOrder = () => {
	const [order, setOrder] = useState<Order>();
	const [trackingCode, setTrackingCode] = useState('')
	const [selectedOrderStatus, setSelectedOrderStatus] = useState<OrderStatusEnum | string>();
	const [editAddressModalOpen, setEditAddressModalOpen] = useState(false);
	const [addressToEdit, setAddressToEdit] = useState<Address>();

	// const [latestCommentIndex, setLatestCommentIndex] = useState<number>(0);

	// const [isPrivateNote, setIsPrivateNote] = useState<string>('true');
	// const [noteText, setNoteText] = useState<string>('');
	const [rowCountState, setRowCountState] = useState(15);
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 15,
	});

	const [orderItems, setOrderItems] = useState<OrderItem[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [orderTotal, setOrderTotal] = useState(0);
	const [itemsSubtotal, setItemsSubtotal] = useState(0);
	const [totalTax, setTotalTax] = useState(0);

	const { orderId } = useParams();
	const navigate = useNavigate();

	const { user } = useUser();


	useEffect(() => {
		fetchOrderDetails();
		fetchOrderItemsByOrderId();
	}, [orderId]);

	useEffect(() => {
		recalculateTotals();
	}, [orderItems]);

	const fetchOrderDetails = async () => {
		const resp = await getOrderById(orderId);

		if (resp.status == 200) {
			setOrder(resp.data);
			setRowCountState(resp.data.length);
			setSelectedOrderStatus(OrderStatusEnum[resp.data.orderStatus]);
			setTrackingCode(resp?.data?.orderTrackingCode || '');
		} else {
			alert(`Error fetching order: ${resp.status}`);
			navigate('/orders');
		}
	};

	const handleOrderStatusChange = async (event: any) => {
		let prevStatusValue = selectedOrderStatus;

		let newLog = {
			oldStatus: OrderStatusEnum[prevStatusValue as keyof typeof OrderStatusEnum],
			newStatus: OrderStatusEnum[event.target.value as keyof typeof OrderStatusEnum],
			orderId,
			userId: user.id,
		};
		const resp = await createOrderStatusLog(newLog);

		if (resp.status == 201) {
			let orderEdit = {
				orderStatus: OrderStatusEnum[event.target.value as keyof typeof OrderStatusEnum],
				trackingCode: trackingCode,
				email: order?.customer?.email,
			};

			const orderEditResp = await editOrderStatus(orderId, orderEdit);
			if (orderEditResp.status == 200) {
				alert(`Status updated successfully`);
				setSelectedOrderStatus(event.target.value);
				fetchOrderDetails();
			}
		} else {
			alert(`Error updating order status:${resp.status}`);
			setSelectedOrderStatus(prevStatusValue);
		}
	};

	const fetchOrderItemsByOrderId = async () => {
		setIsLoading(true);
		const resp = await getOrderItemsByOrderId(orderId);

		if (resp.status == 200) {
			setOrderItems(resp.data);
			setIsLoading(false);
		}
	};

	const recalculateTotals = () => {
		const newItemsSubtotal = orderItems.reduce((subtotal, item) => {
			const itemCost = item.cost;
			const itemQuantity = item.quantity || 0;
			return subtotal + itemCost * itemQuantity;
		}, 0);

		const newTotalTax = orderItems.reduce((tax, item) => {
			const itemTax = parseFloat(item.tax);
			const itemQuantity = item.quantity || 0;
			const totalItemTax = itemTax * itemQuantity;
			return tax + totalItemTax;
		}, 0);

		setOrderTotal(parseFloat(order?.shippingCost as any) || 0 + parseFloat(order?.totalValue as any) || 0);
		setItemsSubtotal(newItemsSubtotal);
		setTotalTax(newTotalTax);
	};

	const handleRecalculateClick = async () => {
		recalculateTotals();

		let info = {
			totalValue: orderTotal,
		};
		const resp = await editOrderStatus(orderId, info);

		if (resp.status == 200) {
			alert(`Calculation complete`);
			fetchOrderDetails();
		}
	};

	useEffect(() => {
		fetchOrderDetails();
		fetchOrderItemsByOrderId();
		recalculateTotals();
	}, [orderId]);

	if (!order) {
		return <p>Loading...</p>;
	}

	const handleEditShippingAddress = (address: any) => {
		setAddressToEdit(address);
		setEditAddressModalOpen(true);
	};

	const handleCloseEditAddressModal = () => {
		setEditAddressModalOpen(false);
		fetchOrderDetails();
	};

	const deleteRowCategory = async (id: GridRowId) => {
		setOrderItems((prevItems) => prevItems.filter((item: any) => item.id !== id));

		deleteOrderItem(id).then(() => {
			alert('Item deleted successfully');
		});

		recalculateTotals();

		let info = {
			totalValue: orderTotal,
		};
		editOrderStatus(orderId, info);
		fetchOrderDetails();
	};
	const refundButtonClick = async () => {
		let info = {
			orderStatus: OrderStatusEnum.REFUNDED,
			paymentStatus: PaymentStatusEnum.REFUNDED,
			shippingStatus: ShippingStatusEnum.REFUNDED,
		};

		const resp = await editOrderStatus(orderId, info);

		if (resp.status == 200) {
			let newLog = {
				oldStatus: OrderStatusEnum[selectedOrderStatus as keyof typeof OrderStatusEnum],
				newStatus: OrderStatusEnum.REFUNDED,
				orderId,
				userId: user.id,
			};

			const logResp = await createOrderStatusLog(newLog);

			if (logResp.status == 201) {
				alert(`Refunded ${orderTotal} ${order.currency.symbol}`);
				setSelectedOrderStatus(OrderStatusEnum.REFUNDED);
				fetchOrderDetails();
			} else {
				alert(`Error creating status log:${logResp.status}`);
				setSelectedOrderStatus(selectedOrderStatus);
			}
		} else {
			alert(`Error updating statuses:${resp.status}`);
		}
	};

	return (
		<div className="py-10 px-2 overflow-auto gray-small-scroll h-full w-full">
			<div className="mb-5">
				<h2 className="text-black text-2xl font-bold">Order: {order?.orderNo} details:</h2>
				<p>Payment via: {order?.paymentType?.name}</p>
			</div>
			<div className="grid grid-cols-3">
				<Card className="bg-gray col-span-2 mr-2 p-3">
					<div className="grid grid-cols-2 gap-4">
						<div>
							<div>
								<p>General:</p>
								<div className="p-2 block items-center" id="dateCreatedContainer">
									<div>
										<InputLabel htmlFor="dateCreatedContainer">Date created:</InputLabel>
									</div>
									<div className="flex items-center">
										<TextField size="small" sx={{ width: '120px' }} disabled value={order?.createdAt ? new Date(order.createdAt).toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' }) : ''}></TextField>
										<span className="mx-2">@</span>
										<TextField size="small" sx={{ width: '50px' }} disabled value={order?.createdAt ? new Date(order.createdAt).getHours() : ''}></TextField>
										<span className="mx-2">:</span>
										<TextField size="small" sx={{ width: '50px' }} disabled value={order?.createdAt ? new Date(order.createdAt).getMinutes() : ''}></TextField>
									</div>
								</div>
								<div className="p-2 block">
									<div>
										<InputLabel htmlFor="shipping-status-label">Order status:</InputLabel>
									</div>
									<div className="w-2/3">
										<FormControl fullWidth variant="standard" id="order-status-container">
											<Select labelId="order-status-label" value={selectedOrderStatus || ''} onChange={handleOrderStatusChange}>
												<MenuItem value="">
													<em>None</em>
												</MenuItem>
												{Object.values(OrderStatusEnum)
													.filter((status) => typeof status === 'string')
													.map((status, index) => (
														<MenuItem key={index} value={status}>
															{status}
														</MenuItem>
													))}
											</Select>
										</FormControl>
									</div>
									<div className="mt-2">
										<InputLabel htmlFor="dateCreatedContainer">Customer:</InputLabel>
									</div>
									<div>
										<TextField size="small" sx={{ width: '220px' }} disabled value={order?.customer ? `${order.customer.firstName} ${order.customer.lastName}` : ''}></TextField>
									</div>
									<>
										<div className="mt-2">
											<InputLabel htmlFor="dateCreatedContainer">Tracking code:</InputLabel>
										</div>
										<div>
											<TextField size="small" sx={{ width: '220px' }} value={trackingCode} onChange={(e) => setTrackingCode(e.target.value)}></TextField>
										</div>
									</>
								</div>
							</div>
						</div>

						<div className="mt-1">
							<ShippingAddress order={order} onEditShippingAddress={handleEditShippingAddress} />
						</div>
					</div>
				</Card>
				<div className="mr-3 mt-2 ml-3">
					<OrderNotes order={order} onFetchOrderDetails={fetchOrderDetails} user={user} />
				</div>
			</div >
			<div>
				<Card className="mr-3 mt-2">
					<OrderItemsTable order={order} orderItems={orderItems} onDeleteRowCategory={deleteRowCategory} paginationModel={paginationModel} rowCountState={rowCountState} isLoading={isLoading} setPaginationModel={setPaginationModel} />
				</Card>
			</div>
			<div className="mr-3 mt-2">
				<Card className="p-2">
					<div className="mt-2 grid grid-cols-7">
						<div>
							<div className="ml-2">
								<FaTruck />
							</div>
						</div>
						<div>
							<p>Shipping type</p>
						</div>
						<div>
							<p>Shipping note</p>
						</div>
						<div>Tracking code</div>
						<div>Status</div>
						<div className="pr-5">Cost</div>
					</div>
					<div className="grid grid-cols-7">
						<div>&nbsp;</div>
						<div>
							<p>{order?.shippingOption?.name}</p>
						</div>
						<div>
							<p>{order?.shippingOption?.description}</p>
						</div>
						<div>{order?.orderTrackingCode}</div>
						<div>{order?.shippingStatus}</div>
						<div className="pr-5">
							{order?.shippingCost} {order.currency.symbol}
						</div>
					</div>
				</Card>
			</div>
			<div className="mr-3 mt-3">
				<div>
					<Card>
						<div className="flex justify-end mr-[200px]">
							<div className="mr-10 flex">
								<div>
									<p>Tax total:</p>
								</div>
								<div className="ml-2">
									<p>
										{totalTax} &nbsp;
										{order?.currency?.symbol}
									</p>
								</div>
							</div>
							<div>
								<p>Items Subtotal:</p>
								<p>Shipping:</p>
								<p>Order total:</p>
							</div>
							<div>
								<p className="ml-2">
									{itemsSubtotal} {order?.currency?.symbol}
								</p>
								<p className="ml-2">
									{order?.shippingCost} {order?.currency?.symbol}
								</p>
								<p className="ml-2">
									{orderTotal} {order?.currency?.symbol}
								</p>
							</div>
							<hr />
						</div>
						<div className="flex justify-between m-2">
							<div>
								<Button onClick={refundButtonClick}>Refund</Button>
							</div>
							<div>
								<Button className="mr-[200px]" variant="contained" onClick={handleRecalculateClick}>
									Recalculate
								</Button>
							</div>
						</div>
					</Card>
				</div>
				<div className="w-1/2">
					<OrderStatusLogs statusLogs={order?.statusLogs} />
				</div>
			</div>
			<EditAddressModal isOpen={editAddressModalOpen} onClose={handleCloseEditAddressModal} onSave={handleEditShippingAddress} address={addressToEdit} addressType="shipping" customer={order?.customer} orderId={order?.id} />
		</div >
	);
};

export default ViewSingleOrder;
