import { Button, TextField } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import { getAllProducts, getImage } from '../../server/server';
import useDebounce from '../../hooks/useDebounce';

function SelectProductListModal({ modalIsOpen, closeModal, onSelect }: { modalIsOpen: boolean; closeModal?: () => void; onSelect: (product: any) => void }) {
	const [products, setProducts] = useState<any[]>([]);
	const [rowCountState, setRowCountState] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const [searchQuery, setSearchQuery] = useState('');
	const [debouncedQuery, setDebouncedQuery] = useState('');
	const [selectedProduct, setSelectedProduct] = useState<any>({});

	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 5,
	});

	const existsMore = rowCountState > products.length;

	useEffect(() => {
		if (modalIsOpen) {
			ReactModal.setAppElement('#root');
		}
	}, [modalIsOpen]);

	useEffect(() => {
		loadProducts(paginationModel.page, paginationModel.pageSize, debouncedQuery, 'published');
	}, [paginationModel.page, paginationModel.pageSize, debouncedQuery]);

	const loadProducts = async (page: any, pageSize: any, searchQuery?: any, filter?: any) => {
		setIsLoading(true);
		const resp = await getAllProducts(pageSize, page * pageSize, searchQuery, filter);
		if (resp.status === 200) {
			setProducts((prevProducts: any[]) => [...prevProducts, ...(resp?.data?.products ?? [])]);
			setRowCountState(resp.data.total);
			setIsLoading(false);
		} else {
			setIsLoading(false);
		}
	};
	const isFirstRender = useRef(true);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			return;
		}

		const handler = setTimeout(() => {
			setProducts([]);
			setPaginationModel((prev) => ({ ...prev, page: 0 }));
			setDebouncedQuery(searchQuery);
		}, 700);

		return () => {
			clearTimeout(handler);
		};
	}, [searchQuery]);

	const loadMoreProducts = () => {
		if (existsMore) {
			setPaginationModel((prev) => ({ ...prev, page: prev.page + 1 }));
		}
	};

	const clickOnProduct = (product: any) => {
		if (selectedProduct.id === product.id) {
			setSelectedProduct({});
		} else {
			setSelectedProduct(product);
		}
	};

	return (
		<ReactModal
			ariaHideApp={false}
			isOpen={modalIsOpen}
			onAfterOpen={() => {}}
			onAfterClose={() => {
				setSearchQuery('');
				setPaginationModel({ page: 0, pageSize: 5 });
				setSelectedProduct({});
			}}
			onRequestClose={closeModal}
			className="no-scroll"
			style={{
				overlay: {
					zIndex: 5,
					backgroundColor: 'rgba(0, 0, 0, 0.75)',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				},
				content: {
					padding: 0,
					opacity: 1,
					width: 600,
					margin: 'auto',
					backgroundColor: 'transparent',
					height: '100%',
					maxHeight: 'min(80%, 800px)',
					border: 'none',
					boxShadow: 'none',
					overflow: 'auto',
					borderRadius: 10,
					display: 'flex',
					flexDirection: 'column',
				},
			}}
		>
			<div className=" w-full h-full  bg-white flex flex-col px-[40px] py-[30px] ">
				<p className="text-[30px] font-semibold leading-[38px] text-black">List of products</p>

				<TextField label="Search Products" variant="outlined" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} fullWidth margin="normal" />
				<div className="flex-1 my-[20px] w-full flex flex-col gap-[5px] overflow-x-auto">
					{products.map((product: any) => {
						const selected = product.id === selectedProduct.id;
						return (
							<div key={product.id} className={`flex items-center flex-row justify-between p-[10px] border-2 ${selected ? 'border-[green]' : 'border-mediumGrey'} rounded-md cursor-pointer`} onClick={() => clickOnProduct(product)}>
								<p>{product.name}</p>
								{!!product?.restrictedimageid && <img src={getImage(product.restrictedimageid)} alt={product.name} width={30} height={30} />}
							</div>
						);
					})}

					{existsMore && (
						<Button onClick={loadMoreProducts} disabled={isLoading}>
							{isLoading ? 'Loading...' : 'Load more'}
						</Button>
					)}
				</div>
				<div className="flex flex-row justify-between">
					<Button color="primary" variant="outlined" onClick={closeModal}>
						Cancel
					</Button>
					<Button color="primary" variant="contained" disabled={!selectedProduct?.id} onClick={() => onSelect(selectedProduct)}>
						Select
					</Button>
				</div>
			</div>
		</ReactModal>
	);
}

export default SelectProductListModal;
