import React, { useEffect, useState } from 'react';
import { TextField, Dialog, DialogTitle, DialogContent, DialogActions, Button, Select, MenuItem, Menu, InputLabel } from '@mui/material';
import { createAddress, editAddress, getCities, getCountries, getMunicipalities, getRegions, updatedAddress } from '../../../server/server';
import { Address } from './ManageCustomerModal';
import { Customer } from '../../order/OrderInterfaces';

export interface Country {
	id: string;
	name: string;
}
export interface Region {
	id: string;
	country: Country;
	name: string;
}
export interface Municipality {
	id: string;
	country: Country;
	region: Region;
	name: string;
}
export interface City {
	id: string;
	country: Country;
	region: Region;
	municipality: Municipality;
	name: string;
	zip_code: string;
}

interface EditAddressModalProps {
	isOpen: boolean;
	onClose: () => void;
	onSave: (editedAddress: any) => void;
	address: any;
	addressType: string;
	customer?: Customer;
	orderId?: string;
}

const EditAddressModal: React.FC<EditAddressModalProps> = ({ isOpen, onClose, address, addressType, onSave, customer, orderId }) => {
	console.log('Address:', address);
	const [editedAddress, setEditedAddress] = useState<Address>(address || {});
	const [selectedCountryId, setSelectedCountryId] = useState<string | undefined>(address?.country);
	const [selectedCity, setSelectedCity] = useState<string | undefined>(address?.city);
	const [countries, setCountries] = useState<Country[]>([]);
	const [isEditMode, setIsEditMode] = useState(!!address);
	const [selectedCityZipCode, setSelectedCityZipCode] = useState<string | undefined>(address?.zip);
	const [validFields, setValidFields] = useState({
		street: true,
		country: true,
		city: true,
		phone: true,
		vatNumber: true,
		zip: true,
	})
	// const [streetValid, setStreetValid] = useState(true);
	// const [countryValid, setCountryValid] = useState(true);
	// const [cityValid, setCityValid] = useState(true);
	// const [phoneValid, setPhoneValid] = useState(true);
	// const [vatNumberValid, setVatNumberValid] = useState(true);
	// const [zipValid, setZipValid] = useState(true);



	const validateField = (name: string, field: any) => {
		let isValid = true;
		if (!field) {
			isValid = false;
		}
		setValidFields((currentValidFields) => ({ ...currentValidFields, [name]: isValid }));
		return isValid;
	}

	const handleSaveChanges = async () => {
		const streetValid = validateField('street', editedAddress.street)
		const countryValid = validateField('country', selectedCountryId)
		const cityValid = validateField('city', selectedCity)
		const phoneValid = validateField('phone', editedAddress.phone)
		// const vatNumberValid = validateField('vatNumber', editedAddress.vatNumber)
		const zipValid = validateField('zip', selectedCityZipCode)


		const isFormValid = streetValid && countryValid && cityValid && phoneValid && zipValid;
		console.log('Is form valid:', streetValid, countryValid, cityValid, phoneValid, zipValid);
		if (isFormValid) {
			// console.log('Edited address:', editedAddress);
			const editedAddressForDatabase = {
				street: editedAddress?.street || undefined,
				countryId: selectedCountryId || undefined,
				city: selectedCity || undefined,
				zip: selectedCityZipCode || undefined,
				phone: editedAddress?.phone || undefined,
				vat: editedAddress?.vatNumber || undefined,
			};
			console.log('Edited address:', editedAddressForDatabase);
			const editResp = await updatedAddress({ ...editedAddressForDatabase, id: address?.id || undefined, });
			if (editResp.status == 201) {
				onSave(editResp.data);
				onClose();
			} else {
				alert(`Error saving address: ${editResp.status}`);
			}
		} else {
			alert('Please fill in all required fields marked with *');
		}
	};

	useEffect(() => {
		fetchLocationData();
		setEditedAddress(address);
	}, [address]);

	const fetchLocationData = async () => {
		const countryResponse = await getCountries();
		if (countryResponse.status == 200) {
			setCountries(countryResponse.data);
			setSelectedCountryId(address?.country?.id);
			setSelectedCity(address?.city);
			setSelectedCityZipCode(address?.zip);
		} else {
			alert(`Error fetching countries: ${countryResponse.status}`);
		}

	};

	const handleInputChange = (field: any, value: any) => {
		setEditedAddress({ ...editedAddress, [field]: value });
		validateField(field, value);
	};
	const handleCountryChange = (event: any) => {
		const selectedCountry = countries.find((country) => country.id === event);
		setSelectedCountryId(event);
		handleInputChange('countryId', event);
		console.log('Selected country:', selectedCountry);
	};


	const handleCityChange = (event: any) => {
		setSelectedCity(event.target.value);
	};

	return (
		<Dialog open={isOpen} onClose={onClose}>
			<DialogTitle className="m-2">Edit {addressType === 'billing' ? 'Billing' : 'Shipping'} Address</DialogTitle>
			<div className="m-2">
				<TextField onFocus={() => setValidFields({ ...validFields, street: true })} label="Street *" fullWidth value={editedAddress?.street} onChange={(e) => handleInputChange('street', e.target.value)} error={!validFields?.street} helperText={!validFields?.street && 'Street is required'} />
			</div>
			<div className="m-2">
				<TextField onFocus={() => setValidFields({ ...validFields, phone: true })} label="Phone *" fullWidth value={editedAddress?.phone} onChange={(e) => handleInputChange('phone', e.target.value)} error={!validFields?.phone} helperText={!validFields?.phone && 'Phone number is required'} />
			</div>
			<div className="m-2">
				<TextField onFocus={() => setValidFields({ ...validFields, country: true })} select label="Select country *" className="w-full" value={selectedCountryId || ''} onChange={(e) => handleCountryChange(e.target.value)} id="country-select" error={!validFields?.country} helperText={!validFields?.country && 'Please select a country'}>
					{countries.map((country) => (
						<MenuItem key={country?.id} value={country?.id}>
							{country?.name}
						</MenuItem>
					))}
				</TextField>
			</div>
			<div className="m-2">
				<TextField onFocus={() => setValidFields({ ...validFields, city: true })} className="w-full" label="City *" value={selectedCity || ''} onChange={handleCityChange} id="city-select" error={!validFields?.city} helperText={!validFields?.city && 'Please select a city'}>
				</TextField>
			</div>
			<div className="m-2">
				<TextField label="Zip code*" onFocus={() => setValidFields({ ...validFields, zip: true })} value={selectedCityZipCode || ''} onChange={(e) => setSelectedCityZipCode(e.target.value)} error={!validFields?.zip} helperText={!validFields?.zip && 'Zip is required'} />
			</div>
			{addressType === 'billing' ? (
				<div className="m-2">
					<TextField label="Vat number *" fullWidth value={editedAddress?.vatNumber} onChange={(e) => handleInputChange('vatNumber', e.target.value)} error={!validFields?.vatNumber} helperText={!validFields?.vatNumber && 'Vat number is required'} />
				</div>
			) : null}

			<div className="m-2">
				<DialogActions>
					<Button onClick={onClose} className="mr-2">
						Cancel
					</Button>
					<Button onClick={handleSaveChanges}>Save</Button>
				</DialogActions>
			</div>
		</Dialog>
	);
};

export default EditAddressModal;
