import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Input, InputLabel, MenuItem, Select, Switch, TextField, TextareaAutosize } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FaArrowDown, FaChevronDown, FaChevronUp, FaCircle, FaDrawPolygon, FaFileUpload, FaHeart, FaReact, FaSquare, FaSquareFull, FaTrash } from 'react-icons/fa';
import InputFileButton from '../../common/InputFileButton';
import PrintingLayerSelect from './PrintingLayerSelect';
// import { ColorPicker } from 'mui-color';
import { DEFAULT_COLOR, DEFAULT_FONT_SIZE, FONTS } from '../../Constants';
import ColorPicker from './ColorPicker';
import FontPicker from './FontPicker';

const types = [
	{
		name: 'label',
		title: 'Label',
	},
	{
		name: 'image',
		title: 'Image',
	},
	{
		name: 'croppingArea',
		title: 'Cropping area',
	},
];

const PRINTING_METHODS = ['ENGRAVING', 'PRINTING'];

const alignments = ['left', 'center', 'right'];

type functionsType = {
	addImage?: (file: any) => void;
	addSVGCroppingArea?: (data: any) => void;
	addCircle?: () => void;
	addRect?: () => void;
	addHearth?: () => void;
	drawFreePolygon?: () => void;
	addText?: ({ text, fontFamily }: { text: string; fontFamily: string }) => void;
	changeTextColor?: (color: string) => void;
	renderAll?: () => void;
	switchLayerEditable?: (value: boolean) => void;
	switchPartiallyEditable?: (value: boolean) => void;
	setLayerSelectedFonts?: (fonts: string[]) => void;
	changePrintingLayer?: (value: string) => void;
	changePrintingMethods?: (value: string) => void;
};

function LayerInfoWidget({
	layer,
	deleteLayer = () => {},
	changeType = () => {},
	printingLayers = [],
	functions,
	fabricObject,
	selectedObject,
	fonts = [],
}: {
	layer: any;
	deleteLayer?: () => void;
	changeType?: (e: string) => void;
	printingLayers?: string[];
	functions: functionsType;
	fabricObject?: any;
	selectedObject?: boolean;
	fonts?: any[];
}) {
	const {
		addImage = () => {},
		addSVGCroppingArea = () => {},
		addText = () => {},
		changeTextColor = () => {},
		renderAll = () => {},
		switchLayerEditable = () => {},
		switchPartiallyEditable = () => {},
		addCircle = () => {},
		addRect = () => {},
		drawFreePolygon = () => {},
		addHearth = () => {},
		setLayerSelectedFonts = () => {},
		changePrintingLayer = () => {},
		changePrintingMethods = () => {},
	} = functions;
	// const changePrintingLayer = (value: string) => {};
	const [showDetails, setShowDetails] = useState(false);
	const [showCroppingArea, setShowCroppingArea] = useState(!layer?.id);
	const [showImageSelection, setShowImageSelection] = useState(!layer?.id);
	const [text, setText] = useState<string>(fabricObject?.text ?? 'New text');
	const [alignment, setAlignment] = useState<string>(fabricObject?.textAlign ?? 'center');
	const [fontSize, setFontSize] = useState<number>(fabricObject?.fontSize ?? DEFAULT_FONT_SIZE);
	const [selectedFonts, setSelectedFonts] = useState<any[]>(layer?.allowedFonts?.length > 0 ? layer.allowedFonts : [fonts[0]]);

	useEffect(() => {
		setShowDetails(!!selectedObject);
	}, [selectedObject]);

	useEffect(() => {
		changeFont(selectedFonts[0]?.name);
		setLayerSelectedFonts(selectedFonts);
	}, [selectedFonts]);

	useEffect(() => {
		if (layer.type === 'text') {
			setText(fabricObject?.text);
			setAlignment(fabricObject?.textAlign);
			setFontSize(fabricObject?.fontSize);
		}
	}, [layer.type, fabricObject]);

	const openImage = (file: File) => {
		addImage(file);
		setShowImageSelection(false);
		// const reader = new FileReader();
		// reader.onload = (event) => {
		// 	addImage(event);
		// };
		// reader.readAsDataURL(file);
	};

	const openCroppingAreaAsImage = (file: File) => {
		const reader = new FileReader();
		reader.onload = (event) => {
			addSVGCroppingArea(event?.target?.result);
			setShowImageSelection(false);
		};
		reader.readAsText(file);
	};

	const changeFont = (fontFamily: string) => {
		fabricObject?.set({ fontFamily });
		renderAll();
	};

	const changeColor = (color: string) => {
		fabricObject.set({ fill: color });
		renderAll();
	};

	const changeAlignment = (align: string) => {
		fabricObject.set({ textAlign: align });
		setAlignment(align);
		renderAll();
	};
	const changeFontSize = (size: string) => {
		fabricObject.set({ fontSize: size });
		setFontSize(parseInt(size));
		renderAll();
	};

	const changeText = (text: string) => {
		fabricObject?.set({ text });
		setText(text);
		renderAll();
	};

	const setLayerType = (type: string) => {
		if (type == 'croppingArea') setShowCroppingArea(true);
		if (type == 'image') setShowImageSelection(true);
		changeType(type);
	};

	const chooseCroppingType = (callbackFunction: () => void) => {
		setShowCroppingArea(false);
		callbackFunction();
	};

	return (
		<div className="mt-[10px] border rounded-[10px] p-[10px] border-[#c4c4c4]">
			<div className="flex flex-row items-center gap-[10px]">
				<Button variant="text" color="primary" className="h-[50px] w-[50px]" onClick={deleteLayer}>
					<FaTrash size={20} />
				</Button>
				<FormControl fullWidth variant="outlined">
					<InputLabel id="type">Type</InputLabel>
					<Select labelId="type" label="Type" value={layer.type ?? layer?.layerType} onChange={(e: any) => setLayerType(e.target.value)} disabled={!!layer.type || !!layer?.layerType}>
						{types.map((type) => (
							<MenuItem key={type.name} value={type.name}>
								{type.title}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				{!!layer.type && ((layer.type === 'croppingArea' && showCroppingArea) || layer.type !== 'croppingArea') && (
					<div>
						<Button variant="text" color="primary" className="h-[50px] w-[50px]" onClick={() => setShowDetails(!showDetails)}>
							{showDetails ? <FaChevronUp size={20} /> : <FaChevronDown size={20} />}
						</Button>
					</div>
				)}
			</div>

			{showDetails && (
				<div>
					{layer.type === 'label' && (
						<div className="gap-4 flex flex-col">
							<div className="mt-4">
								<FormControl fullWidth className="border border-" style={{ borderWidth: 1 }}>
									<FormLabel className="text-[#00000017] font-[400] !text-[14px]  bg-white !px-[5px]" style={{ fontSize: '1rem', transform: 'translate(14px, 9px)', alignSelf: 'flex-start' }}>
										Name
									</FormLabel>
									<TextareaAutosize minRows={2} maxRows={5} value={text} className="border rounded-[4px] px-[14px] py-[16px] !border-[#0000002f]" onChange={(e) => changeText(e.target.value)} />
								</FormControl>
							</div>

							<div className="grid grid-cols-2 gap-4">
								<div className="flex flex-row items-center -mr-4">
									<FormGroup>
										<FormControlLabel control={<Switch checked={layer.editable} onChange={(e) => switchLayerEditable(e.target.checked)} />} label="Editable" />
									</FormGroup>
									{layer.editable && (
										<FormGroup>
											<FormControlLabel control={<Checkbox value={layer.partiallyEditable} onChange={(e) => switchPartiallyEditable(e.target.checked)} />} label={'Partially Editable'} />
										</FormGroup>
									)}
								</div>
								{/* {printingLayers?.length > 1 && (
									<div className="float-right">
										<PrintingLayerSelect layers={printingLayers} value={layer?.printingLayer} changeValue={changePrintingLayer} />
									</div>
								)} */}
								<div className="float-right">
									<PrintingLayerSelect layers={PRINTING_METHODS} value={layer?.printingMethod} changeValue={changePrintingMethods} />
								</div>
							</div>
							<div className="grid grid-cols-2 gap-4">
								<TextField fullWidth label="Font size" type="number" variant="outlined" value={fontSize} onChange={(e) => changeFontSize(e.target.value)} />
								<FormControl fullWidth variant="outlined">
									<InputLabel id="alignment">Text alignment</InputLabel>
									<Select labelId="alignment" label="Text alignment" className="capitalize" value={alignment} onChange={(e) => changeAlignment(e.target.value)}>
										{alignments.map((type) => (
											<MenuItem key={type} value={type} className="capitalize">
												{type}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</div>
							<div>
								<ColorPicker color={fabricObject?.fill} onChange={changeColor} />
							</div>
							<div>
								<FontPicker fonts={fonts} setSelectedFonts={setSelectedFonts} selectedFonts={selectedFonts} onlyOneAllowed={!layer.editable} />
							</div>
						</div>
					)}
					{layer.type === 'image' && (
						<div className="mt-4 flex flex-row justify-between items-center">
							<div>{showImageSelection && <InputFileButton handleFile={openImage} text="Select Image" />}</div>
							{printingLayers?.length > 1 && <PrintingLayerSelect layers={printingLayers} value={layer.printingLayer} changeValue={changePrintingLayer} />}
							<div className="float-right">
								<PrintingLayerSelect layers={PRINTING_METHODS} value={layer?.printingMethod} changeValue={changePrintingMethods} />
							</div>
						</div>
					)}
					{layer.type == 'croppingArea' && showCroppingArea && (
						<div className="mt-4 gap-4 flex flex-row">
							<Button variant="outlined" onClick={() => chooseCroppingType(addCircle)}>
								<FaCircle size={30} />
							</Button>
							<Button variant="outlined" onClick={() => chooseCroppingType(addRect)}>
								<FaSquareFull size={30} />
							</Button>
							<Button variant="outlined" onClick={() => chooseCroppingType(addHearth)}>
								<FaHeart size={30} />
							</Button>
							{/* <Button variant="outlined" startIcon={<FaDrawPolygon />} onClick={drawFreePolygon}>
								Draw polygon
							</Button> */}
							{/* <Button variant="outlined" startIcon={<FaFileUpload />} onClick={drawFreePolygon}>
								Upload file
							</Button> */}
							<div className="flex-1"></div>
							<InputFileButton handleFile={openCroppingAreaAsImage} text="Select Image" acceptTypes=".svg" />
						</div>
					)}
				</div>
			)}
		</div>
	);
}

export default LayerInfoWidget;
