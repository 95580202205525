import React from 'react';

function NewProductsTabsHeader({ selectedTab, setSelectedTab }: any) {
	return (
		<div className="grid grid-cols-6 ">
			<div className={`h-12  flex items-center justify-center border-b-2  cursor-pointer ${selectedTab === 'info' ? 'border-[#ff8f00] hover:bg-lightBlue' : 'border-[#ffffff00] hover:bg-babyBlue'} transition`} onClick={() => setSelectedTab('info')}>
				<p className={`text-[14px] leading-5 font-medium ${selectedTab === 'info' ? ' text-normalBlue ' : 'text-black'} uppercase`}>Information</p>
			</div>
			<div className={`h-12  flex items-center justify-center border-b-2  cursor-pointer ${selectedTab === 'personalization' ? 'border-[#ff8f00] hover:bg-lightBlue' : 'border-[#ffffff00] hover:bg-babyBlue'} transition`} onClick={() => setSelectedTab('personalization')}>
				<p className={`text-[14px] leading-5 font-medium ${selectedTab === 'personalization' ? 'text-normalBlue' : 'text-black'} uppercase`}>Personalization</p>
			</div>
			<div className={`h-12  flex items-center justify-center border-b-2  cursor-pointer ${selectedTab === 'options' ? 'border-[#ff8f00] hover:bg-lightBlue' : 'border-[#ffffff00] hover:bg-babyBlue'} transition`} onClick={() => setSelectedTab('options')}>
				<p className={`text-[14px] leading-5 font-medium ${selectedTab === 'options' ? 'text-normalBlue' : 'text-black'} uppercase`}>Commercial options</p>
			</div>
		</div>
	);
}

export default NewProductsTabsHeader;
