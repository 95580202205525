import { GridColDef, GridActionsCellItem, DataGrid, GridRowSelectionModel } from '@mui/x-data-grid';
import React, { useEffect, useRef, useState } from 'react';
import { FaCheck, FaEdit, FaTrash } from 'react-icons/fa';
import { deleteAllowedSymbolById, getAllowedSymbols, getImage } from '../../../server/server';
import { Button, TextField } from '@mui/material';
import AddSymbolsModal from './AddSymbolsModal';
import Loading from '../../../common/Loading';

function SymbolChooseList({ product, setProduct }: { product: any; setProduct: any }) {
	const [isLoading, setIsLoading] = useState(false);
	const [symbols, setSymbols] = useState([]);
	const [addSymbolsModalOpen, setAddSymbolsModalOpen] = useState(false);
	const [searchQuery, setSearchQuery] = useState('');
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 20,
	});
	const [selectedSymbol, setSelectedSymbol] = useState<any>('');
	const [modalImageIsOpen, setModalImageIsOpen] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);
	const timeout = useRef<any>(null);

	useEffect(() => {
		if (!!product?.id) {
			loadSymbolsList();
		}
	}, []);

	const loadSymbolsList = async () => {
		setIsLoading(true);
		const resp = await getAllowedSymbols(product?.id);
		if (resp.status === 200) {
			setSymbols(resp.data);
			setIsLoading(false);
		}
	};

	const deleteSymbol = async () => {
		if (window.confirm('Do you want to remove this product symbol?')) {
			const resp = await deleteAllowedSymbolById(selectedSymbol);
			if (resp.status === 200) {
				loadSymbolsList();
				setSelectedSymbol('');
			}
		}
	};

	const openAddSymbolsModal = () => {
		if (!product.id) {
			alert('You must create a product first!');
			return;
		}
		setAddSymbolsModalOpen(true);
	};

	const closeAddSymbolsModal = () => {
		setAddSymbolsModalOpen(false);
	};

	const handleSelectImage = (newItem: any) => {
		if (newItem?.allowedSymbolsId === selectedSymbol) {
			setSelectedSymbol('');
		} else {
			setSelectedSymbol(newItem?.allowedSymbolsId);
		}
	};

	return (
		<div className="flex  w-full flex-col mt-6 border border-mediumGrey rounded-md p-[30px] gap-5">
			<div className="flex flex-row items-center justify-between  ">
				<p className="text-black text-lg ">Choose product symbols</p>
				<div className="flex flex-row items-center gap-5">
					{selectedSymbol !== '' && (
						<div onClick={() => deleteSymbol()}>
							<Button variant="contained" onClick={() => {}} style={{ backgroundColor: '#D24D57', color: 'white' }}>
								Remove selected symbol
							</Button>
						</div>
					)}
					<Button variant="contained" onClick={() => openAddSymbolsModal()} style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
						Add allowed symbols
					</Button>
				</div>
			</div>
			<div className="h-full  w-full  ">
				<div className=" h-full flex  flex-col gap-5 rounded-[10px] bg-white px-[40px] py-[30px] ">
					{!isLoading && (
						<div className="max-h-[calc(100%-200px)] overflow-y-auto gray-small-scroll">
							<div className="grid grid-cols-8 gap-3 mt-1 p-1 pr-2">
								{symbols.map((item: any, index: number) => (
									<div className={`flex flex-col border border-[#aeaeae] cursor-pointer relative ${selectedSymbol === item.allowedSymbolsId ? 'shadow-md shadow-darkBlue' : ''}`} title={item.name} onClick={() => handleSelectImage(item)} key={index}>
										<img className=" aspect-square object-cover object-top" src={getImage(item.fileid)} alt="" height={'100%'} />
										<p className="w-full line-clamp-2 text-center">{item.name}</p>
										{selectedSymbol === item.allowedSymbolsId && (
											<div className="absolute -top-1 -right-1 border border-darkBlue p-[1px] bg-white">
												<div className="bg-darkBlue p-[2px]">
													<FaCheck color="white" />
												</div>
											</div>
										)}
									</div>
								))}
							</div>
						</div>
					)}
					{isLoading && (
						<div className="h-[calc(100%-200px)] max-h-[calc(100%-200px)] flex items-center justify-center">
							<Loading />
						</div>
					)}
				</div>
			</div>
			<AddSymbolsModal modalIsOpen={addSymbolsModalOpen} closeModal={closeAddSymbolsModal} product={product} reloadSymbols={loadSymbolsList} />
		</div>
	);
}

export default SymbolChooseList;
