import React, { useEffect, useState } from 'react';
import GroupOptions from './GroupOptions';
import { getCommercialGroups, getCommercialGroupsOptions, getOptionsMap } from '../../../server/server';
import GroupOptionsList from './GroupOptionsList';
import OptionMap from './OptionMap';

function ProductCommercialGroupsForm({ product, setProduct }: { product: any; setProduct: any }) {
	const [commercialGroups, setCommercialGroups] = useState<[]>([]);
	const [options, setOptions] = useState<[]>([]);
	const [optionMaps, setOptionMaps] = useState<[]>([]);
	const [selectedGroup, setSelectedGroup] = useState<any>(null);
	const [selectedOption, setSelectedOption] = useState<any>(null);

	useEffect(() => {
		if (!!product.id) loadGroups();
	}, [product?.id]);

	const loadGroups = async () => {
		const resp = await getCommercialGroups(product.id);
		if (resp.status === 200) {
			setCommercialGroups(resp.data ?? []);
		}
	};
	const loadOptionsByGroupId = async (id: string) => {
		const resp = await getCommercialGroupsOptions(id, commercialGroups.length < 2 ? 'true' : 'false');
		if (resp.status === 200) {
			setOptions(resp.data ?? []);
		}
	};

	const loadMapsByOptionId = async (id: string) => {
		const resp = await getOptionsMap(id);
		if (resp.status === 200) {
			console.log('maps ', resp.data);
			setOptionMaps(resp.data ?? []);
		}
	};
	const handleSelectGroup = (group: any) => {
		if (group?.id !== selectedGroup?.id) {
			setSelectedGroup(group);
			loadOptionsByGroupId(group.id);
			setSelectedOption(null);
			setOptionMaps([]);
		}
	};

	const handleSelectOption = (option: any) => {
		if (option.id !== selectedOption?.id) {
			loadMapsByOptionId(option.id);
			setSelectedOption(option);
		}
	};
	const handleDeleteOption = () => {
		loadOptionsByGroupId(selectedGroup.id);
		setSelectedOption(null);
		setOptionMaps([]);
	};

	const handleDeleteGroup = () => {
		loadGroups();
		setSelectedGroup(null);
		setSelectedOption(null);
		setOptions([]);
		setOptionMaps([]);
	};

	return (
		<div className="flex  w-full  flex-col mt-6 border border-mediumGrey rounded-md p-[30px] gap-5">
			<div className="grid grid-cols-3 gap-5">
				<GroupOptions commercialGroups={commercialGroups} loadGroups={loadGroups} product={product} selectedGroup={selectedGroup} handleSelectGroup={handleSelectGroup} handleDeleteGroup={handleDeleteGroup} />

				<GroupOptionsList
					commercialGroups={commercialGroups}
					options={options}
					loadOptions={loadOptionsByGroupId}
					product={product}
					handleSelectOption={handleSelectOption}
					selectedGroup={selectedGroup}
					selectedOption={selectedOption}
					setSelectedOption={setSelectedOption}
					loadOptionsDeleted={handleDeleteOption}
				/>

				{commercialGroups?.length > 1 && <OptionMap product={product} optionMaps={optionMaps} selectedOption={selectedOption} reloadMaps={loadMapsByOptionId} />}
			</div>
		</div>
	);
}

export default ProductCommercialGroupsForm;
