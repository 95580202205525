import React from 'react';
import { StatusLog, OrderStatusEnum } from '../OrderInterfaces';

interface OrderStatusLogsProps {
	statusLogs: StatusLog[] | undefined;
}

const OrderStatusLogs: React.FC<OrderStatusLogsProps> = ({ statusLogs }) => {
	return (
		<table className="rounded-lg border border-[#81848f3d] min-w-fit p-2 pb-4 mt-2">
			<thead className="border border-[#81848f3d]">
				<tr>
					<th className="pl-5 pr-5">Date</th>
					<th className="pl-5 pr-5">Old status</th>
					<th className="pl-5 pr-5">New status</th>
					<th className="pl-5 pr-5">User</th>
				</tr>
			</thead>
			<tbody className="border border-[#81848f3d]">
				{statusLogs
					?.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
					.map((log) => (
						<tr key={log.id}>
							<td className="pl-5 pr-5">{new Date(log.date).toLocaleString()}</td>
							<td className="pl-5 pr-5">{OrderStatusEnum[log?.oldStatus] || 'Created'}</td>
							<td className="pl-5 pr-5">{OrderStatusEnum[log?.newStatus] || '----'}</td>
							<td className="pl-5 pr-5">{log?.user?.id || `System`}</td>
						</tr>
					))}
			</tbody>
		</table>
	);
};

export default OrderStatusLogs;
