import React from 'react';

function TabsHeader({ selectedTab, setSelectedTab }: any) {
	return (
		<div className="grid grid-cols-3 px-20 ">
			<div className={`h-12  flex items-center justify-center border-b-2  cursor-pointer ${selectedTab === 'fonts' ? 'border-[#ff8f00] hover:bg-lightBlue' : 'border-[#ffffff00] hover:bg-babyBlue'} transition`} onClick={() => setSelectedTab('fonts')}>
				<p className={`text-[14px] leading-5 font-medium ${selectedTab === 'fonts' ? ' text-normalBlue ' : 'text-black'} uppercase`}>Upload fonts</p>
			</div>
			<div className={`h-12  flex items-center justify-center border-b-2  cursor-pointer ${selectedTab === 'symbols' ? 'border-[#ff8f00] hover:bg-lightBlue' : 'border-[#ffffff00] hover:bg-babyBlue'} transition`} onClick={() => setSelectedTab('symbols')}>
				<p className={`text-[14px] leading-5 font-medium ${selectedTab === 'symbols' ? 'text-normalBlue' : 'text-black'} uppercase`}>Symbols</p>
			</div>
			<div className={`h-12  flex items-center justify-center border-b-2  cursor-pointer ${selectedTab === 'design' ? 'border-[#ff8f00] hover:bg-lightBlue' : 'border-[#ffffff00] hover:bg-babyBlue'} transition`} onClick={() => setSelectedTab('design')}>
				<p className={`text-[14px] leading-5 font-medium ${selectedTab === 'design' ? 'text-normalBlue' : 'text-black'} uppercase`}>Product designs</p>
			</div>
		</div>
	);
}

export default TabsHeader;
