import React, { useEffect, useState } from 'react';
import { DataGrid, GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Button } from '@mui/material';
import { deleteAllowedDesignById, getAllowedDesign, getImage } from '../../../server/server';
import AddProductDesignModal from './AddProductDesignModal';
import AddDefaultDesignModal from './AddDefaultDesignModal';

function ProductDesignChooseList({ product, setProduct }: { product: any; setProduct: any }) {
	const [products, setProducts] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [addProductDesignOpen, setAddProductDesignOpen] = useState(false);
	const [addDefaultDesignOpen, setAddDefaultDesignOpen] = useState(false);
	const [selectedMode, setSelectedMode] = useState('allowed');

	useEffect(() => {
		if (!!product?.id) {
			loadProducts();
		}
	}, []);

	const loadProducts = async () => {
		setIsLoading(true);
		const resp = await getAllowedDesign(product.id);
		if (resp.status === 200) {
			console.log('product design ', resp.data);
			setProducts(resp.data);
			setIsLoading(false);
		} else {
			setIsLoading(false);
		}
	};

	const deleteRowProduct = async (item: any) => {
		if (window.confirm('Do you want to remove this product design?')) {
			const resp = await deleteAllowedDesignById(item.allowedDesignsId);
			if (resp.status === 200) {
				loadProducts();
			}
		}
	};

	const openAddProductDesignModal = () => {
		if (!product.id) {
			alert('You must create a product first!');
			return;
		}
		setAddProductDesignOpen(true);
	};
	const closeAddProductDesignModal = () => {
		setAddProductDesignOpen(false);
	};

	const openAddDefaultDesignModal = () => {
		if (!product.id) {
			alert('You must create a product first!');
			return;
		}
		setAddDefaultDesignOpen(true);
	};
	const closeAddDefaultDesignModal = () => {
		setAddDefaultDesignOpen(false);
	};

	const reloadDefaultDesignList = () => {
		setSelectedMode('default');
		loadProducts();
	};

	const reloadAllowedDesignList = () => {
		setSelectedMode('allowed');
		loadProducts();
	};

	const columns: GridColDef[] = [
		{ field: 'designName', headerName: 'Design name', width: 400, flex: 1, sortable: false, disableColumnMenu: true },
		{
			field: 'categories',
			headerName: 'Category',
			width: 200,
			sortable: false,
			disableColumnMenu: true,
			valueGetter: (params: any) => {
				return params.row.categories?.map((e: any) => e?.name)?.join(', ') ?? 'Uncategorized';
			},
		},
		{ field: 'price', headerName: 'Price', width: 100, sortable: false, disableColumnMenu: true, cellClassName: 'text-center' },
		{
			field: 'iconId',
			headerName: 'Small icon',
			width: 100,
			renderCell: (params) =>
				params.value ? (
					<a href={getImage(params.value)} target="_blank" rel="noreferrer">
						<img src={getImage(params.value)} alt="Icon" className="mx-[auto]" width="50" />
					</a>
				) : (
					''
				),
			sortable: false,
			disableColumnMenu: true,
		},
		{
			field: 'thumbnailId',
			headerName: 'Thumbnail',
			width: 100,
			renderCell: (params) =>
				params.value ? (
					<a href={getImage(params.value)} target="_blank" rel="noreferrer">
						<img src={getImage(params.value)} alt="Thumbnail" width="50" className="mx-[auto]" />
					</a>
				) : (
					''
				),
			sortable: false,
			disableColumnMenu: true,
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			width: 120,
			getActions: (params) => [<GridActionsCellItem icon={<FaTrash style={{ width: 20, height: 20, color: '#D24D57' }} />} label="Delete" onClick={() => deleteRowProduct(params.row)} />],
		},
	];
	console.log(products);

	return (
		<div className="flex  w-full  flex-col mt-6 border border-mediumGrey rounded-md p-[30px] gap-5">
			<div className="flex flex-row items-center   ">
				<p className="text-black text-lg flex-1">Choose product design</p>
				<Button variant="contained" onClick={() => openAddDefaultDesignModal()} style={{ backgroundColor: '#2ec4b6', color: 'white', marginRight: 30 }}>
					Add default design
				</Button>
				<Button variant="contained" onClick={() => openAddProductDesignModal()} style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
					Add allowed designs
				</Button>
			</div>
			<div className="mt-5 h-fit w-full">
				<p className="text-black text-xl text-center mb-2">{selectedMode === 'default' ? 'Default design' : 'Allowed design list'}</p>
				<DataGrid
					autoHeight
					loading={isLoading}
					disableRowSelectionOnClick={true}
					rows={products}
					columns={[...columns]}
					disableColumnFilter
					initialState={{
						pagination: {
							paginationModel: { pageSize: 8, page: 0 },
						},
					}}
					pageSizeOptions={[8, 16, 24]}
					disableColumnMenu
					showCellVerticalBorder={false}
				/>
			</div>
			<AddProductDesignModal reloadDesigns={reloadAllowedDesignList} product={product} modalIsOpen={addProductDesignOpen} closeModal={closeAddProductDesignModal} />
			<AddDefaultDesignModal reloadDesigns={reloadDefaultDesignList} product={product} modalIsOpen={addDefaultDesignOpen} closeModal={closeAddDefaultDesignModal} />
		</div>
	);
}

export default ProductDesignChooseList;
