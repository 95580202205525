const CheckedIcon = () => (
	<div style={{ width: '24px', height: '24px', marginRight: '6px' }}>
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M9 11L12 14L20 6" stroke="#4F6288" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			<path
				d="M20 12V18C20 18.5304 19.7893 19.0391 19.4142 19.4142C19.0391 19.7893 18.5304 20 18 20H6C5.46957 20 4.96086 19.7893 4.58579 19.4142C4.21071 19.0391 4 18.5304 4 18V6C4 5.46957 4.21071 4.96086 4.58579 4.58579C4.96086 4.21071 5.46957 4 6 4H15"
				stroke="#4F6288"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	</div>
);
export default CheckedIcon;
