import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '../auth/UseUser';
import ConfirmationModal from './modals/ConfirmationModal';
import { ReactComponent as Logo } from '../assets/logo.svg';
import { FaSignOutAlt } from 'react-icons/fa';

function NavBar() {
	const { user, setUser } = useUser();

	const location = useLocation();
	const isActive = (path: string) => location.pathname === path;

	const logout = () => {
		localStorage.clear();
		setUser({});
	};
	return (
		<div className="w-[300px] min-w-[260px] min-h-full flex flex-col bg-[#fff] text-black shadow-md overflow-auto gray-small-scroll">
			<div className="p-4 flex flex-col items-center border-b-[1px] border-[#e4e4e4]">
				<Logo />
			</div>
			<div className="flex flex-col mt-4 text-base font-medium leading-normal">
				<Link to="/products" className={`p-4 uppercase ${isActive('/products') ? 'bg-[#E5DDF6]' : 'hover:bg-[#d5cdf6]'}`}>
					Products
				</Link>
				<Link to="/categories" className={`p-4 uppercase ${isActive('/categories') ? 'bg-[#E5DDF6]' : 'hover:bg-[#d5cdf6]'}`}>
					Categories
				</Link>
				<Link to="/menu" className={`p-4 uppercase ${isActive('/menu') ? 'bg-[#E5DDF6]' : 'hover:bg-[#d5cdf6]'}`}>
					Menu
				</Link>
				<Link to="/orders" className={`p-4 uppercase ${isActive('/orders') ? 'bg-[#E5DDF6]' : 'hover:bg-[#d5cdf6]'}`}>
					Orders
				</Link>
				{/* <Link to="/product_design" className={`p-4 uppercase ${isActive('/product_design') ? 'bg-[#E5DDF6]' : 'hover:bg-[#d5cdf6]'}`}>
					Product Designs
				</Link> */}
				<Link to="/layout" className={`p-4 uppercase ${isActive('/layout') ? 'bg-[#E5DDF6]' : 'hover:bg-[#d5cdf6]'}`}>
					Mobile layout
				</Link>
				<Link to="/media" className={`p-4 uppercase ${isActive('/media') ? 'bg-[#E5DDF6]' : 'hover:bg-[#d5cdf6]'}`}>
					Media library
				</Link>
				<Link to="/shipping" className={`p-4 uppercase ${isActive('/shipping') ? 'bg-[#E5DDF6]' : 'hover:bg-[#d5cdf6]'}`}>
					Shipping management
				</Link>
				<Link to="/languages" className={`p-4 uppercase ${isActive('/languages') ? 'bg-[#E5DDF6]' : 'hover:bg-[#d5cdf6]'}`}>
					Languages
				</Link>
				<Link to="/customers" className={`p-4 uppercase ${isActive('/customers') ? 'bg-[#E5DDF6]' : 'hover:bg-[#d5cdf6]'}`}>
					Customers
				</Link>
				<Link to="/ssp" className={`p-4 uppercase ${isActive('/ssp') ? 'bg-[#E5DDF6]' : 'hover:bg-[#d5cdf6]'}`}>
					Single static pages
				</Link>
				<Link to="/companyInfo/manage" className={`p-4 uppercase ${isActive('/companyInfo/manage') ? 'bg-[#E5DDF6]' : 'hover:bg-[#d5cdf6]'}`}>
					Company information
				</Link>
				<Link to="/sliders" className={`p-4 uppercase ${isActive('/sliders') ? 'bg-[#E5DDF6]' : 'hover:bg-[#d5cdf6]'}`}>
					Sliders
				</Link>
				<Link to="/payment_types" className={`p-4 uppercase ${isActive('/payment_types') ? 'bg-[#E5DDF6]' : 'hover:bg-[#d5cdf6]'}`}>
					Payment options
				</Link>
			</div>
			<div className="flex items-center gap-2 p-4 cursor-pointer hover:bg-[#d5cdf6]" onClick={() => logout()}>
				<FaSignOutAlt /> Logout
			</div>
		</div>
	);
}

export default NavBar;
