import React, { useEffect, useState } from 'react';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import Checkbox from '@mui/material/Checkbox';
import { getAllCategories } from '../../server/server';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import CustomCheckbox from './Icons/CustomCheckbox';
import ArrowClosedIcon from './Icons/ArrowClosedIcon';
import ArrowOpenIcon from './Icons/ArrowOpenIcon';

interface Product {
	category: Category;
	created: Date;
	currency: string;
	file: any;
	id: string;
	name: string;
	price: number;
	sku: string;
}

interface Category {
	id: string;
	name: string;
	products: Product[];
	parentCategory?: Category | null;
	subCategories?: Category[];
	ordinal: number;
	sku: string;
	imageUrl1: string;
	imageAlt: string;
	thumbnailUrl1: string;
	thumbnailAlt: string;
	web: boolean;
	mobile: boolean;
	color: string;
	disabled: boolean;
}

const CustomTreeView = ({ setSelectedCategories = () => {}, showNumberOfProducts = false, selectedCategories = [], showCheckboxes = true }: { setSelectedCategories?: (category: string[]) => void; showNumberOfProducts?: boolean; selectedCategories?: string[]; showCheckboxes?: boolean }) => {
	const [categories, setCategories] = useState<Category[]>([]);

	useEffect(() => {
		fetchCategories();
	}, []);

	const fetchCategories = async () => {
		const response = await getAllCategories();

		let categories;

		if (response.status === 200) {
			categories = response.data;
		} else alert(`Error fetching categories:${response.status}`);

		let map: any = {};
		let node;
		let roots = [];
		for (let i = 0; i < categories.length; i += 1) {
			map[categories[i].id] = i;
			categories[i].subCategories = [];
		}

		for (let i = 0; i < categories.length; i += 1) {
			node = categories[i];
			if (node.parentCategory !== null) {
				categories[map[node.parentCategory.id]].subCategories.push(node);
			} else {
				roots.push(node);
			}
		}

		setCategories(roots);
	};

	const selectCategory = (id: string) => {
		if (selectedCategories.includes(id)) {
			setSelectedCategories(selectedCategories.filter((e) => e !== id));
		} else {
			setSelectedCategories([...selectedCategories, id]);
		}
	};

	const renderTree = (nodes: Category[], isLastChild = false) => {
		return nodes.map((node, index) => {
			const isCategoryChecked = selectedCategories.includes(node.id);
			const isCategoryOpen = categories.some((cat) => cat.id === node.id && cat.subCategories !== undefined && cat.subCategories.length > 0);
			const isCurrentLastChild = isLastChild && index === nodes.length - 1;

			return (
				<TreeItem
					key={node.id}
					nodeId={node.id}
					expandIcon={
						<div className="ml-4">
							<ArrowClosedIcon />
						</div>
					}
					collapseIcon={
						<div className="ml-4">
							<ArrowOpenIcon />
						</div>
					}
					endIcon={
						showCheckboxes ? (
							<div className="ml-8">
								<CustomCheckbox checked={isCategoryChecked} />
							</div>
						) : null
					}
					onClick={() => {
						if (node?.subCategories?.length === 0) {
							selectCategory(node.id);
						}
					}}
					label={
						<div className={`h-[42px] flex flex-row items-center min-w-[150px] ml-4 ${isCategoryChecked || isCategoryOpen ? 'text-treeViewBlue' : 'text-treeViewGray'}`}>
							{node.name}
							{node?.subCategories && node.subCategories.length === 0 ? `(${node?.products?.length ?? 0})` : null}
						</div>
					}
					// Apply margin to children when the parent is open
					sx={{
						position: 'relative',
						'& > .MuiTreeItem-content': {
							paddingLeft: isCategoryOpen ? '0' : '12px',
						},
						'&:before': {
							content: '""',
							position: 'absolute',
							top: '48px',
							bottom: isCategoryOpen && isCurrentLastChild ? '0' : '10px',
							left: '15px',
							borderLeft: '1px solid #4F6288',
							opacity: isCategoryOpen ? 1 : 0,
						},
					}}
				>
					{Array.isArray(node.subCategories) ? renderTree(node.subCategories, isCurrentLastChild) : null}
				</TreeItem>
			);
		});
	};

	return <TreeView className="min-h-[42px] p-4">{categories.map((category) => renderTree([category]))}</TreeView>;
};

export default CustomTreeView;
