import { Button } from '@mui/material';
import React, { useRef } from 'react';
import { FaUpload } from 'react-icons/fa';

function InputFileButton({ handleFile, text = 'Upload file', acceptTypes = 'image/png, image/jpg', error }: { handleFile: (file: File) => void; text?: string; acceptTypes?: string; error?: string }) {
	const hiddenFileInput = useRef<any>(null);

	const handleChange = (event: any) => {
		const fileUploaded = event.target.files[0];
		handleFile(fileUploaded);
	};

	const handleClick = () => {
		hiddenFileInput?.current?.click();
	};
	return (
		<>
			<Button variant="contained" color={!!error ? 'error' : 'primary'} onClick={handleClick} startIcon={<FaUpload />}>
				{text}
			</Button>
			{!!error && <span className="error-labels-product-design">{error}</span>}
			<input type="file" onChange={handleChange} ref={hiddenFileInput} style={{ display: 'none' }} accept={acceptTypes} />
		</>
	);
}

export default InputFileButton;
