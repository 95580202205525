import { Button, FormControl, Input, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { createProductDesign, getCurrencies, getImage, getProductDesignById, removeProductDesign, updateProductDesign, uploadFile } from '../../server/server';
import ProductDesignLayers from './ProductDesignLayers';
import PrintableLayers from './PrintableLayers';
import { ChromePicker } from 'react-color';
import { FaChevronLeft, FaTrash, FaTrashAlt } from 'react-icons/fa';
import '../../assets/style/CustomLabels.css';
import CustomTreeView from '../treeView/CustomTreeView';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Loading from '../../common/Loading';
export type ProductDesign = {
	designName: string;
	price: number;
	currency: Currency;
	iconSmall: string;
	image: string;
	categories?: any[];
	layers?: any[];
	id?: string;
};
export type Currency = { id: string; name: string };

export const initialProductDesign: ProductDesign = {
	designName: '',
	price: 0,
	currency: { id: '', name: '' },
	iconSmall: '',
	image: '',
	categories: [],
};

export default function ProductDesignForm() {
	const navigation = useNavigate();
	const [searchParams] = useSearchParams();
	const productFormId = searchParams.get('id');
	const [loading, setLoading] = useState(!!productFormId);
	const [productData, setProductData] = useState<any>({});
	const [productDesign, setProductDesign] = useState<ProductDesign>(initialProductDesign);
	const [currencies, setCurrencies] = useState<Currency[]>([]);
	const [iconImage, setIconImage] = useState<any>();
	const [thumbnailImage, setThumbnailImage] = useState<any>();
	const [enableMultiplePrintingLayers, setEnableMultiplePrintingLayers] = useState(false);
	const [printingLayers, setPrintingLayers] = useState<string[]>([]);
	const [error, setError] = useState<any>({});
	const [editMode, setEditMode] = useState(false);
	const [processing, setProcessing] = useState(false);
	const [initLayers, setInitLayers] = useState<any[]>([]);
	const [imageChanged, setImageChanged] = useState({ icon: false, thumbnail: false, image: false });
	const layersRef = useRef<any>();

	useEffect(() => {
		fetchCurrencies();
	}, []);

	useEffect(() => {
		if (!!productFormId) {
			getProductDesignDetails(productFormId);
		}
	}, [productFormId]);

	const getProductDesignDetails = async (id: string) => {
		const resp = await getProductDesignById(id);
		if (resp.status === 200) {
			const data = resp.data;
			setProductDesign({ ...data, categories: data?.categories?.map((e: any) => e.id) });
			setIconImage(data.icon);
			setThumbnailImage(data.thumbnail);
			setPrintingLayers(data?.printingLayers?.split(',') ?? []);
			setEnableMultiplePrintingLayers(data.enableMultiplePrintingLayers);

			setInitLayers(data.layers);
			setEditMode(true);
			console.log('Pringing area: ', data.printingArea);
			setProductData({ ...data });
			setLoading(false);
		}
	};

	const fetchCurrencies = async () => {
		const response = await getCurrencies();
		if (response.status === 200) {
			setCurrencies(response.data);
			setProductDesign((design) => ({ ...design, currency: response.data[1] }));
		}
	};

	const handleFieldChange = (event: any, field: keyof ProductDesign) => {
		if (field === 'price' && parseInt(event.target.value) < 0) return; // Price cannot be lower than 0

		setError({ ...error, [field]: !!event.target.value ? '' : 'Required filed' });
		setProductDesign({
			...productDesign,
			[field]: event.target.value,
		});
	};

	const handleCurrencyChange = (currencyId: string) => {
		setProductDesign({ ...productDesign, currency: currencies.find((c) => c.id === currencyId) || productDesign.currency });
	};

	const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>, setImageFunction: any, field: string) => {
		setError({ ...error, [field]: !!event.target.files ? '' : 'Required filed' });
		const file = event.target.files?.[0];
		if (file) {
			setImageFunction(file);
			setImageChanged((e) => ({ ...e, [field]: true }));
		}
	};

	const uploadImage = async (image: any, field: 'icon' | 'thumbnail' | 'image') => {
		if ((!editMode || (editMode && imageChanged[field])) && !image.id) {
			const resp = await uploadFile({ file: image });
			if (resp.status === 201) {
				return resp.data.id;
			}
			throw Error('Image not uploaded');
		} else {
			return image.id;
		}
	};

	const checkIfIsValid = () => {
		let valid = true;
		if (!productDesign.designName) {
			setError((e: any) => ({ ...e, name: 'Required filed' }));
			valid = false;
		}
		if (productDesign.price == null) {
			setError((e: any) => ({ ...e, price: 'Required filed' }));
			valid = false;
		}

		if (!iconImage) {
			setError((e: any) => ({ ...e, iconImage: 'Required filed' }));
			valid = false;
		}
		if (!thumbnailImage) {
			setError((e: any) => ({ ...e, thumbnailImage: 'Required filed' }));
			valid = false;
		}
		if (!iconImage) {
			setError((e: any) => ({ ...e, iconImage: 'Required filed' }));
			valid = false;
		}
		return valid;
	};

	const submit = async () => {
		const layersData = layersRef?.current?.getData();

		if (!checkIfIsValid()) return;
		if (!!layersData?.invalid) return;
		setProcessing(true);
		const images = await Promise.all([uploadImage(layersData.imageFile, 'image'), uploadImage(thumbnailImage, 'thumbnail'), uploadImage(iconImage, 'icon')]);
		delete layersData.imageFile;
		delete layersData.image;
		delete layersData.invalid;

		const data = {
			...layersData,
			designName: productDesign.designName,
			currency: productDesign.currency,
			category: productDesign.categories,
			price: parseInt(`${productDesign.price}`),
			enableMultiplePrintingLayers: enableMultiplePrintingLayers,
			printingLayers: printingLayers?.join(',') ?? '',
			icon: images[2],
			thumbnail: images[1],
			image: images[0],
			printingArea: layersData.printingArea,
		};

		if (editMode) {
			data['id'] = productData.id;
			const resp = await updateProductDesign(data);
			if (resp.status === 200) {
				alert('Design is updated');
				goBack();
			}
		} else {
			const resp = await createProductDesign(data);
			if (resp.status === 201) {
				alert('Design is created');
				goBack();
			}
		}

		setProcessing(false);
	};

	const handleDeleteProduct = async () => {
		if (window.confirm('Do you want to remove this product design?')) {
			const resp = await removeProductDesign(productDesign?.id ?? '');
			if (resp.status == 200) {
				alert('Design deleted successfully');
				goBack();
			}
		}
	};

	const selectCategories = (categories: string[]) => {
		setProductDesign((design) => ({ ...design, categories }));
	};

	const goBack = () => {
		navigation(-1);
	};

	return (
		<div className={`py-10 px-4  h-full space-y-6 -mr-6 relative ${loading ? 'overflow-hidden' : 'overflow-auto'}`}>
			<div className="flex flex-row gap-4 items-center  mb-6">
				<button onClick={goBack} className="flex flex-row gap-2 items-center ">
					<FaChevronLeft /> Back
				</button>
				<h1 className="text-black text-4xl font-bold">Add product design</h1>
			</div>
			<div className="">
				<TextField fullWidth label="Name" required variant="outlined" value={productDesign.designName} onChange={(e: any) => handleFieldChange(e, 'designName')} helperText={error?.designName ?? ''} error={!!error?.designName} />
			</div>
			<div className="grid grid-cols-2 gap-4">
				<TextField fullWidth label="Price" variant="outlined" required type="number" value={productDesign.price} onChange={(e: any) => handleFieldChange(e, 'price')} helperText={error?.price ?? ''} error={!!error?.price} />
				<FormControl fullWidth variant="outlined" required>
					<InputLabel>Currency</InputLabel>
					<Select value={productDesign.currency.id} label="Currency" onChange={(e: any) => handleCurrencyChange(e.target.value)}>
						{currencies.map((currency) => (
							<MenuItem key={currency.id} value={currency.id}>
								{currency.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>

			<div className="grid grid-cols-4 col-span-4 gap-4">
				<div className="flex col-span-2 gap-4">
					<div className="flex-1">
						<InputLabel htmlFor="category-image-input" error={!!error.thumbnailImage}>
							Thumbnail *
						</InputLabel>
						<Input id="category-image-input" type="file" fullWidth onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleImageChange(e, setThumbnailImage, 'thumbnailImage')} inputProps={{ accept: 'image/png, image/jpg' }} error={!!error.thumbnailImage} />
						{!!error.thumbnailImage && <span className="error-labels-product-design">{error.thumbnailImage}</span>}
						<div className="flex space-x-2 mt-2">{!!thumbnailImage && <img key="thumbnail_image" src={editMode ? getImage(thumbnailImage?.id) : URL.createObjectURL(thumbnailImage)} alt="category-file" style={{ width: '100px', height: '100px', objectFit: 'contain' }} />}</div>
					</div>
					<div className="flex-1">
						<InputLabel htmlFor="seo-image-input" error={!!error.iconImage}>
							Icon *
						</InputLabel>
						<Input id="seo-image-input" type="file" fullWidth onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleImageChange(e, setIconImage, 'iconImage')} inputProps={{ accept: 'image/png, image/jpg' }} error={!!error.iconImage} />
						{!!error.iconImage && <span className="error-labels-product-design">{error.iconImage}</span>}
						<div className="space-x-2 mt-2 ">{!!iconImage && <img key="icon_image" src={editMode ? getImage(iconImage?.id) : URL.createObjectURL(iconImage)} alt="category-file" style={{ width: '100px', height: '100px', objectFit: 'contain' }} />}</div>
					</div>
				</div>
				<div className="col-span-2">
					<InputLabel htmlFor="Category name">Category</InputLabel>
					<div className="border rounded mt-4 border-[#c4c4c4]">
						<CustomTreeView selectedCategories={productDesign.categories} setSelectedCategories={selectCategories} />
					</div>
				</div>
			</div>
			{/* <div>
				<PrintableLayers setPrintingLayers={setPrintingLayers} printingLayers={printingLayers} setEnableMultiplePrintingLayers={setEnableMultiplePrintingLayers} enableMultiplePrintingLayers={enableMultiplePrintingLayers} />
			</div> */}

			<ProductDesignLayers
				printingLayers={printingLayers}
				printingArea={productData.printingArea}
				ref={layersRef}
				initLayers={initLayers.sort((a, b) => a.order - b.order)}
				productImage={productData.image}
				editMode={editMode}
				changeImage={() => setImageChanged({ ...imageChanged, image: true })}
			/>

			<div className="flex flex-row justify-end gap-4">
				{productDesign.id && (
					<Button variant="contained" startIcon={<FaTrashAlt />} style={{ backgroundColor: 'red' }} onClick={handleDeleteProduct}>
						Delete
					</Button>
				)}
				<Button variant="contained" onClick={submit} disabled={processing} style={{ minWidth: 130 }}>
					{processing ? <Loading height={15} color="#21187F" secondaryColor="#F89DBD" /> : 'Save design'}
				</Button>
			</div>
			{loading && (
				<div className="absolute top-0 left-0 z-[1] bg-[#FFFFFF60] w-full h-full flex items-center justify-center">
					<Loading />
				</div>
			)}
		</div>
	);
}
