import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import { Button, TextField } from '@mui/material';
import { tempImg } from './MediaHome';
import { getImage } from '../../server/server';

function SingleImageModal({ modalIsOpen, closeModal, selectedPicture, deleteImage, deleteFlag = true }: any) {
	const onSubmit = async () => {
		deleteImage(selectedPicture?.id);
		closeModal();
	};
	const reset = () => {};
	return (
		<ReactModal
			ariaHideApp={false}
			//closeTimeoutMS={600}
			isOpen={modalIsOpen}
			onAfterOpen={() => {}}
			onAfterClose={() => reset()}
			onRequestClose={closeModal}
			className="no-scroll"
			style={{
				overlay: {
					zIndex: 5,
					backdropFilter: 'brightness(30%)',
				},
				content: {
					padding: 0,
					opacity: 1,
					width: 600,
					margin: 'auto',
					backgroundColor: 'transparent',
					height: '100%',
					maxHeight: '100%',
					border: 'none',
					boxShadow: 'none',
					overflow: 'auto',
					paddingTop: 15,
					paddingBottom: 15,
				},
			}}
		>
			<div className="   max-h-[600px] w-full  ">
				<div className=" flex flex-1 flex-col gap-5 rounded-[10px] bg-white  px-[40px] py-[30px] ">
					<div className={` border border-[#aeaeae] cursor-pointer `}>
						<img className="w-full object-contain object-top" src={getImage(selectedPicture?.id)} alt="" height={'100%'} />
					</div>
					<div className="flex w-full flex-row justify-end gap-8 mt-10  ">
						<Button variant="contained" onClick={() => closeModal()} style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
							Close
						</Button>
						{deleteFlag && (
							<Button variant="contained" onClick={() => onSubmit()} style={{ backgroundColor: '#D24D57', color: 'white' }}>
								Delete
							</Button>
						)}
					</div>
				</div>
			</div>
		</ReactModal>
	);
}

export default SingleImageModal;
