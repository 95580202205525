import { Button, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PriceForm from './PriceForm';
import ManageStockForm from './ManageStockForm';
import { createProductCommercialOptions } from '../../../server/server';
import { Product } from './NewProduct';
import dayjs from 'dayjs';

export type InitialCommercialOptions = {
	price: number;
	salePrice: number | undefined | null;
	saleFrom: Date | undefined | null;
	saleTo: Date | undefined | null;
	validFrom: Date | undefined;
	validTo: Date | undefined;
	weight: number;
	height: number;
	length: number;
	width: number;
	manageStock: boolean;
	inStock: boolean;
	soldIndividually: boolean;
	quantity: number;
	criticalQuantity: number;
	disabled: boolean;
	productId?: string;
};

const initialCommercialOptions: InitialCommercialOptions = {
	price: 0,
	salePrice: null,
	saleFrom: null,
	saleTo: null,
	validFrom: new Date(),
	validTo: new Date(),
	weight: 0,
	height: 0,
	length: 0,
	width: 0,
	manageStock: false,
	inStock: false,
	soldIndividually: false,
	quantity: 0,
	criticalQuantity: 0,
	disabled: false,
};

function ProductComercialOptions({ product, setProduct }: { product: Product; setProduct: any }) {
	const [commercialOption, setCommercialOption] = useState<InitialCommercialOptions>(initialCommercialOptions);

	useEffect(() => {
		if (!!product?.id && product?.commercialOptions?.id) {
			console.log('product data ', product.commercialOptions);
			setCommercialOption({ ...product.commercialOptions });
			setValue('price', product.commercialOptions.price);
			setValue('salePrice', product.commercialOptions.salePrice);
			setValue('saleFrom', !!product.commercialOptions.saleFrom ? dayjs(product.commercialOptions.saleFrom) : null);
			setValue('saleTo', !!product.commercialOptions.saleTo ? dayjs(product.commercialOptions.saleTo) : null);
			setValue('weight', product.commercialOptions.weight);
			setValue('length', product.commercialOptions.length);
			setValue('height', product.commercialOptions.height);
			setValue('width', product.commercialOptions.width);
			// setValue('manageStock', product.commercialOptions.manageStock, { shouldValidate: true });
			//setValue('inStock', product.commercialOptions.inStock);
			//setValue('soldIndividually', product.commercialOptions.soldIndividually);
			setValue('quantity', product.commercialOptions.quantity);
			setValue('criticalQuantity', product.commercialOptions.criticalQuantity);
		}
	}, [product]);

	const onSubmit = () => {
		console.log(getValues());
		if (!product?.id) {
			alert('You have to create a product first');
			return;
		}
		const data: InitialCommercialOptions = {
			productId: product.id,
			price: parseFloat(getValues('price')),
			salePrice: parseFloat(getValues('salePrice')),
			saleFrom: getValues('saleFrom'),
			saleTo: getValues('saleTo'),
			validFrom: commercialOption.validFrom,
			validTo: commercialOption.validTo,
			weight: parseFloat(getValues('weight')),
			height: parseFloat(getValues('height')),
			length: parseFloat(getValues('length')),
			width: parseFloat(getValues('width')),
			manageStock: commercialOption.manageStock,
			inStock: commercialOption.inStock,
			soldIndividually: commercialOption.soldIndividually,
			quantity: parseFloat(getValues('quantity')),
			criticalQuantity: parseFloat(getValues('criticalQuantity')),
			disabled: commercialOption.disabled,
		};
		console.log('submit data ', data);
		createNewProductCommercialOptions(data);
	};

	const createNewProductCommercialOptions = async (data: InitialCommercialOptions) => {
		const response = await createProductCommercialOptions(data);
		if (response.status === 201) {
			alert('Commercial options successfully updated!');
			setProduct({ ...product, commercialOptions: response.data });
		} else {
			alert('Commercial options updated failed!');
		}
	};

	const {
		control,
		handleSubmit,
		register,
		setValue,
		getValues,
		watch,
		trigger,
		formState: { errors },
	} = useForm<any>({
		defaultValues: {
			quantity: 0,
			criticalQuantity: 0,
		},
	});

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="px-2 mt-4 grid grid-cols-2 gap-2">
			<PriceForm product={product} register={register} errors={errors} setValue={setValue} control={control} getValues={getValues} trigger={trigger} />
			<ManageStockForm commercialOption={commercialOption} setCommercialOption={setCommercialOption} register={register} errors={errors} control={control} setValue={setValue} />
			<div className="col-span-2">
				<div className="flex  w-full flex-col mt-4 border border-mediumGrey rounded-md p-[30px] gap-5">
					<div className="flex flex-row items-end justify-end gap-10">
						<Button variant="contained" type="submit" style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
							Submit commercial options
						</Button>
					</div>
				</div>
			</div>
		</form>
	);
}

export default ProductComercialOptions;
