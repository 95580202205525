import { Button, TextField } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import React, { useEffect, useRef, useState } from 'react';
import { FaBorderAll, FaBorderStyle, FaCheck, FaEdit, FaTable, FaTimes, FaTrash } from 'react-icons/fa';
import { deleteFiles, getGallery, getImage } from '../../server/server';
import AddNewMedia from './AddNewMedia';
import GridList from './GridList';
import useDebounce from '../../hooks/useDebounce';
import { async } from 'q';
import MediaNavbar from './MediaNavbar';

export const tempImg = 'https://cdn.shopify.com/s/files/1/0835/1005/files/Escuyer-crew-neck-t-shirt-white-1_1024x1024.jpg';

function MediaHome() {
	const [mediaName, setMediaName] = useState('');
	const [isGrid, setIsGrid] = useState(true);
	const [isBulk, setIsBulk] = useState(false);
	const [mediaList, setMediaList] = useState<any>([]);
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 20,
	});
	const [rowCountState, setRowCountState] = useState(29);
	const [isLoading, setIsLoading] = useState(false);
	const [searchQuery, setSearchQuery] = useState('');
	const [addNewMedia, setAddNewMedia] = useState(false);
	const [selectedFiles, setSelectedFiles] = useState<any>([]);
	const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);
	const searchDelay = 700;
	// const debouncedQuery = useDebounce(searchQuery, searchDelay);
	const timeout = useRef<any>(null);

	useEffect(() => {
		loadMedia(paginationModel.page, paginationModel.pageSize, searchQuery);
	}, [paginationModel.page, paginationModel.pageSize]);

	const loadMedia = async (page: any, pageSize: any, searchQuery?: any, filter?: any) => {
		setIsLoading(true);
		const resp = await getGallery(pageSize, page * pageSize, searchQuery);
		if (resp.status === 200) {
			if (isGrid) {
				if (paginationModel.page === 0) {
					setMediaList(resp.data.items);
				} else {
					setMediaList([...mediaList, ...resp.data.items]);
				}
			} else {
				setMediaList(resp.data.items);
			}
			setRowCountState(resp.data.total);
			setIsLoading(false);
		} else {
			setIsLoading(false);
		}
	};

	const handleNewMedia = () => {
		setAddNewMedia(true);
	};

	const resetMediaList = () => {
		if (paginationModel.page === 0) {
			loadMedia(paginationModel.page, paginationModel.pageSize, searchQuery);
		} else {
			setPaginationModel({
				page: 0,
				pageSize: 20,
			});
		}
		setAddNewMedia(false);
	};

	const deleteRowMedia = async (id: any) => {
		setRowSelectionModel([]);
		if (window.confirm('Do you want to delete selected file?')) {
			const data = {
				ids: [id],
			};
			const resp = await deleteFiles(data);
			if (resp.status === 201) {
				if (paginationModel.page === 0) {
					loadMedia(paginationModel.page, paginationModel.pageSize, searchQuery);
				} else {
					setPaginationModel({
						page: 0,
						pageSize: 20,
					});
				}
			}
		}
	};

	const columns: GridColDef[] = [
		{ field: 'id', headerName: 'Image', filterable: false, sortable: false, width: 130, renderCell: (params) => <img src={getImage(params.value)} alt="product" style={{ height: '92%', aspectRatio: '1/1', objectFit: 'cover', objectPosition: 'top', cursor: 'pointer' }} loading="lazy" /> },
		{ field: 'name', headerName: 'Name', width: 200, flex: 1 },
		{ field: 'date', headerName: 'Date', width: 150 },
		{
			field: 'actions',
			headerName: 'Actions',
			type: 'actions',
			width: 120,
			getActions: (params) => [
				// <GridActionsCellItem icon={<FaEdit style={{ width: 20, height: 20 }} />} label="Edit" onClick={() => editProduct(params.id)} />,
				<GridActionsCellItem icon={<FaTrash style={{ width: 20, height: 20, color: '#D24D57' }} />} label="Delete" onClick={() => deleteRowMedia(params.id)} />,
			],
		},
	];

	const handleIsGrid = (gridState: boolean) => {
		if (isGrid != gridState) {
			setIsGrid(gridState);
			setRowSelectionModel([]);
			setIsBulk(false);
			setPaginationModel({
				page: 0,
				pageSize: 20,
			});
		}
	};

	const handleBulkSelect = (bulkState: boolean) => {
		if (isBulk != bulkState) {
			setIsBulk(bulkState);
			setRowSelectionModel([]);
		}
	};

	const deleteSelectedFiles = async () => {
		if (window.confirm('Do you want to delete selected files?')) {
			console.log('rowselected model ', rowSelectionModel);
			const data = {
				ids: rowSelectionModel,
			};
			const resp = await deleteFiles(data);
			if (resp.status === 201) {
				if (paginationModel.page === 0) {
					loadMedia(paginationModel.page, paginationModel.pageSize, searchQuery);
				} else {
					setPaginationModel({
						page: 0,
						pageSize: 20,
					});
				}
				setRowSelectionModel([]);
			}
		}
	};
	const loadMore = () => {
		if (paginationModel.page * paginationModel.pageSize < rowCountState) {
			setPaginationModel({
				page: paginationModel.page + 1,
				pageSize: paginationModel.pageSize,
			});
		}
	};

	const handleSearchQueryChange = (e: any) => {
		setSearchQuery(e.target.value);
		if (timeout.current != null) clearTimeout(timeout.current);
		timeout.current = setTimeout(() => {
			if (paginationModel.page === 0) {
				loadMedia(paginationModel.page, paginationModel.pageSize, e.target.value);
			} else {
				setPaginationModel({
					page: 0,
					pageSize: 20,
				});
			}
		}, 700);
	};

	return (
		<div className="py-6 px-2 h-full">
			<div className="flex gap-6">
				<h1 className="text-black text-4xl font-bold">Media Library</h1>
				<Button variant="contained" color="primary" onClick={() => handleNewMedia()} style={{ backgroundColor: '#21187F', color: 'white' }}>
					Add New
				</Button>
			</div>
			{addNewMedia && <AddNewMedia selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} setAddNewMedia={setAddNewMedia} resetMediaList={resetMediaList} />}
			<MediaNavbar isBulk={isBulk} isGrid={isGrid} handleIsGrid={handleIsGrid} handleBulkSelect={handleBulkSelect} handleSearchQueryChange={handleSearchQueryChange} deleteSelectedFiles={deleteSelectedFiles} rowSelectionModel={rowSelectionModel} searchQuery={searchQuery} />
			{isGrid && <GridList mediaList={mediaList} rowSelectionModel={rowSelectionModel} isBulk={isBulk} setRowSelectionModel={setRowSelectionModel} loadMore={loadMore} rowCountState={rowCountState} paginationModel={paginationModel} deleteImage={deleteRowMedia} />}
			{!isGrid && (
				<div>
					<div className="mt-5 h-fit w-full">
						<DataGrid
							autoHeight
							checkboxSelection
							rows={mediaList}
							columns={columns}
							rowCount={rowCountState}
							loading={isLoading}
							pagination
							pageSizeOptions={[10, 20, 40]}
							paginationModel={paginationModel}
							paginationMode="server"
							rowSelectionModel={rowSelectionModel}
							disableRowSelectionOnClick
							onRowSelectionModelChange={(newRowSelectionModel) => {
								setRowSelectionModel(newRowSelectionModel);
								console.log('selected rows ', newRowSelectionModel);
							}}
							onPaginationModelChange={(newModel) => {
								if (newModel.pageSize !== paginationModel.pageSize) {
									setPaginationModel({
										page: 0,
										pageSize: newModel.pageSize,
									});
									return;
								} else {
									setPaginationModel(newModel);
								}
							}}
						/>
					</div>
				</div>
			)}
		</div>
	);
}

export default MediaHome;
