import { Button } from '@mui/material';
import React, { useState } from 'react';
import { FaConnectdevelop, FaEdit, FaShareAltSquare, FaTrash } from 'react-icons/fa';
import AddOptionsModal from './AddOptionsModal';
import { deleteGroupOption } from '../../../server/server';
import ConnectProductToOptionMapModal from './ConnectProductToOptionMapModal';
import ConnectProductToOptionModal from './ConnectProductToOptionModal';

function GroupOptionsList({
	commercialGroups,
	options,
	loadOptions,
	selectedGroup,
	selectedOption,
	setSelectedOption,
	handleSelectOption,
	product,
	loadOptionsDeleted,
}: {
	commercialGroups: any;
	options: any;
	loadOptions: any;
	selectedGroup: any;
	selectedOption: any;
	setSelectedOption: any;
	handleSelectOption: any;
	product: any;
	loadOptionsDeleted: any;
}) {
	const [addOptionModalOpen, setAddOptionModalOpen] = useState(false);
	const [connectModalOpen, setConnectModalOpen] = useState(false);
	const [optionForModal, setOptionForModal] = useState(null);

	const closeAddOptionModal = () => {
		setAddOptionModalOpen(false);
		setOptionForModal(null);
	};

	const connectOptionWithProduct = (option: any) => {
		setConnectModalOpen(true);
		setSelectedOption(option);
	};

	const closeConnectModal = () => {
		setConnectModalOpen(false);
	};

	const deleteOption = async (id: string) => {
		if (window.confirm('Do you want to delete this option?')) {
			const resp = await deleteGroupOption(id);
			if (resp.status === 200) {
				loadOptionsDeleted();
				alert('Option deleted successfully!');
			}
		}
	};

	const editOption = (option: any) => {
		setOptionForModal(option);
		setAddOptionModalOpen(true);
	};

	const addNewOption = () => {
		if (!selectedGroup?.id) {
			alert('You must select a group');
			return;
		}
		setOptionForModal(null);
		setAddOptionModalOpen(true);
	};

	return (
		<div className="flex flex-col">
			<p className="text-black text-xl ">Options</p>
			<div className="h-[200px] w-full mt-2 overflow-auto gray-small-scroll pr-2">
				{options.map((option: any, index: number) => (
					<div
						className={`flex w-full flex-row items-center justify-between p-2 mb-1 gap-5 border border-gray rounded-lg cursor-pointer ${selectedOption?.id === option.id ? 'bg-babyBlue' : 'bg-white'}`}
						onClick={() => {
							handleSelectOption(option);
						}}
						key={index}
					>
						<div>
							<p className="text-black text-base">Option name: {option?.name}</p>
							<p className="text-black text-sm">Product name: {!!option?.productName ? option.productName : '-'}</p>
						</div>
						<div className="flex flex-row items-center gap-3">
							{!!option.color && <div className={`w-5 h-5 rounded-md border border-gray`} style={{ backgroundColor: option.color }}></div>}
							{commercialGroups?.length < 2 && (
								<div
									onClick={(e) => {
										e.stopPropagation();
										connectOptionWithProduct(option);
									}}
									title="Connect with product"
								>
									<FaShareAltSquare style={{ width: 20, height: 20 }} />
								</div>
							)}
							<div
								onClick={(e) => {
									e.stopPropagation();
									editOption(option);
								}}
							>
								<FaEdit style={{ width: 20, height: 20 }} />
							</div>
							<div
								onClick={(e) => {
									e.stopPropagation();
									deleteOption(option.id);
								}}
							>
								<FaTrash style={{ width: 20, height: 20, color: '#D24D57' }} />
							</div>
						</div>
					</div>
				))}
			</div>
			{selectedGroup !== null && (
				<div className="mt-5">
					<Button variant="outlined" style={{ backgroundColor: '#eaeaea', color: '#555', fontWeight: 600, border: '1px solid #777777' }} onClick={() => addNewOption()}>
						Create option
					</Button>
				</div>
			)}
			<AddOptionsModal modalIsOpen={addOptionModalOpen} closeModal={closeAddOptionModal} selectedOption={selectedOption} product={product} reloadOptions={loadOptions} selectedGroup={selectedGroup} optionForModal={optionForModal} />
			<ConnectProductToOptionModal modalIsOpen={connectModalOpen} closeModal={closeConnectModal} selectedGroup={selectedGroup} selectedOption={selectedOption} reloadMaps={loadOptions} />
		</div>
	);
}

export default GroupOptionsList;
