import React, { useEffect, useState } from 'react';
import { TextField, Button, MenuItem, Autocomplete } from '@mui/material';
import 'tailwindcss/tailwind.css';

import { Municipality, Region } from '../customer/modals/EditAddressModal';
import { City } from '../customer/modals/EditAddressModal';
import { createCompanyInformation, editCompanyInformation, getCities, getCompanyInformation, getMunicipalities, getRegions } from '../../server/server';

interface CompanyInfo {
	id: string;
	name: string;
	addLine1: string;
	addLine2?: string;
	region: Region;
	city: City;
	zip: string;
	vatNumber: string;
	regNumber: string;
	bankAcc1: string;
	bankAcc2?: string;
	sectorCode: string;
	email: string;
	phone: string;
	note1?: string;
	note2?: string;
}
const initialCompanyInformation: CompanyInfo = {
	id: '',
	name: '',
	addLine1: '',
	region: {
		id: '',
		country: {
			id: '',
			name: '',
		},
		name: '',
	},
	city: {
		id: '',
		country: {
			id: '',
			name: '',
		},
		region: {
			id: '',
			country: {
				id: '',
				name: '',
			},
			name: '',
		},
		municipality: {
			id: '',
			country: {
				id: '',
				name: '',
			},
			region: {
				id: '',
				country: {
					id: '',
					name: '',
				},
				name: '',
			},
			name: '',
		},
		name: '',
		zip_code: '',
	},
	zip: '',
	vatNumber: '',
	regNumber: '',
	bankAcc1: '',
	bankAcc2: '',
	sectorCode: '',
	email: '',
	phone: '',
	note1: '',
	note2: '',
};

const CompanyInfoManagemet = () => {
	const [regions, setRegions] = useState<Region[]>([]);
	const [cities, setCities] = useState<City[]>([]);
	const [municipalities, setMunicipalities] = useState<Municipality[]>([]);
	const [selectedMunicipalityIds, setSelectedMunicipalityIds] = useState<string[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	const [companyInformation, setCompanyInformation] = useState<CompanyInfo>(initialCompanyInformation);

	const [selectedRegionId, setSelectedRegionId] = useState<string | undefined>('');
	const [selectedCityId, setSelectedCityId] = useState<string | undefined>('');
	const [selectedCityZipCode, setSelectedCityZipCode] = useState<string | undefined>();

	const [nameValid, setNameValid] = useState(true);
	const [addLine1Valid, setAddLine1Valid] = useState(true);
	const [regionValid, setRegionValid] = useState(true);
	const [cityValid, setCityValid] = useState(true);
	const [zipValid, setZipValid] = useState(true);
	const [vatNumberValid, setVatNumberValid] = useState(true);
	const [regNumberValid, setRegNumberValid] = useState(true);
	const [bankAcc1Valid, setBankAcc1Valid] = useState(true);
	const [sectorCodeValid, setSectorCodeValid] = useState(true);
	const [emailValid, setEmailValid] = useState(true);
	const [phoneValid, setPhoneValid] = useState(true);

	const validateFields = () => {
		const isNameValid = companyInformation.name.trim() !== '';
		const isAddLine1Valid = companyInformation.addLine1.trim() !== '';
		const isRegionValid = !!companyInformation.region.id;
		const isCityValid = !!companyInformation.city.id;
		const isZipValid = companyInformation.zip.trim() !== '';
		const isVatNumberValid = companyInformation.vatNumber.trim() !== '';
		const isRegNumberValid = companyInformation.regNumber.trim() !== '';
		const isBankAcc1Valid = companyInformation.bankAcc1.trim() !== '';
		const isSectorCodeValid = companyInformation.sectorCode.trim() !== '';
		const isEmailValid = companyInformation.email.trim() !== '' && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(companyInformation.email.trim());
		const isPhoneValid = companyInformation.phone.trim() !== '';

		setNameValid(isNameValid);
		setAddLine1Valid(isAddLine1Valid);
		setRegionValid(isRegionValid);
		setCityValid(isCityValid);
		setZipValid(isZipValid);
		setVatNumberValid(isVatNumberValid);
		setRegNumberValid(isRegNumberValid);
		setBankAcc1Valid(isBankAcc1Valid);
		setSectorCodeValid(isSectorCodeValid);
		setEmailValid(isEmailValid);
		setPhoneValid(isPhoneValid);

		return isNameValid && isAddLine1Valid && isRegionValid && isCityValid && isZipValid && isVatNumberValid && isRegNumberValid && isBankAcc1Valid && isSectorCodeValid && isEmailValid && isPhoneValid;
	};

	useEffect(() => {
		fetchLocationData();
	}, []);

	useEffect(() => {
		fetchCompanyInformation();
	}, [regions, municipalities, cities]);

	const fetchLocationData = async () => {
		const regionResponse = await getRegions();

		if (regionResponse.status == 200) {
			setRegions(regionResponse.data);
			setSelectedRegionId(regionResponse.data?.id);
		} else {
			alert(`Error fetching regions: ${regionResponse.status}`);
		}
		const municipalityResponse = await getMunicipalities();
		if (municipalityResponse.status == 200) {
			setMunicipalities(municipalityResponse.data);
		} else {
			alert(`Error fetching municipalities: ${regionResponse.status}`);
		}
		const cityResponse = await getCities();
		if (cityResponse.status == 200) {
			setCities(cityResponse.data);
			setSelectedCityId(cityResponse?.data?.id);
			console.log({ cityResponse });
		} else {
			alert(`Error fetching cities: ${cityResponse.status}`);
		}
	};

	const fetchCompanyInformation = async () => {
		const companyInfoResp = await getCompanyInformation();
		setIsLoading(true);
		if (companyInfoResp.status === 200) {
			const companyInfoArray = companyInfoResp.data;

			if (companyInfoArray.length > 0) {
				setCompanyInformation({ ...companyInfoArray[0] });
				setSelectedRegionId(companyInfoArray[0].region.id);

				const selectedRegion = regions.find((region) => region.id === companyInfoArray[0].region.id);

				if (selectedRegion) {
					const municipalityIds = municipalities.filter((municipality) => municipality.region.id === selectedRegion.id).map((municipality) => municipality.id);
					setSelectedMunicipalityIds(municipalityIds);
				}

				setSelectedCityId(companyInfoArray[0].city.id);
				setSelectedCityZipCode(companyInfoArray[0].city.zip);
			}
		}
		setIsLoading(false);
	};

	const handleSubmit = async () => {
		const isValid = validateFields();
		if (isValid) {
			const companyInfoDTO = {
				name: companyInformation.name,
				addLine1: companyInformation.addLine1,
				addLine2: companyInformation.addLine2 || null,
				regionId: companyInformation.region.id,
				cityId: companyInformation.city.id,
				zip: companyInformation.zip,
				vatNumber: companyInformation.vatNumber,
				regNumber: companyInformation.regNumber,
				bankAcc1: companyInformation.bankAcc1,
				bankAcc2: companyInformation.bankAcc2 || null,
				sectorCode: companyInformation.sectorCode,
				email: companyInformation.email,
				phone: companyInformation.phone,
				note1: companyInformation.note1 || null,
				note2: companyInformation.note2 || null,
			};

			if (companyInformation.id) {
				const editResponse = await editCompanyInformation(companyInformation.id, companyInfoDTO);

				if (editResponse.status === 200) {
					alert('Information updated successfully');
				} else {
					alert(`Error updating information: ${editResponse.status}`);
				}
			} else {
				const createResponse = await createCompanyInformation(companyInfoDTO);

				if (createResponse.status === 201) {
					alert('Information saved successfully');
				} else {
					alert(`Error saving information: ${createResponse.status}`);
				}
			}
		} else {
			alert(`Please fill all fields marked with *.`);
		}
	};

	const handleInputChange = (field: any, value: any) => {
		setCompanyInformation((prevCompanyInformation) => ({
			...prevCompanyInformation,
			[field]: value,
		}));
	};

	const handleRegionChange = (event: any) => {
		const selectedRegion = regions.find((region) => region.id === event);

		setSelectedRegionId(event);

		if (selectedRegion) {
			const municipalityIds = municipalities.filter((municipality) => municipality.region.id === selectedRegion.id).map((municipality) => municipality.id);
			console.log(municipalityIds);
			setSelectedMunicipalityIds(municipalityIds);
		} else {
			setSelectedMunicipalityIds([]);
		}

		setSelectedCityId(undefined);
		handleInputChange('region', selectedRegion);
	};

	const handleCityChange = (event: any) => {
		const selectedCity = cities.find((city) => city.id === event);
		console.log('Autocomplete value', selectedCity);
		setSelectedCityId(event);
		setCompanyInformation((prevCompanyInformation) => ({
			...prevCompanyInformation,
			zip: selectedCity?.zip_code || '',
		}));

		setSelectedCityZipCode(selectedCity?.zip_code || '');
		handleInputChange('city', selectedCity);
	};

	return (
		<div className="overflow-auto gray-small-scroll">
			{isLoading && (
				<div
					style={{
						position: 'fixed',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						backgroundColor: 'rgba(255, 255, 255, 0.5)',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						zIndex: 999,
					}}
				>
					Loading...
				</div>
			)}
			<div className="p-4 grid grid-cols-2 gap-4">
				<div className="col-1 grid grid-rows-10">
					<div className="row-start-1">
						<h1 className="text-xl font-bold">Store Address</h1>
						<p>This is the store legal info used for the store data in the invoice and payment instructions.</p>
					</div>

					<div className="row-start-2">
						<div className="w-5/6">
							<TextField size="small" className="w-full" label="Company name *" variant="outlined" value={companyInformation.name || ''} onChange={(event) => handleInputChange('name', event.target.value)} error={!nameValid} helperText={!nameValid && 'Company name is required'} />
						</div>
					</div>
					<div className="row-start-3">
						<div className="w-5/6">
							<TextField
								size="small"
								className="w-full"
								label="Address line 1 *"
								variant="outlined"
								value={companyInformation.addLine1 || ''}
								onChange={(event) => handleInputChange('addLine1', event.target.value)}
								error={!addLine1Valid}
								helperText={!addLine1Valid && 'Address is required'}
							/>
						</div>
					</div>
					<div className="row-start-4">
						<div className="w-5/6">
							<Autocomplete
								options={regions.slice().sort((a, b) => a.name.localeCompare(b?.name))}
								getOptionLabel={(region) => `${region?.country?.name} - ${region?.name}`}
								value={regions.find((region) => region?.id === selectedRegionId) || null}
								onChange={(_, newValue) => handleRegionChange(newValue?.id || '')}
								renderInput={(params) => <TextField {...params} label="Select country/region *" variant="outlined" size="small" error={!regionValid} helperText={!regionValid && 'Please select a region'} />}
							/>
						</div>
					</div>
					<div className="row-start-5">
						<div className="mb-2 w-5/6">
							<Autocomplete
								options={cities.filter((city) => selectedMunicipalityIds.includes(city?.municipality?.id) && city?.region?.id === selectedRegionId).sort((a, b) => a?.name?.localeCompare(b?.name))}
								getOptionLabel={(city) => city?.name}
								// options={cities.filter((city) => selectedMunicipalityIds.includes(city?.municipality?.id)).sort((a, b) => a.city_name.localeCompare(b.city_name))}
								// getOptionLabel={(city) => city.city_name}

								value={cities.find((city) => city.id === selectedCityId) || null}
								onChange={(_, newValue) => handleCityChange(newValue?.id || '')}
								renderInput={(params) => <TextField {...params} label="Select city *" variant="outlined" size="small" error={!cityValid} helperText={!cityValid && 'Please select a city'} />}
							/>
						</div>
					</div>
					<div className="row-start-6">
						<div className="mb-2 w-5/6">
							<TextField size="small" className="w-full" disabled label="Postal/ZIP code" value={selectedCityZipCode || companyInformation.zip || ''} variant="outlined" onChange={(event) => handleInputChange('zip', event.target.value)} />
						</div>
					</div>
					<div className="row-start-7">
						<div className="mb-2 w-5/6">
							<TextField
								size="small"
								className="w-full"
								label="VAT number *"
								variant="outlined"
								value={companyInformation.vatNumber || ''}
								onChange={(event) => handleInputChange('vatNumber', event.target.value)}
								error={!vatNumberValid}
								helperText={!vatNumberValid && 'Vat number is required'}
							/>
						</div>
					</div>
					<div className="row-start-8">
						<div className="mb-2 w-5/6">
							<TextField
								size="small"
								className="w-full"
								label="Registration number *"
								variant="outlined"
								value={companyInformation.regNumber || ''}
								onChange={(event) => handleInputChange('regNumber', event.target.value)}
								error={!regNumberValid}
								helperText={!regNumberValid && 'Registration number is required'}
							/>
						</div>
					</div>
					<div className="row-start-9">
						<div className="mb-2 w-5/6">
							<TextField
								size="small"
								className="w-full"
								label="Bank account 1 *"
								variant="outlined"
								value={companyInformation.bankAcc1 || ''}
								onChange={(event) => handleInputChange('bankAcc1', event.target.value)}
								error={!bankAcc1Valid}
								helperText={!bankAcc1Valid && 'Bank account is required'}
							/>
						</div>
					</div>
					<div className="row-start-10">
						<div className="mb-2 w-5/6">
							<TextField size="small" className="w-full" label="Bank account 2" variant="outlined" value={companyInformation.bankAcc2 || ''} onChange={(event) => handleInputChange('bankAcc2', event.target.value)} />
						</div>
						<div className=" w-5/6">
							<Button variant="contained" onClick={handleSubmit}>
								Save changes
							</Button>
						</div>
					</div>
				</div>

				<div className="col-1 grid grid-rows-10">
					<div className="mb-4 row-start-1"></div>

					<div className="mb-4 row-start-2 "></div>
					<div className="mb-4 row-start-3">
						<div className="mb-2 w-5/6">
							<TextField size="small" className="w-full" label="Address Line 2" variant="outlined" value={companyInformation.addLine2 || ''} onChange={(event) => handleInputChange('addLine2', event.target.value)} />
						</div>
					</div>
					<div className=" row-start-4"></div>
					<div className="row-start-5"></div>
					<div className=" row-start-6">
						<div className="mb-2 w-5/6">
							<TextField
								size="small"
								className="w-full"
								label="Sector code *"
								variant="outlined"
								value={companyInformation.sectorCode || ''}
								onChange={(event) => handleInputChange('sectorCode', event.target.value)}
								error={!sectorCodeValid}
								helperText={!sectorCodeValid && 'Sector code is required'}
							/>
						</div>
					</div>
					<div className="row-start-7">
						<div className="mb-2 w-5/6">
							<TextField
								size="small"
								className="w-full"
								label="Email *"
								variant="outlined"
								value={companyInformation.email || ''}
								onChange={(event) => handleInputChange('email', event.target.value)}
								error={!emailValid}
								helperText={!emailValid && 'Email is required and must be in email format'}
							/>
						</div>
					</div>
					<div className="row-start-8">
						<div className="mb-2 w-5/6">
							<TextField size="small" className="w-full" label="Phone number *" variant="outlined" value={companyInformation.phone || ''} onChange={(event) => handleInputChange('phone', event.target.value)} error={!phoneValid} helperText={!phoneValid && 'Phone number is required'} />
						</div>
					</div>
					<div className="row-start-9">
						<div className="mb-2 w-5/6">
							<TextField size="small" className="w-full" label="Additional note 1" variant="outlined" value={companyInformation.note1 || ''} onChange={(event) => handleInputChange('note1', event.target.value)} />
						</div>
					</div>
					<div className=" row-start-10">
						<div className="mb-2 w-5/6">
							<TextField size="small" className="w-full" label="Additional note 2" variant="outlined" value={companyInformation.note2 || ''} onChange={(event) => handleInputChange('note2', event.target.value)} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CompanyInfoManagemet;
