import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { Button } from '@mui/material';
import { createAllowedDesigns, getAvailableAllowedDesign, getImage } from '../../../server/server';
import useDebounce from '../../../hooks/useDebounce';
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';

function AddProductDesignModal({ modalIsOpen, closeModal, product, reloadDesigns }: any) {
	const [products, setProducts] = useState([]);
	const [rowCountState, setRowCountState] = useState(5);
	const [isLoading, setIsLoading] = useState(true);
	const [searchQuery, setSearchQuery] = useState('');
	const [selectedDesigns, setSelectedDesigns] = useState<any[][]>([[]]);
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 5,
	});
	const searchDelay = 2000;
	const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);
	const debouncedQuery = useDebounce(searchQuery, searchDelay);

	useEffect(() => {
		if (modalIsOpen) {
			ReactModal.setAppElement('#root');
			loadProductsDesign(product?.id, paginationModel.page, paginationModel.pageSize, debouncedQuery);
		}
	}, [modalIsOpen]);

	useEffect(() => {
		if (modalIsOpen) {
			loadProductsDesign(product?.id, paginationModel.page, paginationModel.pageSize, debouncedQuery);
		}
	}, [paginationModel.page, paginationModel.pageSize, debouncedQuery]);

	const loadProductsDesign = async (productId: string, page?: any, pageSize?: any, searchQuery?: any) => {
		setIsLoading(true);
		const resp = await getAvailableAllowedDesign(productId, pageSize, page * pageSize, searchQuery);
		if (resp.status === 200) {
			setProducts(resp.data?.items);
			setRowCountState(resp.data?.total);
			setIsLoading(false);
		}
	};

	const onSubmit = async () => {
		createAllowedProductDesigns(selectedDesigns.flat());
	};

	const createAllowedProductDesigns = async (designList: any) => {
		if (!designList.length) {
			alert('You have to select at least one design!');
			return;
		}
		let data = createListOfDesigns(designList);
		const response = await createAllowedDesigns(data);
		if (response.status === 201) {
			console.log('product created ', response.data);
			closeModalAndReloadDesigns();
		}
	};

	const createListOfDesigns = (list: string[]) => {
		let newData: any = { allowedDesigns: [], default: false };
		list.forEach((designId: string) => {
			let allowedDesign = {
				productId: product?.id,
				productDesignId: designId,
			};
			newData.allowedDesigns.push(allowedDesign);
		});
		return newData;
	};

	const closeModalAndReloadDesigns = () => {
		reloadDesigns();
		closeModal();
	};

	const reset = () => {
		setRowSelectionModel([]);
		setSelectedDesigns([[]]);
		setPaginationModel({
			page: 0,
			pageSize: 5,
		});
		setProducts([]);
	};

	const columns: GridColDef[] = [
		{ field: 'designName', headerName: 'Design name', width: 300, sortable: false, disableColumnMenu: true },
		{
			field: 'categories',
			headerName: 'Category',
			width: 150,
			sortable: false,
			disableColumnMenu: true,
			valueGetter: (params: any) => {
				return params.row.categories?.map((e: any) => e?.name)?.join(', ') ?? 'Uncategorized';
			},
		},
		{ field: 'price', headerName: 'Price', width: 100, sortable: false, disableColumnMenu: true, cellClassName: 'text-center' },
		{
			field: 'iconId',
			headerName: 'Small icon',
			width: 100,
			renderCell: (params) =>
				params.value ? (
					<a href={getImage(params.value)} target="_blank" rel="noreferrer">
						<img src={getImage(params.value)} alt="Icon" className="mx-[auto]" width="50" />
					</a>
				) : (
					''
				),
			sortable: false,
			disableColumnMenu: true,
		},
		{
			field: 'thumbnailId',
			headerName: 'Thumbnail',
			width: 100,
			renderCell: (params) =>
				params.value ? (
					<a href={getImage(params.value)} target="_blank" rel="noreferrer">
						<img src={getImage(params.value)} alt="Thumbnail" width="50" className="mx-[auto]" />
					</a>
				) : (
					''
				),
			sortable: false,
			disableColumnMenu: true,
		},
	];
	return (
		<ReactModal
			ariaHideApp={false}
			//closeTimeoutMS={600}
			isOpen={modalIsOpen}
			onAfterOpen={() => {}}
			onAfterClose={() => reset()}
			onRequestClose={closeModal}
			className="no-scroll"
			style={{
				overlay: {
					zIndex: 5,
					backdropFilter: 'brightness(30%)',
				},
				content: {
					padding: 0,
					opacity: 1,
					width: 950,
					margin: 'auto',
					backgroundColor: 'transparent',
					height: '100%',
					maxHeight: '100%',
					border: 'none',
					boxShadow: 'none',
					overflow: 'auto',
					paddingTop: 15,
					paddingBottom: 15,
				},
			}}
		>
			<div className="   max-h-[600px] w-full  ">
				<div className=" flex flex-1 flex-col gap-5 rounded-[10px] bg-white  px-[40px] py-[30px] ">
					<p className=" text-center text-xl ">Add Designs for product</p>
					<div className="mt-5 h-fit w-full">
						<DataGrid
							checkboxSelection
							rowCount={rowCountState}
							loading={isLoading}
							disableRowSelectionOnClick
							pagination
							rows={products}
							columns={[...columns]}
							showCellVerticalBorder={false}
							style={{ minHeight: 371 }}
							pageSizeOptions={[5, 15, 25]}
							paginationModel={paginationModel}
							paginationMode="server"
							rowSelectionModel={selectedDesigns[paginationModel.page]}
							onRowSelectionModelChange={(newRowSelectionModel) => {
								setRowSelectionModel(newRowSelectionModel);
								let list: any[] = [...newRowSelectionModel];
								selectedDesigns[paginationModel.page] = list;
							}}
							onPaginationModelChange={(newModel) => {
								if (newModel.pageSize !== paginationModel.pageSize) {
									setSelectedDesigns([[]]);
									setPaginationModel({
										page: 0,
										pageSize: newModel.pageSize,
									});
									return;
								} else {
									setPaginationModel(newModel);
									if (newModel.page + 1 > selectedDesigns.length) {
										setSelectedDesigns([...selectedDesigns, []]);
									}
								}
							}}
						/>
					</div>
					<div className="flex w-full flex-row justify-end gap-8 mt-10  ">
						<Button variant="contained" onClick={() => closeModal()} style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
							Cancel
						</Button>
						<Button variant="contained" onClick={() => onSubmit()} style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
							Submit
						</Button>
					</div>
				</div>
			</div>
		</ReactModal>
	);
}

export default AddProductDesignModal;
