import React, { useEffect, useState, useRef } from 'react';
import { DataGrid, GridRowId, GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import { FaClone, FaEdit, FaTrash } from 'react-icons/fa';
import { Button } from '@mui/material';
import { getZones, deleteZoneById } from '../../server/server';
import { useNavigate } from 'react-router-dom';

function ShippingHome() {
	const [rowCountState, setRowCountState] = useState(5);
	const [isLoading, setIsLoading] = useState(false);
	const [zones, setZones] = useState([]);

	let navigate = useNavigate();
	const handleNewZone = () => {
		navigate('/shipping/zone/new');
	};

	useEffect(() => {
		loadZones();
	}, []);

	const loadZones = async () => {
		setIsLoading(true);
		const resp = await getZones();
		if (resp.status === 200) {
			setZones(resp.data);
			setRowCountState(resp.data?.length);
			setIsLoading(false);
		}
	};

	const openEditZone = (zoneId: any) => {
		navigate(`/shipping/zone/${zoneId}`);
	};

	const deleteZone = async (data: any) => {
		if (window.confirm('Do you want to delete this zone?')) {
			const resp = await deleteZoneById(data);
			if (resp.status === 200) {
				loadZones();
			}
		}
	};

	const columns: GridColDef[] = [
		{ field: 'name', headerName: 'Name', width: 400, flex: 1 },
		{
			field: 'actions',
			headerName: 'Actions',
			type: 'actions',
			width: 200,
			getActions: (params) => [
				<GridActionsCellItem icon={<FaEdit style={{ width: 20, height: 20 }} />} label="Edit" onClick={() => openEditZone(params.id)} />,
				<GridActionsCellItem icon={<FaTrash style={{ width: 20, height: 20, color: '#D24D57' }} />} label="Delete" onClick={() => deleteZone(params.id)} />,
			],
		},
	];

	return (
		<div className="py-10 px-2 h-full w-full">
			<div className="flex flex-col md:flex-row gap-6">
				<h1 className="text-black md:text-4xl text-2xl font-bold">Shipping Management</h1>
				<Button variant="contained" color="primary" onClick={() => handleNewZone()} style={{ backgroundColor: '#21187F', color: 'white' }}>
					Add shipping zone
				</Button>
			</div>
			<div className="rounded-lg border border-[#81848f3d] min-w-full p-8 pb-4 mt-8 overflow-x-auto">
				<div style={{ height: zones.length === 0 || isLoading ? '300px' : 'auto' }} className="mt-5 h-fit w-full">
					<DataGrid autoHeight rowCount={rowCountState} loading={isLoading} rows={zones} columns={columns} />
				</div>
			</div>
		</div>
	);
}

export default ShippingHome;
