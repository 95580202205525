import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { Button } from '@mui/material';
import { createAllowedSymbols, getAvailableAllowedSymbols, getImage } from '../../../server/server';
import useDebounce from '../../../hooks/useDebounce';
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';

function AddSymbolsModal({ modalIsOpen, closeModal, product, reloadSymbols }: any) {
	const [symbols, setSymbols] = useState([]);
	const [rowCountState, setRowCountState] = useState(5);
	const [isLoading, setIsLoading] = useState(true);
	const [searchQuery, setSearchQuery] = useState('');
	const [selectedSymbols, setSelectedSymbols] = useState<any[][]>([[]]);
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 5,
	});
	const searchDelay = 2000;
	const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);
	const debouncedQuery = useDebounce(searchQuery, searchDelay);

	useEffect(() => {
		if (modalIsOpen) {
			ReactModal.setAppElement('#root');
			loadSymbolsList(product?.id, paginationModel.page, paginationModel.pageSize, debouncedQuery);
		}
	}, [modalIsOpen]);

	useEffect(() => {
		if (modalIsOpen) {
			loadSymbolsList(product?.id, paginationModel.page, paginationModel.pageSize, debouncedQuery);
		}
	}, [paginationModel.page, paginationModel.pageSize, debouncedQuery]);

	const loadSymbolsList = async (productId: string, page?: any, pageSize?: any, searchQuery?: any) => {
		setIsLoading(true);
		const resp = await getAvailableAllowedSymbols(productId, pageSize, page * pageSize, searchQuery);
		if (resp.status === 200) {
			setSymbols(resp.data?.items);
			setRowCountState(resp.data?.total);
			setIsLoading(false);
		}
	};

	const onSubmit = async () => {
		createAllowedProductSymbols(selectedSymbols.flat());
	};

	const createAllowedProductSymbols = async (symbolsList: any) => {
		let data = createListOfSymbols(symbolsList);
		console.log('allowed modal ', data);
		const response = await createAllowedSymbols(data);
		if (response.status === 201) {
			console.log(' created ', response.data);
			closeModalAndReloadSymbols();
		}
	};

	const createListOfSymbols = (list: string[]) => {
		let newData: any = { allowedSymbols: [] };
		list.forEach((symbolId: string) => {
			let allowedSymbol = {
				productId: product?.id,
				symbolId: symbolId,
			};
			newData.allowedSymbols?.push(allowedSymbol);
		});
		return newData;
	};
	const closeModalAndReloadSymbols = () => {
		reloadSymbols();
		closeModal();
	};

	const reset = () => {
		setRowSelectionModel([]);
		setSelectedSymbols([[]]);
		setPaginationModel({
			page: 0,
			pageSize: 5,
		});
		setSymbols([]);
	};

	const columns: GridColDef[] = [
		{
			field: 'fileid',
			headerName: 'Image',
			renderCell: (params) => <img src={getImage(params.value)} alt="Icon" className=" h-[94%]" />,
			filterable: false,
			sortable: false,
			disableColumnMenu: true,
		},
		{ field: 'name', headerName: 'Symbol name', width: 300, flex: 1, sortable: false, disableColumnMenu: true },
		{
			field: 'categories',
			headerName: 'Category',
			width: 150,
			sortable: false,
			filterable: false,
			disableColumnMenu: true,
			valueGetter: (params: any) => {
				return params.row.categories?.map((e: any) => e?.name)?.join(', ') ?? 'Uncategorized';
			},
		},
		{ field: 'price', headerName: 'Price', width: 100, sortable: false, disableColumnMenu: true, cellClassName: 'text-center' },
	];
	return (
		<ReactModal
			ariaHideApp={false}
			//closeTimeoutMS={600}
			isOpen={modalIsOpen}
			onAfterOpen={() => {}}
			onAfterClose={() => reset()}
			onRequestClose={closeModal}
			className="no-scroll"
			style={{
				overlay: {
					zIndex: 5,
					backdropFilter: 'brightness(30%)',
				},
				content: {
					padding: 0,
					opacity: 1,
					width: 950,
					margin: 'auto',
					backgroundColor: 'transparent',
					height: '100%',
					maxHeight: '100%',
					border: 'none',
					boxShadow: 'none',
					overflow: 'auto',
					paddingTop: 15,
					paddingBottom: 15,
				},
			}}
		>
			<div className="   max-h-[600px] w-full  ">
				<div className=" flex flex-1 flex-col gap-5 rounded-[10px] bg-white  px-[40px] py-[30px] ">
					<p className=" text-center text-xl ">Add Symbols for product</p>
					<div className="mt-5 h-fit w-full">
						<DataGrid
							autoHeight
							checkboxSelection
							rowHeight={80}
							rowCount={rowCountState}
							loading={isLoading}
							disableRowSelectionOnClick
							pagination
							rows={symbols}
							columns={[...columns]}
							showCellVerticalBorder={false}
							style={{ minHeight: 371 }}
							pageSizeOptions={[5, 15, 25]}
							paginationModel={paginationModel}
							paginationMode="server"
							rowSelectionModel={selectedSymbols[paginationModel.page]}
							onRowSelectionModelChange={(newRowSelectionModel: GridRowSelectionModel) => {
								setRowSelectionModel(newRowSelectionModel);
								let list: any[] = [...newRowSelectionModel];
								selectedSymbols[paginationModel.page] = list;
							}}
							onPaginationModelChange={(newModel) => {
								if (newModel.pageSize !== paginationModel.pageSize) {
									setSelectedSymbols([[]]);
									setPaginationModel({
										page: 0,
										pageSize: newModel.pageSize,
									});
									return;
								} else {
									setPaginationModel(newModel);
									if (newModel.page + 1 > selectedSymbols.length) {
										setSelectedSymbols([...selectedSymbols, []]);
									}
								}
							}}
						/>
					</div>
					<div className="flex w-full flex-row justify-end gap-8 mt-10  ">
						<Button variant="contained" onClick={() => closeModal()} style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
							Cancel
						</Button>
						<Button variant="contained" onClick={() => onSubmit()} style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
							Submit
						</Button>
					</div>
				</div>
			</div>
		</ReactModal>
	);
}

export default AddSymbolsModal;
