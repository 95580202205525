import { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import './App.css';
import PublicRoute from './router/PublicRoute';
import PrivateRoute from './router/PrivateRoute';
import { Toaster } from 'react-hot-toast';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Login from './components/login/Login';
import Layout from './components/login/Layout';
import { LocalizationProvider } from '@mui/x-date-pickers';
import Product from './components/products/Product';
import Orders from './components/order/Orders';
import ProductDesigns from './components/productDesigns/ProductDesigns';
import ProductDesignForm from './components/productDesign/ProductDesignForm';

import Categories from './components/category/Categories';
import ManageCategory from './components/category/ManageCategory';

import LayoutHome from './components/layout/LayoutHome';
import NewProduct from './components/products/new-product/NewProduct';
import ShippingHome from './components/shipping/ShippingHome';
import ZoneScreen from './components/shipping/ZoneScreen';
import Languages from './components/language/Languages';
import Customers from './components/customer/Customers';
import SingleStaticPages from './components/singleStaticPages/SingleStaticPages';
import CompanyInfoManagemet from './components/companyInfo/CompanyInfoManagemet';
import Slider from './components/sliders/Slider';
import ManageSlider from './components/sliders/ManageSlider';
import MediaHome from './components/media/MediaHome';

import PaymentType from './components/paymentType/PaymentType';
import ViewSingleOrder from './components/order/ViewSingleOrder';
import { getAllFonts, getImage } from './server/server';
import { createGlobalStyle } from 'styled-components';
import Menu from './components/menu/Menu';

function App() {
	const [fonts, setFonts] = useState([]);
	useEffect(() => {
		loadFonts();
	}, []);

	const loadFonts = async () => {
		const resp = await getAllFonts();
		if (resp.status === 200) {
			setFonts(resp.data);
		} else {
			console.error('fonts are not loaded.');
		}
	};

	const GlobalStyle = createGlobalStyle`
		body { ${fonts.map((font: any) => {
			return `@font-face {
						font-family: ${font.name};
						src: url(${getImage(font.imageId)}) format('truetype');
						font-style: normal;
						font-weight: 500;
					}`;
		})} }
		`;

	return (
		<div>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<GlobalStyle />
				<Routes>
					<Route path="/login" element={<PublicRoute Component={Login} />} />
					<Route path="/" element={<Layout />}>
						<Route path="languages" element={<PrivateRoute Component={Languages} />} />

						<Route path="customers" element={<PrivateRoute Component={Customers} />} />

						<Route path="ssp" element={<PrivateRoute Component={SingleStaticPages} />} />

						<Route path="payment_types" element={<PrivateRoute Component={PaymentType} />} />

						<Route path="/sliders" element={<PrivateRoute Component={Slider} />} />
						<Route path="/sliders/manage" element={<PrivateRoute Component={ManageSlider} />} />
						<Route path="/sliders/manage/:id" element={<PrivateRoute Component={ManageSlider} />} />

						<Route path="companyInfo/manage" element={<PrivateRoute Component={CompanyInfoManagemet} />} />

						<Route path="orders" element={<PrivateRoute Component={Orders} />} />
						<Route path="orders/:orderId" element={<PrivateRoute Component={ViewSingleOrder} />} />

						<Route path="menu" element={<PrivateRoute Component={Menu} />} />

						<Route path="categories" element={<PrivateRoute Component={Categories} />} />
						<Route path="categories/manage/" element={<PrivateRoute Component={ManageCategory} />} />
						<Route path="categories/manage/:categoryId" element={<PrivateRoute Component={ManageCategory} />} />

						<Route path="media" element={<PrivateRoute Component={MediaHome} />} />

						<Route path="layout" element={<PrivateRoute Component={LayoutHome} />} />
						<Route path="layout/product_designs/form" element={<PrivateRoute Component={ProductDesignForm} />} />
						<Route path="shipping" element={<PrivateRoute Component={ShippingHome} />} />
						<Route path="shipping/zone/:id" element={<PrivateRoute Component={ZoneScreen} />} />

						<Route path="products" element={<PrivateRoute Component={Product} />} />
						<Route path="products/form/:id" element={<PrivateRoute Component={NewProduct} />} />
						<Route path="product_designs" element={<PrivateRoute Component={ProductDesigns} />} />
						<Route path="product_designs/form" element={<PrivateRoute Component={ProductDesignForm} />} />

						<Route path="" element={<Navigate to="/products" replace={true} />} />
						{/* <Route index element={<PrivateRoute Component={Home} />} /> */}
					</Route>
					<Route path="*" element={<Navigate to="/products" replace={true} />} />
				</Routes>
				<Toaster
					position="top-right"
					reverseOrder={false}
					containerStyle={{
						top: 125,
					}}
				/>
			</LocalizationProvider>
		</div>
	);
}

export default App;
