export const FONTS = ['Amarante', 'Black Ops One', 'Bree Serif', 'Cantora One', 'Courgette', 'Francois One', 'Grand Hotel', 'Grand Vibes', 'Khand', 'Limelight', 'Lobster', 'Oregano', 'Oswald', 'Playball', 'Rubik One', 'Sacramento', 'Sancreek'];
export const DEFAULT_NEW_TEXT = 'New text';
export const FabricTypeConverter: any = {
	'i-text': 'editText',
	'i-image': '',
};
export const TEXT_COLORS = ['#212121', '#FFFFFF', '#DE901C', '#4EDE1C', '#C52A2A', '#2AC5BB', '#522AC5', '#B82AC5'];
export const DEFAULT_COLOR = '#212121';
export const DEFAULT_FONT_SIZE = 30;
export const DELETE_IMAGE_STRING =
	'data:image/svg+xml;base64,' +
	btoa(`<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z" fill="#D4C4F6"/>
<path d="M16.2356 7.76478L7.76489 16.2354" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.76489 7.76478L16.2356 16.2354" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`);
//                                   LEFT, TOP, dx , dy
export const FAKE_RESTRICTION_AREA: [LEFT: number, TOP: number, dX: number, dY: number] = [220, 230, 290, 370];
export const RESTRICTION_AREA_BORDER = 3;
export const DEFAULT_TEXT_CONFIG = { editable: true, hasBorders: true, borderColor: 'rgba(255, 255, 255, 1)', borderDashArray: [4], padding: 0, fill: DEFAULT_COLOR, fontSize: DEFAULT_FONT_SIZE };
export const HideControls = {
	tl: false,
	tr: false,
	bl: false,
	br: false,
	ml: false,
	mt: false,
	mr: false,
	mb: false,
	mtr: false,
};
export const HideControlsText = {
	tl: false,
	tr: false,
	bl: false,
	br: false,
	ml: true,
	mt: false,
	mr: true,
	mb: false,
	mtr: true,
};

export const FAKE_SYMBOLS = [
	'https://files.upstrivesystem.com/public/bells.svg',

	'https://files.upstrivesystem.com/public/candy.svg',
	'https://files.upstrivesystem.com/public/christmas-decorations-for-trees-1.svg',
	'https://files.upstrivesystem.com/public/christmas-decorations-for-trees.svg',
	'https://files.upstrivesystem.com/public/christmas-deer-1.svg',
	'https://files.upstrivesystem.com/public/christmas-deer.svg',
	'https://files.upstrivesystem.com/public/christmas-bird.svg',
	'https://files.upstrivesystem.com/public/christmas-candy.svg',
];
export const VIRTUAL_CANVAS_SIZE = 500;
export const INITIAL_SCALE_FACTOR = 1.5;
export const LINE_CONFIGURATION = {
	stroke: 'rgba(255, 255, 255, 1)',
	strokeWidth: 3,
	selectable: false,
	hoverCursor: 'default',
	moveCursor: 'default',
	name: 'restriction_area',
};

export const countries: any = {
	AF: 'Afghanistan',
	AL: 'Albania',
	DZ: 'Algeria',
	AS: 'American Samoa',
	AD: 'Andorra',
	AO: 'Angola',
	AI: 'Anguilla',
	AG: 'Antigua and Barbuda',
	AR: 'Argentina',
	AM: 'Armenia',
	AW: 'Aruba',
	AU: 'Australia',
	AT: 'Austria',
	AZ: 'Azerbaijan',
	BS: 'Bahamas',
	BH: 'Bahrain',
	BD: 'Bangladesh',
	BB: 'Barbados',
	BY: 'Belarus',
	BE: 'Belgium',
	BZ: 'Belize',
	BJ: 'Benin',
	BM: 'Bermuda',
	BT: 'Bhutan',
	BO: 'Bolivia, Plurinational State of',
	BA: 'Bosnia and Herzegovina',
	BW: 'Botswana',
	BR: 'Brazil',
	IO: 'British Indian Ocean Territory',
	BG: 'Bulgaria',
	BF: 'Burkina Faso',
	BI: 'Burundi',
	KH: 'Cambodia',
	CM: 'Cameroon',
	CA: 'Canada',
	CV: 'Cape Verde',
	KY: 'Cayman Islands',
	CF: 'Central African Republic',
	TD: 'Chad',
	CL: 'Chile',
	CN: 'China',
	CO: 'Colombia',
	KM: 'Comoros',
	CG: 'Congo',
	CD: 'Democratic Republic of the Congo',
	CK: 'Cook Islands',
	CR: 'Costa Rica',
	CI: "Côte d'Ivoire",
	HR: 'Croatia',
	CU: 'Cuba',
	CW: 'Curaçao',
	CY: 'Cyprus',
	CZ: 'Czech Republic',
	DK: 'Denmark',
	DJ: 'Djibouti',
	DM: 'Dominica',
	DO: 'Dominican Republic',
	EC: 'Ecuador',
	EG: 'Egypt',
	SV: 'El Salvador',
	GQ: 'Equatorial Guinea',
	ER: 'Eritrea',
	EE: 'Estonia',
	ET: 'Ethiopia',
	FK: 'Falkland Islands (Malvinas)',
	FO: 'Faroe Islands',
	FJ: 'Fiji',
	FI: 'Finland',
	FR: 'France',
	PF: 'French Polynesia',
	GA: 'Gabon',
	GM: 'Gambia',
	GE: 'Georgia',
	DE: 'Germany',
	GH: 'Ghana',
	GI: 'Gibraltar',
	GR: 'Greece',
	GL: 'Greenland',
	GD: 'Grenada',
	GU: 'Guam',
	GT: 'Guatemala',
	GG: 'Guernsey',
	GN: 'Guinea',
	GW: 'Guinea-Bissau',
	HT: 'Haiti',
	HN: 'Honduras',
	HK: 'Hong Kong',
	HU: 'Hungary',
	IS: 'Iceland',
	IN: 'India',
	ID: 'Indonesia',
	IR: 'Iran, Islamic Republic of',
	IQ: 'Iraq',
	IE: 'Ireland',
	IM: 'Isle of Man',
	IL: 'Israel',
	IT: 'Italy',
	JM: 'Jamaica',
	JP: 'Japan',
	JE: 'Jersey',
	JO: 'Jordan',
	KZ: 'Kazakhstan',
	KE: 'Kenya',
	KI: 'Kiribati',
	KP: 'North Korea',
	KR: 'South Korea',
	KW: 'Kuwait',
	KG: 'Kyrgyzstan',
	LA: "Lao People's Democratic Republic",
	LV: 'Latvia',
	LB: 'Lebanon',
	LS: 'Lesotho',
	LR: 'Liberia',
	LY: 'Libya',
	LI: 'Liechtenstein',
	LT: 'Lithuania',
	LU: 'Luxembourg',
	MO: 'Macao',
	MK: 'Republic of Macedonia',
	MG: 'Madagascar',
	MW: 'Malawi',
	MY: 'Malaysia',
	MV: 'Maldives',
	ML: 'Mali',
	MT: 'Malta',
	MH: 'Marshall Islands',
	MQ: 'Martinique',
	MR: 'Mauritania',
	MU: 'Mauritius',
	MX: 'Mexico',
	FM: 'Micronesia, Federated States of',
	MD: 'Republic of Moldova',
	MC: 'Monaco',
	MN: 'Mongolia',
	ME: 'Montenegro',
	MS: 'Montserrat',
	MA: 'Morocco',
	MZ: 'Mozambique',
	MM: 'Myanmar',
	NA: 'Namibia',
	NR: 'Nauru',
	NP: 'Nepal',
	NL: 'Netherlands',
	NZ: 'New Zealand',
	NI: 'Nicaragua',
	NE: 'Niger',
	NG: 'Nigeria',
	NU: 'Niue',
	NF: 'Norfolk Island',
	MP: 'Northern Mariana Islands',
	NO: 'Norway',
	OM: 'Oman',
	PK: 'Pakistan',
	PW: 'Palau',
	PS: 'Palestinian Territory',
	PA: 'Panama',
	PG: 'Papua New Guinea',
	PY: 'Paraguay',
	PE: 'Peru',
	PH: 'Philippines',
	PN: 'Pitcairn',
	PL: 'Poland',
	PT: 'Portugal',
	PR: 'Puerto Rico',
	QA: 'Qatar',
	RO: 'Romania',
	RU: 'Russia',
	RW: 'Rwanda',
	KN: 'Saint Kitts and Nevis',
	LC: 'Saint Lucia',
	WS: 'Samoa',
	SM: 'San Marino',
	ST: 'Sao Tome and Principe',
	SA: 'Saudi Arabia',
	SN: 'Senegal',
	RS: 'Serbia',
	SC: 'Seychelles',
	SL: 'Sierra Leone',
	SG: 'Singapore',
	SX: 'Sint Maarten',
	SK: 'Slovakia',
	SI: 'Slovenia',
	SB: 'Solomon Islands',
	SO: 'Somalia',
	ZA: 'South Africa',
	SS: 'South Sudan',
	ES: 'Spain',
	LK: 'Sri Lanka',
	SD: 'Sudan',
	SR: 'Suriname',
	SZ: 'Swaziland',
	SE: 'Sweden',
	CH: 'Switzerland',
	SY: 'Syria',
	TW: 'Taiwan',
	TJ: 'Tajikistan',
	TZ: 'Tanzania',
	TH: 'Thailand',
	TG: 'Togo',
	TK: 'Tokelau',
	TO: 'Tonga',
	TT: 'Trinidad and Tobago',
	TN: 'Tunisia',
	TR: 'Turkey',
	TM: 'Turkmenistan',
	TC: 'Turks and Caicos Islands',
	TV: 'Tuvalu',
	UG: 'Uganda',
	UA: 'Ukraine',
	AE: 'United Arab Emirates',
	GB: 'United Kingdom',
	US: 'United States',
	UY: 'Uruguay',
	UZ: 'Uzbekistan',
	VU: 'Vanuatu',
	VE: 'Venezuela, Bolivarian Republic of',
	VN: 'Viet Nam',
	VI: 'Virgin Islands',
	YE: 'Yemen',
	ZM: 'Zambia',
	ZW: 'Zimbabwe',
};
export const fullCountryCodes = Object.keys(countries);
export const fullCountryNames: string[] = Object.values(countries);

export function isJSON(str: any) {
	try {
		JSON.parse(str);
		return true;
	} catch (e) {
		return false;
	}
}

export const DEFAULT_LEFT = 50;
export const DEFAULT_TOP = 50;
export const DEFAULT_WIDTH = 60;
export const DEFAULT_HEIGHT = 70;
export const DEFAULT_RESTRICTED_AREA = [DEFAULT_LEFT, DEFAULT_TOP, DEFAULT_WIDTH, DEFAULT_HEIGHT];

export const checkAllowedExtensions = (name: string) => {
	if (name.toLowerCase().includes('.png') || name.toLowerCase().includes('.jpg') || name.toLowerCase().includes('.gif') || name.toLowerCase().includes('.svg') || name.toLowerCase().includes('.jpeg') || name.toLowerCase().includes('.mp4') || name.toLowerCase().includes('.avi')) return true;
	else {
		return false;
	}
};
export const checkAllowedExtensionsForList = (files: any) => {
	let valid = true;
	files.forEach((element: any) => {
		if (!checkAllowedExtensions(element.name)) {
			valid = false;
			return valid;
		}
	});
	return valid;
};
export const checkAllowedExtensionsForFonts = (name: string) => {
	if (name.toLowerCase().includes('.otf') || name.toLowerCase().includes('.ttf') || name.toLowerCase().includes('.woff') || name.toLowerCase().includes('.woff2')) return true;
	else {
		return false;
	}
};
