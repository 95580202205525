import { DataGrid, GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { deleteCustomer, getCustomers } from '../../server/server';
import ManageCustomerModal from './modals/ManageCustomerModal';
import Dialog from '@mui/material/Dialog';
import useDebounce from '../../hooks/useDebounce';

type Customer = {
	id: string;
	zone: any;
	user: any;
	firstName: string;
	lastName: string;
	company: string;
	vatNo: string;
	billingAddress: any;
	shippingAddress: any;
};

const Customers = () => {
	const [customers, setCustomers] = useState<Customer[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [rowCountState, setRowCountState] = useState(5);
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 5,
	});

	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [selectedCustomer, setSelectedCustomer] = useState<Customer>();

	useEffect(() => {
		fetchCustomers();
	}, []);

	const filterCustomers = (searchText: string) => {
		return customers.filter((customer) => customer.firstName.toLowerCase().includes(searchText.toLowerCase()));
	};

	const handleSetDefaultAddress = (addressType: 'billing' | 'shipping', addressId: string) => {
		fetchCustomers();
	};

	const fetchCustomers = async () => {
		setIsLoading(true);

		const resp = await getCustomers(paginationModel.pageSize, paginationModel.page);

		if (resp.status == 200) {
			setCustomers(resp.data?.items);
			setRowCountState(resp.data.total);
			setIsLoading(false);
		}
	};

	const editCustomer = (id: any) => {
		const customer = customers.find((customer) => customer?.id === id);
		setSelectedCustomer(customer);

		setIsEditModalOpen(true);
	};

	const deleteRowCustomer = async (id: any) => {
		const resp = await deleteCustomer(id);
		if (resp.status == 200) {
			fetchCustomers();
		}
	};



	const columns: GridColDef[] = [
		{
			field: 'firstName', headerName: 'Name', filterable: false
			, flex: 1,
			sortable: false,
			valueGetter: (params) => {
				return params?.row?.firstName + ' ' + params?.row?.lastName;
			}
		},
		{ field: 'email', headerName: 'Email', filterable: true, minWidth: 200, flex: 1 },

		{
			field: 'lastOrderTime',
			headerName: 'Last order time',
			filterable: false,
			flex: 1,
			sortable: false,

			valueGetter: (params) => {

				return params?.row?.lastOrderTime?.toLocaleString('en-GB', {
					day: '2-digit',
					month: '2-digit',
					year: 'numeric',
					hour: 'numeric',
					minute: 'numeric',
					hour12: false,
				});;
			},
		},
		{
			field: 'numberOfOrders',
			headerName: 'Orders',
			flex: 1,
			filterable: false,
		},
		{
			field: 'totalSpend',
			headerName: 'Total spent',
			sortable: false,
			filterable: false,
			flex: 1,
			valueGetter: (params) => {



				let currencySymbol;

				if (params.row?.billingAddress?.country !== 'Serbia') {
					currencySymbol = 'RSD';
				} else {
					currencySymbol = '€';
				}

				// Convert the total value sum to string and append the symbol
				return params?.row?.totalSpend?.toString() || 0 + ' ' + currencySymbol;
			},
		},
		{
			field: 'aov',
			headerName: 'AOV',
			filterable: false,
			flex: 1,
			sortable: false,
			valueGetter: (params) => {

				let currencySymbol;

				if (params.row?.billingAddress?.country !== 'Serbia') {
					currencySymbol = 'RSD';
				} else {
					currencySymbol = '€';
				}

				return params?.row?.aov?.toString() + ' ' + currencySymbol;
			},
		},
		{
			field: 'billingAddresses',
			headerName: 'Country',
			filterable: false,
			sortable: false,
			flex: 1,
			valueGetter: (params) => {
				return params.row?.billingAddresses?.[0]?.country?.name || params.row?.shippingAddresses?.[0]?.country?.name;
			},
		},
		{
			field: 'city',
			headerName: 'City',
			flex: 1,
			filterable: false,
			sortable: false,
			valueGetter: (params) => {
				return params.row?.billingAddresses?.[0]?.city || params.row?.shippingAddresses?.[0]?.city;
			},
		},
		{
			field: 'postCode',
			headerName: 'Postal code',
			minWidth: 150,
			filterable: false,
			sortable: false,
			valueGetter: (params) => {
				return params.row?.billingAddresses?.[0]?.zip || params.row?.shippingAddresses?.[0]?.zip;
			},
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			sortable: false,
			getActions: (params) => [
				<GridActionsCellItem icon={<FaEdit style={{ width: 20, height: 20 }} />} label="Edit" onClick={() => editCustomer(params.id)} />,
				<GridActionsCellItem icon={<FaTrash style={{ width: 20, height: 20, color: '#D24D57' }} />} label="Delete" onClick={() => deleteRowCustomer(params.id)} />,
			],
		},
	];

	return (
		<div className="py-10 px-2 h-full w-full">
			<div className="flex flex-col md:flex-row gap-6 mt-10">
				<h1 className="text-black text-4xl font-bold">Customers</h1>
			</div>
			<DataGrid
				/*style={{ height: customers.length === 0 || isLoading ? '300px' : 'auto' }}*/
				className="rounded-lg border border-[#81848f3d] min-w-full p-8 pb-4 mt-8 overflow-x-auto sm:mr-4 md:mr-4"
				autoHeight
				rows={customers}
				columns={columns}
				rowCount={rowCountState}
				loading={isLoading}
				pageSizeOptions={[10, 25, 50]}
				paginationModel={paginationModel}
				paginationMode="client"
				onPaginationModelChange={(newModel) => {
					setPaginationModel(newModel);
				}}
			/>
			{selectedCustomer && (
				<Dialog open={selectedCustomer !== null} onClose={() => setSelectedCustomer(undefined)}>
					<ManageCustomerModal isOpen={selectedCustomer !== null} onSetDefaultAddress={handleSetDefaultAddress} customer={selectedCustomer} onClose={() => setSelectedCustomer(undefined)} />
				</Dialog>
			)}
		</div>
	);
};

export default Customers;
