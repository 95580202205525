import { Button, TextField } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridColDef, GridRowId } from '@mui/x-data-grid';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { deleteCategory, disableCategory, getAllCategories, getCategories } from '../../server/server';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useDebounce from '../../hooks/useDebounce';
import CustomTreeView from '../treeView/CustomTreeView';

interface Category {
	id: GridRowId;
}

const Categories = () => {
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(true);
	const [categories, setCategories] = useState<Category[]>([]);
	const [rowCountState, setRowCountState] = useState(5);
	const [searchQuery, setSearchQuery] = useState('');
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 5,
	});
	const [filteredRows, setFilteredRows] = useState<Category[]>([]);

	const searchDelay = 700;

	const debouncedQuery = useDebounce(searchQuery, searchDelay);

	useEffect(() => {
		console.log(paginationModel);
		fetchCategories(paginationModel.page, paginationModel.pageSize, debouncedQuery);
	}, [paginationModel.page, paginationModel.pageSize, debouncedQuery]);

	const fetchCategories = async (page: any, pageSize: any, searchQuery?: any): Promise<void> => {
		setIsLoading(true);

		const resp = await getCategories(pageSize, page * pageSize, searchQuery);

		if (resp.status == 200) {
			console.log(resp);
			setCategories(resp.data.categories);
			setRowCountState(resp.data.total);
			setIsLoading(false);
		} else {
			setIsLoading(false);
		}
	};

	const editCategory = (id: GridRowId): void => {
		const selectedCat = categories.find((cat) => cat.id === id);
		navigate(`/categories/manage/${selectedCat?.id}`);
	};

	async function deleteRowCategory(id: GridRowId): Promise<void> {
		if (window.confirm('Do you want to delete this category?')) {
			setCategories((prevCat) => prevCat.filter((category: any) => category.id !== id));

			const resp = await deleteCategory(id);

			if (resp.status == 200) {
				alert('Category deleted successfully');

				fetchCategories(paginationModel.page, paginationModel.pageSize, debouncedQuery);
			} else {
				alert(`Error deleting category: ${resp.status}`);
			}
		}
	}

	function handleNewCategory(): void {
		navigate('/categories/manage');
	}

	const columns: GridColDef[] = [
		{ field: 'name', headerName: 'Name', width: 200, filterable: true },
		{ field: 'description', headerName: 'Description', flex: 1, minWidth: 300, filterable: true, valueGetter: (params) => params?.row?.description },
		{ field: 'slug', headerName: 'Slug', filterable: true, valueGetter: (params) => params?.row?.urlSlugs },
		{ field: 'count', headerName: 'Count', filterable: true, valueGetter: (params) => params.row?.productsCount },
		{
			field: 'actions',
			type: 'actions',
			width: 100,
			getActions: (params) => [
				<GridActionsCellItem icon={<FaEdit style={{ width: 20, height: 20 }} />} label="Edit" onClick={() => editCategory(params?.id)} />,
				<GridActionsCellItem icon={<FaTrash style={{ width: 20, height: 20, color: '#D24D57' }} />} label="Delete" onClick={() => deleteRowCategory(params?.id)} />,
			],
		},
	];

	return (
		<div className="py-10 px-2 h-full w-full">
			<div className="py-10 h-full w-full">
				<div className="flex flex-col md:flex-row gap-6">
					<h1 className="text-black text-4xl font-bold">Categories</h1>
					<Button variant="contained" color="primary" onClick={() => handleNewCategory()} style={{ backgroundColor: '#21187F', color: 'white' }}>
						Add New Category
					</Button>
				</div>
				<div className="rounded-lg border border-[#81848f3d] min-w-full p-8 pb-4 mt-8">
					<div className="flex justify-end items-center ">
						<TextField label="Search" variant="outlined" value={searchQuery} onChange={(e: any) => setSearchQuery(e.target.value)} style={{ marginRight: '5px' }} />
					</div>
					<div /*style={{ height: categories.length === 0 || filteredRows.length === 0 || isLoading ? '300px' : 'auto' }}*/ className="mt-5 h-fit w-full overflow-x-auto">
						<DataGrid
							autoHeight
							className="w-full"
							rows={categories}
							columns={columns}
							rowCount={rowCountState}
							loading={isLoading}
							pageSizeOptions={[5, 15, 25]}
							paginationModel={paginationModel}
							paginationMode="server"
							onPaginationModelChange={(newModel) => {
								setPaginationModel((prevModel) => ({
									...prevModel,
									page: newModel.page,
									pageSize: newModel.pageSize,
								}));
							}}
							onFilterModelChange={(model) => {
								const newFilteredRows = model.items.reduce((currentRows, filterItem) => {
									return currentRows.filter((row) => String(row[filterItem.field as keyof Category]).includes(filterItem.value));
								}, categories);
								setFilteredRows(newFilteredRows);
							}}
						/>
					</div>
				</div>
				<div className="flex flex-col mt-5 gap-5 w-auto">
					<div>
						<label htmlFor="Categories">Category tree</label>
					</div>
					<div className="border rounded w-fit max-h-[300px] overflow-auto gray-small-scroll">
						<CustomTreeView showCheckboxes={false} />
					</div>
				</div>
			</div>
		</div>
	);
};
export default Categories;
