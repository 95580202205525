import React, { useEffect, useState } from 'react';
import { DataGrid, GridRowId, GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Button, FormControl, Select, MenuItem, InputLabel, Tooltip, Pagination } from '@mui/material';
import { deleteProduct, getAllProducts, getImage, getProductDesigns, removeProductDesign } from '../../server/server';
import { useNavigate } from 'react-router-dom';
import useDebounce from '../../hooks/useDebounce';

function ProductDesigns() {
	let navigate = useNavigate();
	const [products, setProducts] = useState([]);
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(5);
	const [rowCountState, setRowCountState] = useState(5);
	const [isLoading, setIsLoading] = useState(true);
	const [searchQuery, setSearchQuery] = useState('');
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10,
	});
	const searchDelay = 2000;

	const debouncedQuery = useDebounce(searchQuery, searchDelay);

	const loadProducts = async (page?: any, pageSize?: any, searchQuery?: any) => {
		setIsLoading(true);
		const resp = await getProductDesigns(pageSize, page * pageSize, searchQuery);
		if (resp.status === 200) {
			console.log(resp);

			setProducts(resp.data.data);
			setRowCountState(resp.data.size);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		loadProducts(paginationModel.page, paginationModel.pageSize, debouncedQuery);
	}, [paginationModel.page, debouncedQuery]);

	const handleDeleteProduct = async (id: string) => {
		const resp = await removeProductDesign(id);
		alert('Design deleted successfully');
	};
	const deleteRowProduct = (id: any) => {
		setProducts((prevProducts) => prevProducts.filter((product: any) => product.id !== id));
		handleDeleteProduct(id);
	};

	const handleNewProduct = () => {
		navigate('/layout/product_designs/form');
	};

	const editProduct = (id: any) => {
		navigate('/layout/product_designs/form?id=' + id);
	};

	const columns: GridColDef[] = [
		{ field: 'name', headerName: 'Design name', width: 200, sortable: false, disableColumnMenu: true, flex: 1 },
		{
			field: 'categories',
			headerName: 'Category',
			width: 200,
			sortable: false,
			disableColumnMenu: true,
			valueGetter: (params: any) => {
				return params.row.categories?.map((e: any) => e?.name)?.join(', ') ?? 'Uncategorized';
			},
		},
		{ field: 'price', headerName: 'Price', width: 100, sortable: false, disableColumnMenu: true, cellClassName: 'text-center' },
		{
			field: 'icon',
			headerName: 'Small icon',
			width: 100,
			renderCell: (params) =>
				params.value ? (
					<a href={getImage(params.value)} target="_blank" rel="noreferrer">
						<img src={getImage(params.value)} alt="Icon" className="mx-[auto]" width="50" />
					</a>
				) : (
					''
				),
			sortable: false,
			disableColumnMenu: true,
		},
		{
			field: 'thumbnail',
			headerName: 'Thumbnail',
			width: 100,
			renderCell: (params) =>
				params.value ? (
					<a href={getImage(params.value)} target="_blank" rel="noreferrer">
						<img src={getImage(params.value)} alt="Thumbnail" width="50" className="mx-[auto]" />
					</a>
				) : (
					''
				),
			sortable: false,
			disableColumnMenu: true,
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			width: 120,
			getActions: (params) => [
				<GridActionsCellItem icon={<FaEdit style={{ width: 20, height: 20 }} />} label="Edit" onClick={() => editProduct(params.id)} style={{ justifyContent: 'center' }} />,
				<GridActionsCellItem icon={<FaTrash style={{ width: 20, height: 20, color: '#D24D57' }} />} label="Delete" onClick={() => deleteRowProduct(params.id)} />,
			],
		},
	];

	return (
		<div>
			<div className="mt-8">
				<Button variant="contained" onClick={handleNewProduct} style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
					Add New
				</Button>
			</div>
			<div className="rounded-lg border border-[#81848f3d] min-w-fit px-8 py-5 mt-5">
				<div className=" h-fit w-full">
					<DataGrid
						autoHeight
						//autoPageSize
						rowCount={rowCountState}
						loading={isLoading}
						disableRowSelectionOnClick
						pagination
						rows={products}
						columns={[...columns]}
						showCellVerticalBorder={false}
						style={{ minHeight: 371 }}
						pageSizeOptions={[5, 15, 25]}
						paginationModel={paginationModel}
						paginationMode="server"
						onPaginationModelChange={(newModel) => {
							setPaginationModel(newModel);
							loadProducts(newModel.page, newModel.pageSize, debouncedQuery);
						}}
					/>
				</div>
			</div>
		</div>
	);
}

export default ProductDesigns;
