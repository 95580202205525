import React, { useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { tempImg } from './MediaHome';
import SingleImageModal from './SingleImageModal';
import { Button } from '@mui/material';
import { getImage } from '../../server/server';

function GridList({ mediaList, rowSelectionModel, isBulk, setRowSelectionModel, loadMore, rowCountState, paginationModel, deleteImage }: { mediaList: any; rowSelectionModel: any; isBulk: any; setRowSelectionModel: any; loadMore: any; rowCountState: any; paginationModel: any; deleteImage: any }) {
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [selectedPicture, setSelectedPicture] = useState<any>(null);
	const closeModal = () => {
		setSelectedPicture(null);
		setModalIsOpen(false);
	};

	const openSingleImageModal = (item: any) => {
		setSelectedPicture(item);
		setModalIsOpen(true);
	};

	const handleSelectImage = (newItem: any) => {
		if (isBulk) {
			const existingItem = rowSelectionModel.find((item: any) => item === newItem.id);
			if (!existingItem) {
				setRowSelectionModel([...rowSelectionModel, newItem.id]);
			} else {
				let newItemList = rowSelectionModel.filter((item: any) => item !== newItem.id);
				setRowSelectionModel(newItemList);
			}
		} else {
			openSingleImageModal(newItem);
		}
	};
	return (
		<div>
			<div className="grid grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-7 gap-3 2xl:grid-cols-8 mt-5">
				{mediaList.map((item: any, index: number) => (
					<div className={`flex flex-col border border-[#aeaeae] cursor-pointer relative ${isBulk && rowSelectionModel.includes(item.id) ? 'shadow-md shadow-darkBlue' : ''}`} title={item.name} onClick={() => handleSelectImage(item)} key={index}>
						<img className=" aspect-square object-cover object-top" src={getImage(item.id)} alt="" height={'100%'} loading="lazy" />
						<p className="w-full line-clamp-2 text-center">{item.name}</p>
						{isBulk && rowSelectionModel.includes(item.id) && (
							<div className="absolute -top-1 -right-1 border border-darkBlue p-[1px] bg-white">
								<div className="bg-darkBlue p-[2px]">
									<FaCheck color="white" />
								</div>
							</div>
						)}
					</div>
				))}
			</div>
			<div className="py-5">
				<div className="flex flex-col items-center justify-center gap-3">
					{mediaList.length === 0 && <p className="text-[#4e4e4e] text-base">No media files added</p>}
					{mediaList.length !== 0 && (
						<p className="text-[#4e4e4e] text-base">
							Showing {mediaList.length} of {rowCountState} media items
						</p>
					)}
					{rowCountState > (paginationModel.page + 1) * paginationModel.pageSize && (
						<Button variant="outlined" onClick={() => loadMore()} style={{ backgroundColor: '#0F2765', color: 'white' }}>
							Load more
						</Button>
					)}
				</div>
			</div>
			<SingleImageModal modalIsOpen={modalIsOpen} closeModal={closeModal} selectedPicture={selectedPicture} deleteImage={deleteImage} />
		</div>
	);
}

export default GridList;
