import { Button, TextField } from '@mui/material';
import { GridColDef, GridActionsCellItem, DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { deleteSymbolById, deleteSymbolCategoryById, getImage, getSymbolCategory, getSymbols, getSymbolsPagination } from '../../server/server';
import AddSymbolsModal from './AddSymbolsModal';
import useDebounce from '../../hooks/useDebounce';
import AddCategoryModal from './AddCategoryModal';

function Symbols() {
	const [rowCountState, setRowCountState] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [symbols, setSymbols] = useState([]);
	const [categories, setCategories] = useState([]);
	const [addNewSymbolsModalOpen, setAddNewSymbolsModalOpen] = useState(false);
	const [addNewCategoryModalOpen, setAddNewCategoryModalOpen] = useState(false);
	const [selectedSymbol, setSelectedSymbol] = useState(null);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [searchQuery, setSearchQuery] = useState('');
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 8,
	});
	const searchDelay = 700;
	const debouncedQuery = useDebounce(searchQuery, searchDelay);

	useEffect(() => {
		loadSymbolsList(paginationModel.page, paginationModel.pageSize, debouncedQuery);
	}, [paginationModel.page, paginationModel.pageSize, debouncedQuery]);

	useEffect(() => {
		loadSymbolsCategory();
	}, []);

	const loadSymbolsCategory = async () => {
		setIsLoading(true);
		const resp = await getSymbolCategory();
		if (resp.status === 200) {
			setCategories(resp.data.items);
			setIsLoading(false);
		} else {
			setIsLoading(false);
		}
	};

	const loadSymbolsList = async (page: any, pageSize: any, searchQuery?: any) => {
		setIsLoading(true);
		const resp = await getSymbolsPagination(pageSize, page * pageSize, searchQuery);
		if (resp.status === 200) {
			setSymbols(resp.data.data);
			setRowCountState(resp.data.size);
			setIsLoading(false);
		} else {
			setIsLoading(false);
		}
	};

	const closeAddNewSymbolsModal = () => {
		setAddNewSymbolsModalOpen(false);
		setSelectedSymbol(null);
	};

	const openEditSymbolModal = (item: any) => {
		setSelectedSymbol(item);
		setAddNewSymbolsModalOpen(true);
	};

	const closeAddCategoryModal = () => {
		setSelectedCategory(null);
		setAddNewCategoryModalOpen(false);
	};

	const openAddCategoryModal = (item: any) => {
		setSelectedCategory(item);
		setAddNewCategoryModalOpen(true);
	};

	const deleteSymbol = async (data: any) => {
		if (window.confirm('Do you want to delete this symbol?')) {
			const respSymbol = await deleteSymbolById(data);
			if (respSymbol.status === 200) {
				reloadSymbols();
			}
		}
	};

	const deleteCategory = async (data: any) => {
		if (window.confirm('Do you want to delete this symbol category?')) {
			const respSymbol = await deleteSymbolCategoryById(data);
			if (respSymbol.status === 200) {
				loadSymbolsCategory();
			}
		}
	};

	const reloadSymbols = () => {
		if (selectedSymbol !== null) {
			loadSymbolsList(paginationModel.page, paginationModel.pageSize, debouncedQuery);
		} else {
			if (paginationModel.page === 0) {
				loadSymbolsList(paginationModel.page, paginationModel.pageSize, debouncedQuery);
			} else {
				setPaginationModel({
					page: 0,
					pageSize: 8,
				});
			}
		}
	};

	const handleSearchQueryChange = (e: any) => {
		setSearchQuery(e.target.value);
	};

	const columns: GridColDef[] = [
		{ field: 'image', headerName: 'Image', filterable: false, sortable: false, width: 100, renderCell: (params) => <img src={getImage(params.value)} alt="product" style={{ height: '94%', objectFit: 'cover' }} /> },
		{ field: 'name', headerName: 'Name', width: 300, flex: symbols.length === 0 ? 0 : 1 },
		{
			field: 'actions',
			headerName: 'Actions',
			type: 'actions',
			width: 100,
			getActions: (params) => [
				<GridActionsCellItem icon={<FaEdit style={{ width: 20, height: 20 }} />} label="Edit" onClick={() => openEditSymbolModal(params.row)} />,
				<GridActionsCellItem
					icon={<FaTrash style={{ width: 20, height: 20, color: '#D24D57' }} />}
					label="Delete"
					onClick={() => {
						deleteSymbol(params.id);
					}}
				/>,
			],
		},
	];
	const columnsCategory: GridColDef[] = [
		{ field: 'name', headerName: 'Category name', width: 300, flex: categories.length === 0 ? 0 : 1 },
		{
			field: 'actions',
			headerName: 'Actions',
			type: 'actions',
			width: 100,
			getActions: (params) => [
				<GridActionsCellItem icon={<FaEdit style={{ width: 20, height: 20 }} />} label="Edit" onClick={() => openAddCategoryModal(params.row)} />,
				<GridActionsCellItem
					icon={<FaTrash style={{ width: 20, height: 20, color: '#D24D57' }} />}
					label="Delete"
					onClick={() => {
						deleteCategory(params.id);
					}}
				/>,
			],
		},
	];
	return (
		<div className="w-full">
			<div className="grid grid-cols-2 gap-7">
				<div className="flex flex-col">
					<div className="flex flex-row items-center justify-between border border-[#aeaeae] p-2 mt-5 shadow-md">
						<TextField
							label="Search"
							value={searchQuery}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								handleSearchQueryChange(event);
							}}
						/>
						<Button variant="contained" onClick={() => setAddNewSymbolsModalOpen(true)} style={{ backgroundColor: '#2ec4b6', color: 'white', marginRight: 20 }}>
							Add new symbol
						</Button>
					</div>
					<div className="rounded-lg border border-[#81848f3d] min-w-fit px-8 py-5 mt-5">
						<div className=" h-fit w-full max-h-[631px] overflow-auto pr-1 gray-small-scroll">
							<DataGrid
								autoHeight
								rowHeight={65}
								rowCount={rowCountState}
								loading={isLoading}
								disableRowSelectionOnClick
								disableColumnFilter
								disableColumnMenu
								pagination
								rows={symbols}
								columns={[...columns]}
								showCellVerticalBorder={false}
								pageSizeOptions={[8, 16, 32]}
								paginationModel={paginationModel}
								paginationMode="server"
								onPaginationModelChange={(newModel) => {
									if (newModel.pageSize !== paginationModel.pageSize) {
										setPaginationModel({
											page: 0,
											pageSize: newModel.pageSize,
										});
										return;
									} else {
										setPaginationModel(newModel);
									}
								}}
							/>
						</div>
					</div>
				</div>
				<div className="flex flex-col">
					<div className="h-[74px] flex flex-row items-center justify-end border border-[#aeaeae] p-2 mt-5 shadow-md">
						<Button variant="contained" onClick={() => setAddNewCategoryModalOpen(true)} style={{ backgroundColor: '#2ec4b6', color: 'white', marginRight: 20 }}>
							Add new symbol category
						</Button>
					</div>
					<div className="rounded-lg border border-[#81848f3d] min-w-fit px-8 py-5 mt-5">
						<div className=" h-fit w-full max-h-[631px] overflow-auto pr-1 gray-small-scroll">
							<DataGrid
								autoHeight
								rowHeight={65}
								loading={isLoading}
								disableRowSelectionOnClick
								rows={categories}
								columns={[...columnsCategory]}
								initialState={{
									pagination: {
										paginationModel: { pageSize: 8, page: 0 },
									},
								}}
								pageSizeOptions={[8, 16, 24]}
								disableColumnFilter
								disableColumnMenu
								showCellVerticalBorder={false}
							/>
						</div>
					</div>
				</div>
			</div>
			<AddSymbolsModal modalIsOpen={addNewSymbolsModalOpen} closeModal={closeAddNewSymbolsModal} selectedSymbol={selectedSymbol} reloadSymbols={reloadSymbols} categories={categories} />
			<AddCategoryModal modalIsOpen={addNewCategoryModalOpen} closeModal={closeAddCategoryModal} category={selectedCategory} reloadCategories={loadSymbolsCategory} />
		</div>
	);
}

export default Symbols;
