import React from 'react';
import { FaPencilAlt } from 'react-icons/fa';
import { Button, InputLabel } from '@mui/material';
import { Order } from '../OrderInterfaces';

interface ShippingAddressProps {
	order: Order;
	onEditShippingAddress: (address: any) => void;
}

const ShippingAddress: React.FC<ShippingAddressProps> = ({ order, onEditShippingAddress }) => {
	console.log(order);

	return (
		<div>
			<Button onClick={() => onEditShippingAddress(order?.shippingAddress)}>
				<FaPencilAlt /> &nbsp; Edit shipping address
			</Button>
			<div className="p-2">
				<div className="mb-2">
					<InputLabel htmlFor="shipping-address-label">Shipping address:</InputLabel>
				</div>
				<div className="mb-2">
					<p>{`${order?.customer?.firstName} ${order?.customer?.lastName}`}</p>
				</div>
				<div className="mb-2">
					<p>{order?.shippingAddress?.street}</p>
				</div>
				<div className="mb-2">
					<p>{`${order?.shippingAddress?.zip} ${order?.shippingAddress?.city}`}</p>
				</div>
				<div className="mb-2">{order?.shippingAddress?.country?.name}</div>
				<div className="mb-2">
					<div>
						<InputLabel htmlFor="email-addressLabel">Email:</InputLabel>
					</div>
					<div>
						<p>{`${order?.customer?.email}`}</p>
					</div>
				</div>
				<div className="flex mb-2">
					<div className="mr-10">
						<div>
							<InputLabel htmlFor="phone-label">Payment method:</InputLabel>
							<p>{order?.paymentType?.name}</p>
						</div>
						<div></div>
					</div>
					<div>
						<div>
							<InputLabel htmlFor="vat-label">Payment reference:</InputLabel>
						</div>
						<div>
							<p>{order?.paymentReference ?? 'N/A'}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ShippingAddress;
