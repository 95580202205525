import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import { Button, TextField } from '@mui/material';
import { createSymbol, getSymbolById, updateSymbol, uploadFile } from '../../server/server';
import { baseUrl } from '../../server/connections';
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { checkAllowedExtensions } from '../../Constants';

function AddSymbolsModal({ modalIsOpen, closeModal, reloadSymbols, selectedSymbol, categories }: any) {
	const [symbol, setSymbol] = useState<any>(null);
	const [loading, setLoading] = useState(false);
	const [image, setImage] = useState<string | undefined>(undefined);
	const [selectedFile, setSelectedFile] = useState<any>(undefined);
	const [removedPhoto, setRemovedPhoto] = useState(false);
	const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);
	const [symbolName, setSymbolName] = useState('');
	const [error, setError] = useState<any>({});

	useEffect(() => {
		if (modalIsOpen) {
			ReactModal.setAppElement('#root');
			if (!!selectedSymbol?.id) {
				loadSymbolById(selectedSymbol?.id);
			}
		}
	}, [modalIsOpen]);

	const loadSymbolById = async (id: string) => {
		setLoading(true);
		const resp = await getSymbolById(id);
		if (resp.status === 200) {
			setSymbol(resp.data);
			setSymbolName(resp.data.name);
			const newCategories = resp.data.categories?.map((item: any) => item?.id);
			setRowSelectionModel(!!newCategories[0] ? [...newCategories] : []);
			console.log('new cdategor', newCategories);
			if (!!resp.data?.image) {
				fetchImg(resp.data.image);
			}
			setLoading(false);
		} else {
			setLoading(false);
		}
	};

	const fetchImg = async (imgUrl: string) => {
		await fetch(baseUrl + '/product/file/' + imgUrl)
			.then((res) => res.blob())
			.then((blob) => {
				const objectUrl = URL.createObjectURL(blob);
				setImage(objectUrl);
				return () => {
					console.log('remove img');
					URL.revokeObjectURL(objectUrl);
				};
			});
	};

	const checkIfIsValid = () => {
		let valid = true;
		if (!symbolName) {
			setError((e: any) => ({ ...e, name: 'Required filed ' }));
			valid = false;
		}

		return valid;
	};

	useEffect(() => {
		if (!selectedFile) {
			setImage(undefined);
			console.log('undefined img');
			return;
		}
		console.log('set img selected file je: ', selectedFile);
		const objectUrl = URL.createObjectURL(selectedFile);
		setImage(objectUrl);
		setRemovedPhoto(false);
		// return () => {
		// 	console.log("remove img trigerovan u create conteern modelu");
		// 	URL.revokeObjectURL(objectUrl);
		// };
	}, [selectedFile]);

	const press_upload = () => {
		document.getElementById('photo_upload_modal')?.click();
	};

	const closeImage = () => {
		setImage(undefined);
		setSelectedFile(undefined);
		setRemovedPhoto(true);
	};

	const onImageChange = (e: any) => {
		console.log(image);
		if (!e.target.files || e.target.files.length === 0) {
			setSelectedFile(undefined);
			return;
		}
		let file = e.target.files[0];
		if (file.size > 17728640) {
			alert('File is too big!');
			setSelectedFile(undefined);
			return;
		}
		if (!checkAllowedExtensions(file.name)) {
			alert('Type of file is not allowed!');
			return;
		}
		console.log('Selected file : ', file);
		setSelectedFile(file);
	};
	const resetInputValue = (e: any) => {
		e.target.value = null;
	};

	const onSubmit = async () => {
		if (!checkIfIsValid()) return;
		if (!!selectedFile) {
			const resp = await uploadFile({ file: selectedFile });
			if (resp.status == 201) {
				let data: any = {
					name: symbolName,
					fileId: resp.data.id,
					categories: rowSelectionModel,
				};
				if (!!symbol?.id) {
					data.id = symbol.id;
					editSymbol(data);
				} else {
					createNewSymbol(data);
				}
			} else {
				return;
			}
		} else if (!!image && !!symbol?.id) {
			let data = {
				id: symbol?.id,
				name: symbolName,
				fileId: symbol?.image,
				categories: rowSelectionModel,
			};
			editSymbol(data);
		}
	};
	const createNewSymbol = async (data: any) => {
		const respSymbol = await createSymbol(data);
		if (respSymbol.status === 201) {
			closeModalAndReloadSymbols();
			alert('Symbol is created!');
		}
	};
	const editSymbol = async (data: any) => {
		const respSymbol = await updateSymbol(data);
		if (respSymbol.status === 201) {
			closeModalAndReloadSymbols();
			alert('Symbol is updated!');
		}
	};

	const closeModalAndReloadSymbols = () => {
		reloadSymbols();
		closeModal();
	};

	const columnsCategory: GridColDef[] = [{ field: 'name', headerName: 'Category name', width: 300, flex: 1 }];

	const resetModalValues = () => {
		closeImage();
		setSymbol(null);
		setSymbolName('');
		setRowSelectionModel([]);
	};

	const handleNameChange = (event: any) => {
		setError({ ...error, name: !!event.target.value ? '' : 'Required filed' });
		setSymbolName(event.target.value);
	};

	return (
		<ReactModal
			ariaHideApp={false}
			isOpen={modalIsOpen}
			onAfterOpen={() => {}}
			onAfterClose={() => resetModalValues()}
			onRequestClose={closeModal}
			className="no-scroll"
			style={{
				overlay: {
					zIndex: 5,
					backdropFilter: 'brightness(30%)',
				},
				content: {
					padding: 0,
					opacity: 1,
					width: 1200,
					margin: 'auto',
					backgroundColor: 'transparent',
					height: '100%',
					maxHeight: '100%',
					border: 'none',
					boxShadow: 'none',
					overflow: 'auto',
					paddingTop: 15,
					paddingBottom: 15,
				},
			}}
		>
			<div className=" w-full rounded-[10px] bg-white  p-[20px]  ">
				<div className="flex w-full flex-col ">
					<p className="text-[24px] font-semibold leading-[32px] text-black text-center">{!!symbol?.id ? 'Edit symbol' : 'Add new symbol'}</p>
				</div>
				<div className="grid grid-cols-2 gap-5 mt-5">
					<div className=" flex flex-1 flex-col gap-5 ">
						<div className="">
							<TextField type="text" helperText={error?.name ?? ''} error={!!error?.name} value={symbolName} onChange={(e: any) => handleNameChange(e)} style={{ width: '100%' }} label="Symbol name" variant="filled" />
						</div>
						<div className="course-media-container" style={{ alignItems: 'center' }}>
							<div className="flex flex-col rounded-xl bg-containerGray py-2">
								{image && (
									<div>
										<p className=" text-base text-black">Preview:</p>
										<div className="relative flex max-h-[244px]  flex-col items-center justify-center rounded-xl bg-containerGray py-8">
											<div onClick={() => closeImage()} className="absolute top-0 right-0 cursor-pointer rounded-[20px] bg-white p-[10px] opacity-40">
												<FaTimes style={{ width: 20, height: 20, stroke: 'white', display: 'block' }} />
											</div>
											<img style={{ objectFit: 'contain', width: '100%', minHeight: 170, marginTop: 10 }} src={image} alt="" />
										</div>
									</div>
								)}
								<p className=" text-base text-black">Upload image file:</p>
								<div className="bg-white flex flex-row items-center gap-3 p-2 rounded-md mt-2 border border-gray">
									<Button
										variant="outlined"
										onClick={(e) => {
											e.preventDefault();
											press_upload();
										}}
										style={{ backgroundColor: '#eaeaea', color: '#777', border: '1px solid #777777' }}
									>
										Choose file
									</Button>

									<p>{!!selectedFile?.name ? selectedFile.name : !!image ? '' : 'No file chosen'}</p>
								</div>
								<p className="mt-3 text-base text-black">Supported image file extensions are: *.png, *.jpg, *.gif ,*.svg</p>
							</div>

							<input title="Add Video/Photo" style={{ display: 'none' }} accept=" image/*" type="file" id="photo_upload_modal" onChange={onImageChange} onClick={resetInputValue} />
						</div>
					</div>
					<div>
						<div className="rounded-lg border border-[#81848f3d] min-w-fit px-2 py-5">
							<div className=" h-fit w-full max-h-[560px] overflow-auto px-2 gray-small-scroll">
								<DataGrid
									autoHeight
									checkboxSelection
									disableRowSelectionOnClick
									rows={categories}
									columns={[...columnsCategory]}
									disableColumnFilter
									disableColumnMenu
									initialState={{
										pagination: {
											paginationModel: { pageSize: 8, page: 0 },
										},
									}}
									pageSizeOptions={[8, 16, 24]}
									rowSelectionModel={rowSelectionModel}
									onRowSelectionModelChange={(newRowSelectionModel) => {
										setRowSelectionModel(newRowSelectionModel);
										console.log('selected rows ', newRowSelectionModel);
									}}
									showCellVerticalBorder={false}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="flex w-full flex-row justify-end gap-8 mt-6  ">
					<Button
						variant="contained"
						onClick={(e) => {
							e.preventDefault();
							closeModal();
						}}
						style={{ backgroundColor: '#2ec4b6', color: 'white' }}
					>
						Cancel
					</Button>
					<Button variant="contained" onClick={() => onSubmit()} style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
						Submit
					</Button>
				</div>
			</div>
		</ReactModal>
	);
}

export default AddSymbolsModal;
