import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { FaEdit, FaTimes } from 'react-icons/fa';
import { Button, Tab, Tabs, TextField } from '@mui/material';
import { createGroup, createOption, getAllProducts, getImage, insertProductToMap } from '../../../server/server';
import { CreateGroupDto } from '../../../model/group';
import { CreateOptionDto } from '../../../model/option';
import useDebounce from '../../../hooks/useDebounce';
import { DataGrid, GridActionsCellItem, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';

function ConnectProductToOptionMapModal({ modalIsOpen, closeModal, selectedMap, selectedOption, reloadMaps }: any) {
	const [name, setName] = useState('');
	const [color, setColor] = useState('');
	const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);
	const [products, setProducts] = useState([]);
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 5,
	});
	const [rowCountState, setRowCountState] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const [searchQuery, setSearchQuery] = useState('');
	const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');
	const [filter, setFilter] = useState('all');
	const [counts, setCounts] = useState({
		published: 0,
		deleted: 0,
		allItems: 0,
	});
	const searchDelay = 700;
	const debouncedQuery = useDebounce(searchQuery, searchDelay);

	useEffect(() => {
		if (modalIsOpen) {
			loadProducts(paginationModel.page, paginationModel.pageSize, debouncedQuery, 'notFeatured');
		}
	}, [paginationModel.page, paginationModel.pageSize, debouncedQuery]);

	useEffect(() => {
		if (modalIsOpen) {
			ReactModal.setAppElement('#root');
			loadProducts(paginationModel.page, paginationModel.pageSize, debouncedQuery, 'notFeatured');
		}
	}, [modalIsOpen]);

	const onSubmit = async () => {
		const data: any = {
			productId: rowSelectionModel[0],
			optionTwoId: selectedOption?.id,
			optionOneId: selectedMap?.id,
		};
		if (!!selectedMap?.map) {
			data.id = selectedMap.map.id;
		}
		addProductToMap(data);
	};

	const addProductToMap = async (data: any) => {
		const resp = await insertProductToMap(data);
		if (resp.status === 201) {
			console.log('optiob ', resp.data);
			closeModalAndReloadOptions();
			alert('You have successfully added the product to the selected folder');
		}
	};

	const loadProducts = async (page: any, pageSize: any, searchQuery?: any, filter?: any) => {
		console.log('loaded count');
		setIsLoading(true);
		const data = await getAllProducts(pageSize, page * pageSize, searchQuery, filter);
		if (data.status === 200) {
			setProducts(data.data.products);
			setRowCountState(data.data.total);
			setCounts(data.data.counts);
			setIsLoading(false);
		} else {
		}
	};

	const handleSearchQueryChange = (e: any) => {
		setSearchQuery(e.target.value);
	};

	const closeModalAndReloadOptions = () => {
		reloadMaps(selectedOption.id);
		closeModal();
	};

	const reset = () => {};

	const columns: GridColDef[] = [
		{ field: 'file', headerName: 'Image', width: 80, renderCell: (params) => <img src={getImage(params.value)} alt="product" width="50" /> },
		{ field: 'name', headerName: 'Name', width: 200, flex: 1 },
		{ field: 'sku', headerName: 'SKU', width: 150 },
		{ field: 'price', headerName: 'Price', width: 150 },
		{ field: 'category', headerName: 'Category', width: 100 },
	];

	return (
		<ReactModal
			ariaHideApp={false}
			//closeTimeoutMS={600}
			isOpen={modalIsOpen}
			onAfterOpen={() => {}}
			onAfterClose={() => reset()}
			onRequestClose={closeModal}
			className="no-scroll"
			style={{
				overlay: {
					zIndex: 5,
					backdropFilter: 'brightness(30%)',
				},
				content: {
					padding: 0,
					opacity: 1,
					width: 900,
					margin: 'auto',
					backgroundColor: 'transparent',
					height: '100%',
					maxHeight: '100%',
					border: 'none',
					boxShadow: 'none',
					overflow: 'auto',
					paddingTop: 15,
					paddingBottom: 15,
				},
			}}
		>
			<div className="   max-h-[600px] w-full  ">
				<div className=" flex flex-1 flex-col gap-5 rounded-[10px] bg-white  px-[40px] py-[30px] ">
					<p className=" text-center text-xl ">Connect product with option map</p>
					<div className="rounded-lg border border-[#81848f3d] min-w-fit p-8 pb-4 mt-2">
						<div className="flex justify-between items-center">
							<TextField label="Search" variant="outlined" value={searchQuery} onChange={handleSearchQueryChange} style={{ marginRight: '5px' }} />
						</div>
						<div className="mt-5 h-fit w-full">
							<DataGrid
								autoHeight
								rows={products}
								columns={columns}
								rowCount={rowCountState}
								loading={isLoading}
								pageSizeOptions={[5, 10, 20]}
								paginationModel={paginationModel}
								paginationMode="server"
								rowSelectionModel={rowSelectionModel}
								onRowSelectionModelChange={(newRowSelectionModel) => {
									setRowSelectionModel(newRowSelectionModel);
									console.log('selected rows ', newRowSelectionModel);
								}}
								onPaginationModelChange={(newModel) => {
									if (newModel.pageSize !== paginationModel.pageSize) {
										setPaginationModel({
											page: 0,
											pageSize: newModel.pageSize,
										});
										return;
									} else {
										setPaginationModel(newModel);
									}
								}}
							/>
						</div>
					</div>
					<div className="flex w-full flex-row justify-end gap-8 mt-5  ">
						<Button variant="contained" onClick={() => closeModal()} style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
							Cancel
						</Button>
						<Button variant="contained" onClick={() => onSubmit()} style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
							Submit
						</Button>
					</div>
				</div>
			</div>
		</ReactModal>
	);
}

export default ConnectProductToOptionMapModal;
