import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { Button, TextField } from '@mui/material';
import { createSymbolCategory } from '../../server/server';

function AddCategoryModal({ modalIsOpen, closeModal, category, reloadCategories }: any) {
	const [name, setName] = useState('');
	const [error, setError] = useState<any>({});

	useEffect(() => {
		if (modalIsOpen) {
			ReactModal.setAppElement('#root');
		}
	}, [modalIsOpen]);

	useEffect(() => {
		if (!!category?.id) {
			setName(category.name);
		}
	}, [category]);

	const onSubmit = async () => {
		if (!checkIfIsValid()) return;
		const data: any = {
			name: name,
		};
		if (!category?.id) {
			createNewCategory(data);
			alert('Category is created!');
		} else {
			data.id = category.id;
			createNewCategory(data);
			alert('Category is updated!');
		}
	};

	const createNewCategory = async (data: any) => {
		const resp = await createSymbolCategory(data);
		if (resp.status === 201) {
			console.log('group ', resp.data);
			closeModalAndReloadOptions();
		}
	};

	const closeModalAndReloadOptions = () => {
		reloadCategories();
		closeModal();
	};

	const reset = () => {
		setName('');
	};

	const checkIfIsValid = () => {
		let valid = true;
		if (!name) {
			setError((e: any) => ({ ...e, name: 'Required filed ' }));
			valid = false;
		}

		return valid;
	};

	const handleNameChange = (event: any) => {
		setError({ ...error, name: !!event.target.value ? '' : 'Required filed' });
		setName(event.target.value);
	};
	return (
		<ReactModal
			ariaHideApp={false}
			//closeTimeoutMS={600}
			isOpen={modalIsOpen}
			onAfterOpen={() => {}}
			onAfterClose={() => reset()}
			onRequestClose={closeModal}
			className="no-scroll"
			style={{
				overlay: {
					zIndex: 5,
					backdropFilter: 'brightness(30%)',
				},
				content: {
					padding: 0,
					opacity: 1,
					width: 600,
					margin: 'auto',
					backgroundColor: 'transparent',
					height: '100%',
					maxHeight: '100%',
					border: 'none',
					boxShadow: 'none',
					overflow: 'auto',
					paddingTop: 15,
					paddingBottom: 15,
				},
			}}
		>
			<div className="   max-h-[600px] w-full  ">
				<div className=" flex flex-1 flex-col gap-5 rounded-[10px] bg-white  px-[40px] py-[30px] ">
					<div className="flex w-full flex-col ">
						<p className="text-[30px] font-semibold leading-[38px] text-black textce">{!!category ? 'Edit symbol category' : 'Add new category'}</p>
					</div>
					<div className="col-span-2 ">
						<TextField required className=" background-transparent " value={name} onChange={(e) => handleNameChange(e)} helperText={error?.name ?? ''} error={!!error?.name} style={{ width: '100%' }} label="Group name" variant="filled" />
					</div>
					<div className="flex w-full flex-row justify-end gap-8 mt-10  ">
						<Button variant="contained" onClick={() => closeModal()} style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
							Cancel
						</Button>
						<Button variant="contained" onClick={() => onSubmit()} style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
							Submit
						</Button>
					</div>
				</div>
			</div>
		</ReactModal>
	);
}

export default AddCategoryModal;
