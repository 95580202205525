import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { editPaymentTypeOrder, getPaymentTypes, disablePaymentType, deletePaymentType } from '../../server/server';
import { Button, Switch } from '@mui/material';
import { FaAngleDown, FaAngleUp, FaBars, FaEdit, FaTrash } from 'react-icons/fa';
import AddPaymentTypeModal from './AddPaymentTypeModal';

interface PaymentType {
	id: string;
	name: string;
	description: string;
	priority: number;
	disabled: boolean;
}

const PaymentType = () => {
	const [paymentTypes, setPaymentTypes] = useState<PaymentType[]>([]);
	const [selectedPaymentTypeId, setSelectedPaymentTypeId] = useState<string | null>(null);
	const [paymentModalVisible, setPaymentModalVisible] = useState(false);

	useEffect(() => {
		fetchPaymentTypes();
	}, []);

	const fetchPaymentTypes = async () => {
		const paymentResp = await getPaymentTypes();

		if (paymentResp.status == 200) {
			let sordtedPaymentTypes = paymentResp.data.sort((a: PaymentType, b: PaymentType) => a.priority - b.priority);

			setPaymentTypes(sordtedPaymentTypes);
		} else {
			alert('Error fetching payment types');
		}
	};

	const handleSwitchChange = async (item: PaymentType) => {
		const updatedPaymentTypes = paymentTypes.map((paymentType) => (paymentType.id === item.id ? { ...paymentType, disabled: !paymentType.disabled } : paymentType));
		setPaymentTypes(updatedPaymentTypes);

		const disablePaymentTypeDto = {
			id: item.id,
			disabled: !item.disabled,
		};

		const resp = await disablePaymentType(disablePaymentTypeDto);

		if (resp.status !== 200) {
			setPaymentTypes(paymentTypes);
		}
	};

	const handleDrop = async (droppedItem: any) => {
		// Ignore drop outside droppable container
		if (!droppedItem.destination) return;
		var updatedList = [...paymentTypes];
		// Remove dragged item
		const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
		// Add dropped item
		updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
		let newReorderList = updatedList.map((item, index) => {
			return { ...item, priority: index + 1 };
		});
		// Update State
		setPaymentTypes(newReorderList);
		const resp = await editPaymentTypeOrder({ paymentTypes: newReorderList });
		if (resp.status !== 200) {
			alert(`Error changing payment type order: ${resp.status}`);
		}
	};

	const handleUp = async (index: number) => {
		if (index === 0) return;
		const updatedList = [...paymentTypes];
		const [item] = updatedList.splice(index, 1);
		updatedList.splice(index - 1, 0, item);
		let newReorderList = updatedList.map((item, index) => {
			return { ...item, priority: index + 1 };
		});
		setPaymentTypes(newReorderList);
		await editPaymentTypeOrder({ paymentTypes: newReorderList });
	};

	const handleDown = async (index: number) => {
		if (index === paymentTypes.length - 1) return;
		const updatedList = [...paymentTypes];
		const [item] = updatedList.splice(index, 1);
		updatedList.splice(index + 1, 0, item);
		let newReorderList = updatedList.map((item, index) => {
			return { ...item, priority: index + 1 };
		});
		setPaymentTypes(newReorderList);
		await editPaymentTypeOrder({ paymentTypes: newReorderList });
	};

	const handleAddPaymentTypeClick = () => {
		setPaymentModalVisible(true);
	};

	const handleModalClose = () => {
		setSelectedPaymentTypeId(null);
		setPaymentModalVisible(false);
		fetchPaymentTypes();
	};

	const editPaymentType = (id: any) => {
		setSelectedPaymentTypeId(id);
		setPaymentModalVisible(true);
	};
	const deleteSelectedPaymentType = async (id: any) => {
		const isConfirmed = window.confirm('Are you sure you want to delete this payment option?');
		if (isConfirmed) {
			const resp = await deletePaymentType(id);

			if (resp.status == 200) {
				fetchPaymentTypes();
				alert(`Payment option deleted successfully!`);
			} else {
				alert(`Error deleting payment option: ${resp.status}`);
			}
		} else {
			return;
		}
	};

	return (
		<div className="py-10 px-2 h-full w-full">
			<div className="gap-6 block">
				<div className="flex">
					<div>
						<h1 className="text-black text-4xl font-bold">Payment options</h1>
					</div>
					<div className="mt-1 ml-4">
						<Button variant="contained" color="primary" onClick={handleAddPaymentTypeClick} style={{ backgroundColor: '#21187F', color: 'white' }}>
							Add New Payment Type
						</Button>
					</div>
				</div>
				<div>
					<p className="text-darkGrey text-base mt-2">Installed payment methods are listed below and can be sorted to control their display order on the frontend.</p>
				</div>
			</div>
			<div className=" mt-3 max-h-[330px] overflow-auto gray-small-scroll pb-[15px] border p-1 border-gray rounded">
				{paymentTypes.length ? (
					<div className="grid grid-cols-5 p-2 gap-1 border-b-2 border-gray" style={{ gridTemplateColumns: '10% 25% 15% auto 10%' }}>
						<p className="text-darkGrey text-lg ">Order</p>
						<p className="text-darkGrey text-lg ml-2">Method</p>
						<p className="text-darkGrey text-lg">Enabled</p>
						<p className="text-darkGrey text-lg">Description</p>
						<p className="text-darkGrey text-lg">Actions</p>
					</div>
				) : (
					<p className="text-red-500">No payment types found.</p>
				)}

				{paymentTypes.length > 0 ? (
					<DragDropContext onDragEnd={handleDrop}>
						<Droppable droppableId="list-container">
							{(provided) => (
								<div {...provided.droppableProps} ref={provided.innerRef}>
									{paymentTypes?.map((item: any, index: number) => {
										const isEven = index % 2 === 0;
										return (
											<Draggable key={item.id} draggableId={item.id} index={index}>
												{(provided) => (
													<div ref={provided.innerRef} {...provided.draggableProps}>
														<div className={`grid grid-cols-4 gap-1 transition-colors  mt-2 ${isEven ? 'bg-slate' : 'bg-white'}`} {...provided.dragHandleProps} style={{ gridTemplateColumns: '10% 25% 15% auto 10%' }}>
															<div className="flex flex-row items-center justify-between col-span-1 p-[6px]">
																<FaBars />
																<FaAngleUp className={index === 0 ? 'text-disabled cursor-not-allowed' : 'text-black cursor-pointer'} onClick={index !== 0 ? () => handleUp(index) : undefined} />
																<FaAngleDown className={index === paymentTypes.length - 1 ? 'text-disabled cursor-not-allowed' : 'text-black cursor-pointer'} onClick={index !== paymentTypes.length - 1 ? () => handleDown(index) : undefined} />
															</div>
															<div className="flex flex-row items-center justify-between col-span-1 p-[6px]">
																<p className="text-darkGrey text-lg ">{item.name}</p>
															</div>
															<div className="flex flex-row items-center justify-between col-span-1 p-[6px]">
																<Switch checked={!item.disabled} color="primary" onChange={() => handleSwitchChange(item)} />
															</div>
															<div className="flex flex-row items-center justify-between col-span-1 p-[6px]">
																<p className="text-darkGrey text-lg">{item.description}</p>
															</div>
															<div className="flex flex-row items-center col-span-1 p-[6px]">
																<FaEdit style={{ width: 20, height: 20, marginRight: '10px', cursor: 'pointer' }} onClick={() => editPaymentType(item.id)} />
																<FaTrash style={{ width: 20, height: 20, color: '#D24D57', cursor: 'pointer' }} onClick={() => deleteSelectedPaymentType(item.id)} />
															</div>
														</div>
													</div>
												)}
											</Draggable>
										);
									})}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				) : null}
			</div>
			<AddPaymentTypeModal open={paymentModalVisible} onClose={handleModalClose} isEdit={selectedPaymentTypeId !== null} paymentTypeId={selectedPaymentTypeId} />
		</div>
	);
};

export default PaymentType;
