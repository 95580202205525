export enum CustomAllow {
	YES = 'Yes',
	NO = 'No',
	'DIGITAL PRODUCT' = 'Digital product',
}

export enum ProductType {
	WOOD = 'Wood',
	LEATHER = 'Leather',
	GLASS = 'Glass',
	METAL = 'Metal',
}

export enum InStock {
	'IN STOCK',
	'OUT OF STOCK',
}

export enum ShippingOptionType {
	'FLAT RATE' = 0,
	'FREE SHIPPING' = 1,
	'LOCAL PICKUP' = 2,
}
