import React, { useEffect, useState } from 'react';
import { TextField, Select, MenuItem, FormControl, InputLabel, Grid, Typography, Card, CardContent, CardActions, Button } from '@mui/material';
import EditAddressModal, { City, Country, Municipality, Region } from './EditAddressModal';
import { changeDefaultBillingAddress, changeDefaultShippingAddress, editAddress, editCustomer } from '../../../server/server';

interface ManageCustomerProps {
	customer: any;
	onClose: () => void;
	isOpen: boolean;
	onSetDefaultAddress: (addressType: 'billing' | 'shipping', addressId: string) => void;
}
export interface Address {
	id: string;
	city: string;
	country: Country;
	municipality: Municipality;
	region: Region;
	street: string;
	vatNumber: string;
	zip: string;
	phone: string;
	number: string;
	cityId?: string;
}

const ManageCustomer: React.FC<ManageCustomerProps> = ({ customer, onClose, onSetDefaultAddress }) => {
	const [customerToEdit, setCustomerToEdit] = useState(customer || undefined);
	const [selectedBillingAddressIndex, setSelectedBillingAddressIndex] = useState(0);
	const [selectedShippingAddressIndex, setSelectedShippingAddressIndex] = useState(0);

	const [isEditBillingAddressModalOpen, setIsEditBillingAddressModalOpen] = useState(false);
	const [isEditShippingAddressModalOpen, setIsEditShippingAddressModalOpen] = useState(false);
	const [editedAddress, setEditedAddress] = useState<Address>();

	const [firstNameValid, setFirstNameValid] = useState(true);
	const [lastNameValid, setLastNameValid] = useState(true);

	let billingAddressLength = customer.billingAddresses?.length;
	let shippingAddressLength = customer.shippingAddresses?.length;

	const shippingAddresses = customer.shippingAddresses || [];
	const billingAddresses = customer.billingAddresses || [];

	useEffect(() => {
		setCustomerToEdit(customer);
	}, [customer]);

	const handleShippingAddressClick = (index: number) => {
		setSelectedShippingAddressIndex(index);
	};

	const handleBillingAddressClick = (index: number) => {
		setSelectedBillingAddressIndex(index);
	};

	const handleInputChange = (fieldId: any, value: any) => {
		setCustomerToEdit({ ...customerToEdit, [fieldId]: value });
		validateField(fieldId, value);
	};

	const validateField = (field: any, value: any) => {
		switch (field) {
			case 'firstName':
				setFirstNameValid(value.trim() !== '');
				break;
			case 'lastName':
				setLastNameValid(value.trim() !== '');
				break;
			default:
				break;
		}
	};

	const handleSaveChanges = async () => {
		const isFormValid = firstNameValid && lastNameValid;

		if (isFormValid) {
			const editCustomerDto = {
				zoneId: customerToEdit.zoneId || undefined,
				userId: customerToEdit.user?.id || undefined,
				firstName: customerToEdit.firstName || undefined,
				lastName: customerToEdit.lastName || undefined,
				company: customerToEdit.company || undefined,
			};

			const editCustomerResponse = await editCustomer(customerToEdit.id, editCustomerDto);

			if (editCustomerResponse.status != 200) {
				alert(`Error editing customer:${editCustomerResponse.status}`);
			}
		} else {
			alert('Please fill in all required fields marked with *');
		}
	};

	const handleEditBillingAddress = (address: any) => {
		setEditedAddress(address);
		setIsEditBillingAddressModalOpen(true);
	};

	const handleEditShippingAddress = (address: any) => {
		setEditedAddress(address);
		setIsEditShippingAddressModalOpen(true);
	};

	const handleEditAddressModalClose = (updatedAddress: any) => {
		if (editedAddress) {



		}

	};

	const handleBModalSave = (updatedAddress: any) => {
		const addressIndex = customer?.billingAddresses?.findIndex((address: any) => address.id === editedAddress?.id);

		if (addressIndex !== -1) {
			customer.billingAddresses[addressIndex] = updatedAddress;
		}
		setIsEditBillingAddressModalOpen(false);
	}
	const handleSModalSave = (updatedAddress: any) => {
		const addressIndex = customer?.shippingAddresses?.findIndex((address: any) => address.id === editedAddress?.id);

		if (addressIndex !== -1) {
			customer.shippingAddresses[addressIndex] = updatedAddress;
		}
		console.log('updated;', updatedAddress);
		setIsEditShippingAddressModalOpen(false);
	}

	const handleSetAsDefault = async (addressType: 'billing' | 'shipping', addressId: string) => {
		if (addressId) {
			const addresses = addressType === 'billing' ? billingAddresses : shippingAddresses;

			const selectedAddress = addresses.find((address: any) => address.id === addressId);
			if (!selectedAddress) {
				alert('No default address found. Please add or edit an address before setting it as default.');
				return;
			}

			// Set all addresses to non-default
			addresses.forEach((address: any) => {
				if (address.id === addressId) {
					address.isDefault = true; // Set the selected address as default
				} else {
					address.isDefault = false; // Set other addresses as non-default
				}
			});

			// Update the state with the modified addresses
			if (addressType === 'billing') {
				customer.billingAddresses = [...addresses];
			} else {
				customer.shippingAddresses = [...addresses];
			}

			// Save the changes to the backend
			if (addressType === 'shipping') {
				await changeDefaultShippingAddress(customer.id, addressId);
			} else if (addressType === 'billing') {
				await changeDefaultBillingAddress(customer.id, addressId);
			}

			// Trigger the parent component's callback to reflect changes
			onSetDefaultAddress(addressType, addressId);
		}
	};

	return (
		<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 overflow-y-auto gray-small-scroll">
			<div className="w-full sm:w-3/4 md:w-1/2 lg:w-2/5 xl:w-1/3 p-4 bg-white rounded-md">
				<div className="m-5 grid grid-cols-1 md:grid-cols-2 gap-4">
					<div className="m-2">
						<Grid container direction="column" spacing={2}>
							<Grid item>
								<Typography variant="h4">Manage Customer:</Typography>
							</Grid>
							<Grid item>
								<TextField label="First name *" variant="standard" fullWidth value={customerToEdit.firstName} onChange={(e) => handleInputChange('firstName', e.target.value)} error={!firstNameValid} helperText={!firstNameValid && 'First name is required'} />
							</Grid>

							<Grid item>
								<TextField label="Last Name *" variant="standard" fullWidth value={customerToEdit.lastName} onChange={(e) => handleInputChange('lastName', e.target.value)} error={!lastNameValid} helperText={!lastNameValid && 'Last name is required'} />
							</Grid>
							<Grid item>
								<TextField label="Company" variant="standard" fullWidth value={customerToEdit.company} onChange={(e) => handleInputChange('company', e.target.value)} />
							</Grid>
							<Grid item>
								<TextField disabled label="Email" variant="standard" fullWidth value={customerToEdit?.email} onChange={(e) => handleInputChange('email', e.target.value)} />
							</Grid>

							<div className="grid grid-cols-2 justify-end mt-5">
								<Button variant="contained" onClick={handleSaveChanges}>
									Save changes
								</Button>
								<Button onClick={onClose}>Close</Button>
							</div>
						</Grid>
					</div>
					<div className="grid grid-rows-2 gap-4 ">
						<div>
							<div className="ml-2">
								<p>Billing addresses: {billingAddressLength}</p>
								{billingAddresses.map((address: any, index: any) => (
									<Button style={{ marginRight: '2px' }} key={index} onClick={() => handleBillingAddressClick(index)} variant={selectedBillingAddressIndex === index ? 'contained' : 'outlined'}>
										{index + 1}
									</Button>
								))}
							</div>
							<div>
								<Card className="m-2">
									<CardContent>
										<Typography>Address: {billingAddresses[selectedBillingAddressIndex]?.street}</Typography>
										<Typography>Post code: {billingAddresses[selectedBillingAddressIndex]?.zip}</Typography>
										<Typography>City: {billingAddresses[selectedBillingAddressIndex]?.city}</Typography>
										{/* <Typography>Municipality: {billingAddresses[selectedBillingAddressIndex]?.municipality?.name}</Typography>
										<Typography>Region: {billingAddresses[selectedBillingAddressIndex]?.region?.name}</Typography> */}
										<Typography>Country: {billingAddresses[selectedBillingAddressIndex]?.country?.name}</Typography>
									</CardContent>
									<CardActions>
										<Button size="small" onClick={() => handleEditBillingAddress(billingAddresses[selectedBillingAddressIndex])}>
											Edit
										</Button>
										{/* <Button size="small">Delete</Button> */}
										<Button onClick={() => handleSetAsDefault('billing', billingAddresses[selectedBillingAddressIndex]?.id)} disabled={!billingAddresses[selectedBillingAddressIndex] || billingAddresses[selectedBillingAddressIndex]?.isDefault === true} size="small">
											{billingAddresses[selectedBillingAddressIndex]?.isDefault === true ? 'Default' : 'Set as default'}
										</Button>
									</CardActions>
								</Card>
							</div>
						</div>
						<div>
							<div>
								<div className="ml-2">
									<p>Shipping addresses: {shippingAddressLength}</p>
									{shippingAddresses.map((address: any, index: any) => (
										<Button style={{ marginRight: '2px' }} key={index} onClick={() => handleShippingAddressClick(index)} variant={selectedShippingAddressIndex === index ? 'contained' : 'outlined'}>
											{index + 1}
										</Button>
									))}
								</div>
								<div>
									<Card className="m-2">
										<CardContent>
											<Typography>Address: {shippingAddresses[selectedShippingAddressIndex]?.street}</Typography>
											<Typography>Post code: {shippingAddresses[selectedShippingAddressIndex]?.zip}</Typography>
											<Typography>City: {shippingAddresses[selectedShippingAddressIndex]?.city}</Typography>
											{/* <Typography>Municipality: {shippingAddresses[selectedShippingAddressIndex]?.municipality?.name}</Typography>
											<Typography>Region: {shippingAddresses[selectedShippingAddressIndex]?.region?.name}</Typography> */}
											<Typography>Country: {shippingAddresses[selectedShippingAddressIndex]?.country?.name}</Typography>
										</CardContent>
										<CardActions>
											<Button size="small" onClick={() => handleEditShippingAddress(shippingAddresses[selectedShippingAddressIndex])}>
												Edit
											</Button>
											{/* <Button size="small">Delete</Button> */}
											<Button onClick={() => handleSetAsDefault('shipping', shippingAddresses[selectedShippingAddressIndex]?.id)} disabled={!shippingAddresses[selectedShippingAddressIndex] || shippingAddresses[selectedShippingAddressIndex]?.isDefault === true} size="small">
												{shippingAddresses[selectedShippingAddressIndex]?.isDefault === true ? 'Default' : 'Set as default'}
											</Button>
										</CardActions>
									</Card>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<EditAddressModal isOpen={isEditBillingAddressModalOpen} onSave={handleBModalSave} onClose={() => setIsEditBillingAddressModalOpen(false)} address={editedAddress} addressType="billing" />
			<EditAddressModal isOpen={isEditShippingAddressModalOpen} onSave={handleSModalSave} onClose={() => setIsEditShippingAddressModalOpen(false)} address={editedAddress} addressType="shipping" />
		</div>
	);
};

export default ManageCustomer;
