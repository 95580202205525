import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ReactComponent as DeleteIcon } from '../../../assets/svg/DeleteIcon.svg';
import { DragDropContext } from 'react-beautiful-dnd';
import { Droppable } from 'react-beautiful-dnd';
import { Draggable } from 'react-beautiful-dnd';
import AddProductsImageModal from './AddProductsImageModal';
import { getImage, uploadFile } from '../../../server/server';
import AddFileFromLibraryModal from '../../media/AddFileFromLibraryModal';
import SingleImageModal from '../../media/SingleImageModal';

function NewProductImagesForm({ imagesList, setImagesList, product, setProduct }: { imagesList: any; setImagesList: any; product: any; setProduct: any }) {
	const [addImageModalOpen, setAddImageModalOpen] = useState(false);
	const [libraryModalOpen, setLibraryModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [selectedPicture, setSelectedPicture] = useState<any>(null);

	const closeModal = () => {
		setSelectedPicture(null);
		setModalIsOpen(false);
	};

	const openSingleImageModal = (item: any) => {
		setSelectedPicture(item);
		setModalIsOpen(true);
	};

	const setSelectedFileToList = async (file: any) => {
		if (!!file) {
			setLoading(true);
			const resp = await uploadFile({ file: file, selectable: 'true' });
			if (resp.status == 201) {
				const uploadedImage = resp.data;
				setImagesList([...imagesList, { id: uploadedImage.id, order: imagesList.length + 1 }]);
				setProduct({ ...product, files: [...product.files, { id: uploadedImage.id, order: imagesList.length + 1 }] });
				setLoading(false);
				closeAddImageModal();
			} else {
				setLoading(false);
				return;
			}
		}
	};

	const removeImageFromList = (index: number) => {
		if (window.confirm('Do you want to remove image from list?')) {
			let newList = [...imagesList];
			newList.splice(index, 1);
			let newReorderList = newList.map((item, index) => {
				return { ...item, order: index + 1 };
			});
			setImagesList(newReorderList);
			setProduct({ ...product, files: newReorderList });
		}
	};

	const closeAddImageModal = () => {
		setAddImageModalOpen(false);
	};

	const closeLibraryModal = () => {
		setLibraryModalOpen(false);
	};

	const handleDrop = (droppedItem: any) => {
		// Ignore drop outside droppable container
		if (!droppedItem.destination) return;
		var updatedList = [...imagesList];
		// Remove dragged item
		const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
		// Add dropped item
		updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
		let newReorderList = updatedList.map((item, index) => {
			return { ...item, order: index + 1 };
		});
		// Update State
		setImagesList(newReorderList);
		setProduct({ ...product, files: newReorderList });
	};

	const handleSelectedFile = (id: any) => {
		setImagesList([...imagesList, { id: id, order: imagesList.length + 1 }]);
		setProduct({ ...product, files: [...product.files, { id: id, order: imagesList.length + 1 }] });
		closeLibraryModal();
	};

	return (
		<div className="flex  w-full flex-col mt-6 border border-mediumGrey rounded-md p-[30px] gap-5 ">
			<div className=" w-full flex flex-row gap-5 ">
				<div className="flex  h-full  flex-col  w-full ">
					<div className="flex w-full flex-row items-center gap-5">
						<p className="text-darkGrey text-xl px-2 flex-1">List of product images </p>
						<Button variant="contained" onClick={() => setLibraryModalOpen(true)} style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
							Add image from library
						</Button>
						<Button variant="contained" onClick={() => setAddImageModalOpen(true)} style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
							Add new image
						</Button>
					</div>
					<div className=" mt-3 max-h-[330px] overflow-auto gray-small-scroll pb-[15px] pr-2">
						{!!imagesList.length && (
							<div className="grid grid-cols-12 gap-1 p-[6px]">
								<p className="text-darkGrey text-lg">Position</p>
								<p className="col-span-11 text-darkGrey text-lg">Image</p>
							</div>
						)}
						<DragDropContext onDragEnd={handleDrop}>
							<Droppable droppableId="list-container">
								{(provided) => (
									<div {...provided.droppableProps} ref={provided.innerRef}>
										{imagesList?.map((item: any, index: number) => {
											return (
												<Draggable key={index.toString()} draggableId={index.toString()} index={index}>
													{(provided) => (
														<div className="grid grid-cols-12 gap-1 bg-white transition-colors hover:bg-babyBlue  border border-mediumGrey mt-1 p-[6px]" ref={provided.innerRef} {...provided.draggableProps}>
															<div className="flex flex-row items-center pl-4" {...provided.dragHandleProps}>
																{index + 1}
															</div>
															<div className="flex flex-row items-center justify-between col-span-11 ">
																{item?.mimeType?.startsWith('video') ? (
																	<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 60, width: 60 }}>
																		<video style={{ pointerEvents: 'none', width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center' }} muted>
																			<source src={getImage(item.id)} type="video/mp4" />
																			Your browser does not support the video tag.
																		</video>
																	</div>
																) : (
																	<img src={getImage(item.id)} alt="product" style={{ minHeight: 60, maxHeight: 60, aspectRatio: '1/1', objectFit: 'cover', objectPosition: 'top', cursor: 'pointer' }} onClick={() => openSingleImageModal(item)} />
																)}
																<div className="cursor-pointer mr-5" onClick={() => removeImageFromList(index)}>
																	<DeleteIcon />
																</div>
															</div>
														</div>
													)}
												</Draggable>
											);
										})}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</DragDropContext>
					</div>
				</div>
			</div>
			<AddProductsImageModal modalIsOpen={addImageModalOpen} closeModal={closeAddImageModal} setSelectedFileToList={setSelectedFileToList} loading={loading} />
			<AddFileFromLibraryModal modalIsOpen={libraryModalOpen} closeModal={closeLibraryModal} returnSelectedFile={handleSelectedFile} />
			<SingleImageModal modalIsOpen={modalIsOpen} closeModal={closeModal} selectedPicture={selectedPicture} deleteFlag={false} />
		</div>
	);
}

export default NewProductImagesForm;
