import React, { useEffect, useState } from 'react';
import TabsHeader from './TabsHeader';
import { DataGrid, GridRowId, GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { getImage } from '../../server/server';
import AddFontsModal from './AddFontsModal';
import { Button } from '@mui/material';
import Fonts from './Fonts';
import Symbols from './Symbols';
import AddSymbolsModal from './AddSymbolsModal';
import ProductDesigns from '../productDesigns/ProductDesigns';
import { useSearchParams } from 'react-router-dom';

function LayoutHome() {
	const [searchParams, setSearchParams] = useSearchParams();
	const defaultTab = searchParams.get('tab') ?? 'fonts';
	const [selectedTab, setSelectedTab] = useState(defaultTab);
	const [addNewFontsModalOpen, setAddNewFontsModalOpen] = useState(false);

	const closeAddNewFontsModal = () => {
		setAddNewFontsModalOpen(false);
	};

	useEffect(() => {
		handleTabs(selectedTab);
	}, [selectedTab]);

	useEffect(() => {
		setSelectedTab(defaultTab);
	}, [defaultTab]);

	const handleTabs = (selectedTab: string) => {
		setSearchParams(`tab=${selectedTab}`);
	};

	return (
		<div className="py-6 px-2 h-full w-full">
			<TabsHeader selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
			{selectedTab === 'symbols' && <Symbols />}
			{selectedTab === 'fonts' && <Fonts />}
			{selectedTab === 'design' && <ProductDesigns />}
		</div>
	);
}

export default LayoutHome;
