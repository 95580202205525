import { Button } from '@mui/material';
import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { uploadFiles } from '../../server/server';
import { checkAllowedExtensionsForList } from '../../Constants';
import Loading from '../../common/Loading';

function AddNewMedia({ selectedFiles, setSelectedFiles, setAddNewMedia, resetMediaList }: { selectedFiles: any; setSelectedFiles: any; setAddNewMedia: any; resetMediaList: any }) {
	const [loading, setLoading] = useState(false);

	const onSubmit = async () => {
		if (!!selectedFiles) {
			setLoading(true);
			const resp = await uploadFiles({ filesArray: selectedFiles, selectable: 'true' });
			if (resp.status == 201) {
				resetMediaList();
				setSelectedFiles([]);
				setLoading(false);
			} else {
				setLoading(false);
				return;
			}
		}
	};

	const press_upload = () => {
		document.getElementById('photo_upload_modal')?.click();
	};

	const handleFileChange = (event: any) => {
		const files: any = Array.from(event.target.files);
		if (!checkAllowedExtensionsForList(files)) {
			alert('Type of file is not allowed!');
			return;
		}
		console.log('files ', files);
		setSelectedFiles(files);
	};

	const resetInputValue = (e: any) => {
		e.target.value = null;
	};

	const closeImage = () => {
		setSelectedFiles([]);
	};

	const closeAddMedia = () => {
		setAddNewMedia(false);
		setSelectedFiles([]);
	};

	const removeImage = (index: number) => {
		var updatedList = [...selectedFiles];
		updatedList.splice(index, 1);
		setSelectedFiles([...updatedList]);
	};

	return (
		<div className="border-[4px] border-[#aeaeae] mt-5 p-5 border-dashed">
			<div className="w-full flex flex-col items-center relative">
				<div onClick={() => closeAddMedia()} className="absolute top-0 right-0 cursor-pointer rounded-[20px] bg-white p-[10px] opacity-40">
					<FaTimes style={{ width: 20, height: 20, stroke: 'white', display: 'block' }} />
				</div>

				<p className="text-center text-base text-black">Upload media files:</p>

				<div className="bg-white flex flex-row items-center gap-3 p-2 rounded-md mt-2">
					<Button variant="outlined" onClick={() => press_upload()} style={{ backgroundColor: '#eaeaea', color: '#777', border: '1px solid #777777' }}>
						Select files
					</Button>

					<p>{!!selectedFiles[0]?.name ? selectedFiles[0]?.name : 'No file chosen'}</p>
				</div>
			</div>
			<p className="mt-3 text-center text-base text-black">Supported image file extensions are: *.png, *.jpg, *.gif ,*.svg</p>
			<p className="mt-3 text-center text-base text-black">Maximum upload file size: 256 MB.</p>
			{selectedFiles.length > 0 && (
				<div>
					<p className=" text-base text-black">Preview:</p>
					<div className="grid lg:grid-cols-6 xl:grid-cols-7 gap-3 2xl:grid-cols-8 mt-2">
						{selectedFiles.map((item: any, index: number) => {
							let image = URL.createObjectURL(item);
							return (
								<div className=" aspect-square border border-[#aeaeae] relative" key={index}>
									<img className=" aspect-square object-cover object-top" src={image} alt="" />
									<div className="absolute -top-1 -right-1 border border-[#D24D57] p-[1px] bg-white cursor-pointer">
										<div className="bg-[#D24D57] p-[2px]" onClick={() => removeImage(index)}>
											<FaTimes color="white" />
										</div>
									</div>
								</div>
							);
						})}
					</div>
					<div className="flex w-full flex-row justify-end gap-8 mt-5  ">
						<Button variant="contained" onClick={() => setSelectedFiles([])} style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
							Remove selected files
						</Button>
						<Button variant="contained" disabled={loading} onClick={() => onSubmit()} style={{ backgroundColor: '#2ec4b6', color: 'white', minWidth: 100 }}>
							{loading ? <Loading height={18} color="#ffffff" /> : <p>Submit</p>}
						</Button>
					</div>
				</div>
			)}

			<input title="Add Video/Photo" multiple style={{ display: 'none' }} accept=" image/*" type="file" id="photo_upload_modal" onChange={handleFileChange} onClick={resetInputValue} />
		</div>
	);
}

export default AddNewMedia;
