import { Autocomplete, Button, FormControl, FormControlLabel, IconButton, Input, InputLabel, MenuItem, Select, Switch, TextField } from '@mui/material';
import { ChromePicker, ColorResult } from 'react-color';
import React, { useEffect, useState } from 'react';
import { createCategory, createSeo, getAllProducts, getCategories, uploadFile, getCategoryById, getSeoById, updateSeo, updateCategory, getAllCategories, getImage, getLanguages } from '../../server/server';
import { useNavigate, useParams } from 'react-router-dom';
import { FaChevronLeft, FaTrash } from 'react-icons/fa';
import AddProductsImageModal from '../products/new-product/AddProductsImageModal';
import MDEditor from '@uiw/react-md-editor';
import rgbHex from 'rgb-hex';
import AddFileFromLibraryModal from '../media/AddFileFromLibraryModal';
import { Language } from '../language/Languages';

type Category = {
	id: string;
	name: string;
	products: string[];
	parentCategory?: Category;
	subCategories?: Category[];
	ordinal: number;
	sku: string;
	imageUrl1: string;
	imageAlt: string;
	thumbnailUrl1: string;
	thumbnailAlt: string;
	web: boolean;
	mobile: boolean;
	color: string;
	description: string;
	disabled: boolean;
	image?: any;
	languages: any;
};

type Product = {
	category: Category;
	created: Date;
	currency: string;
	file: any;
	id: string;
	name: string;
	price: number;
	sku: string;
};

type SeoInfo = {
	id: string;
	metaTitle: string;
	metaDescription: string;
	metaKeywords: string;
	metaUrl: string;
	metaImage: string;
	metaImageUrl: string;
	file: string;
};

let initialSeo: SeoInfo = {
	id: '',
	metaTitle: '',
	metaDescription: '',
	metaKeywords: '',
	metaUrl: '',
	metaImage: '',
	metaImageUrl: '',
	file: '',
};

let initialCategory: Category = {
	id: '',
	name: '',
	products: [],
	parentCategory: undefined,
	subCategories: [],
	ordinal: 1,
	sku: '',
	imageUrl1: '',
	imageAlt: '',
	thumbnailUrl1: '',
	thumbnailAlt: '',
	web: false,
	mobile: false,
	color: '',
	description: '',
	disabled: false,
	languages: [],
};

const ManageCategory = () => {
	const navigation = useNavigate();
	const navigate = useNavigate();
	const { categoryId } = useParams();
	const [category, setCategory] = useState<Category>(initialCategory);
	const [seo, setSeo] = useState<SeoInfo>(initialSeo);
	const [products, setProducts] = useState<Product[]>([]);
	const [selectedProducts, setSelectedProducts] = useState<Product[]>([]);
	const [databaseCategories, setDatabaseCategories] = useState<Category[]>([]);
	const [selectedDatabaseCategory, setSelectedDatabaseCategory] = useState<Category>();
	const [selectedColor, setSelectedColor] = useState({
		h: 250,
		s: 0,
		l: 0.2,
		a: 1,
	});
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 5,
	});
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [originalCategoryImageId, setOriginalCategoryImageId] = useState('');
	const [originalSeoImageId, setOriginalSeoImageId] = useState('');
	const [isAddProductsImageModalOpen, setIsAddProductsImageModalOpen] = useState(false);
	const [isAddProductsImageModalOpenForSeo, setIsAddProductsImageModalOpenForSeo] = useState(false);
	const [markdownContent, setMarkdownContent] = useState<string>('');
	const [nameValid, setNameValid] = useState(true);
	const [skuValid, setSkuValid] = useState(true);
	const [metaUrlValid, setMetaUrlValid] = useState(true);
	const [imageValid, setImageValid] = useState(true);
	const [languageValid, setLanguageValid] = useState(true);
	const [isEditMode, setIsEditMode] = useState(!!categoryId);
	const [libraryModalOpen, setLibraryModalOpen] = useState(false);
	const [libraryModalOpenForSeo, setLibraryModalOpenForSeo] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [languages, setLanguages] = useState<any[]>([]);
	const [selectedLanguages, setSelectedLangauges] = useState<any[]>([]);

	useEffect(() => {
		if (categoryId) {
			fetchCategoryDetails(categoryId);
		}
	}, [categoryId, products]);

	useEffect(() => {
		const limit = 100;
		fetchProducts(limit);
		loadLanguages(paginationModel.page, paginationModel.pageSize);
		fetchCategories();
	}, []);

	const loadLanguages = async (page: any, pageSize: any, searchQuery?: any) => {
		const response = await getLanguages(pageSize, page * pageSize, searchQuery);
		if (response.status == 200) {
			setLanguages(response.data.languages);
		} else {
		}
	};

	function findInitialSelectedProducts(categoryData: any, products: any) {
		const initialSelectedProducts: any = [];
		categoryData.products.forEach((productObj: any) => {
			const productId = productObj.id;
			const product = products.find((product: any) => product.id === productId);
			if (product) {
				initialSelectedProducts.push(product);
			}
		});
		return initialSelectedProducts;
	}
	function findInitialSelectedLanguages(categoryData: any, languages: any) {
		const initialSelectedLanguages: any = [];
		if (categoryData.languages) {
			categoryData?.languages?.forEach((languageObj: any) => {
				const languageId = languageObj.id;
				const language = languages.find((lang: any) => lang.id === languageId);
				if (language) {
					initialSelectedLanguages.push(language);
				}
			});
		}
		return initialSelectedLanguages;
	}

	const fetchCategoryDetails = async (id: string) => {
		setIsLoading(true);
		const response = await getCategoryById(id);
		if (response.status == 200) {
			const categoryData = response.data;

			setCategory(categoryData);
			setMarkdownContent(categoryData.description);
			setOriginalCategoryImageId(categoryData?.image?.id ?? '');
			setImageValid(categoryData?.image?.id ? true : false);
			setOriginalSeoImageId(categoryData?.seo?.metaImage?.id ?? '');
			const initialSelectedProducts = findInitialSelectedProducts(categoryData, products);
			setSelectedProducts(initialSelectedProducts);
			const initialSelectedLanguages = findInitialSelectedLanguages(categoryData, languages);
			setSelectedLangauges(initialSelectedLanguages);
			const initialSelectedCategories = setSelectedDatabaseCategory(categoryData.parentCategory?.id || '');
			const hsla = hexToHSLA(categoryData.color);
			setSelectedColor(hsla);

			if (categoryData.seo) {
				setSeo({
					id: categoryData.seo.id,
					metaTitle: categoryData.seo.metaTitle,
					metaDescription: categoryData.seo.metaDescription,
					metaKeywords: categoryData.seo.metaKeywords,
					metaUrl: categoryData.seo.urlSlug,
					metaImageUrl: categoryData.seo.metaImageUrl,
					metaImage: '',
					file: categoryData.seo.fileId,
				});
			}
		} else {
			alert(`Error fetching category details: ${response.status}`);
		}

		setIsLoading(false);
	};

	function hexToHSLA(H: string): { h: number; s: number; l: number; a: number } {
		let r = 0,
			g = 0,
			b = 0,
			a = 1;
		if (H.length == 5) {
			r = parseInt(H[1] + H[1], 16);
			g = parseInt(H[2] + H[2], 16);
			b = parseInt(H[3] + H[3], 16);
			a = parseInt(H[4] + H[4], 16);
		} else if (H.length == 9) {
			r = parseInt(H[1] + H[2], 16);
			g = parseInt(H[3] + H[4], 16);
			b = parseInt(H[5] + H[6], 16);
			a = parseInt(H[7] + H[8], 16);
		}
		r /= 255;
		g /= 255;
		b /= 255;
		a /= 255;
		let max = Math.max(r, g, b),
			min = Math.min(r, g, b);
		let h = 0,
			s: number,
			l = (max + min) / 2;

		if (max == min) {
			h = s = 0;
		} else {
			let d = max - min;
			s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
			switch (max) {
				case r:
					h = (g - b) / d + (g < b ? 6 : 0);
					break;
				case g:
					h = (b - r) / d + 2;
					break;
				case b:
					h = (r - g) / d + 4;
					break;
			}
			h /= 6;
		}
		return { h: h * 360, s: s * 100, l: l * 100, a: a };
	}

	const fetchCategories = async (): Promise<void> => {
		const resp = await getAllCategories();
		if (resp.status == 200) {
			setDatabaseCategories(resp.data);
		} else {
			alert(`Error fetching categories ${resp.status}`);
		}
	};

	const fetchProducts = async (limit: any, offset = 0, products = []): Promise<void> => {
		const response = await getAllProducts(limit, offset, '', 'all');
		if (response.status == 200) {
			const newProducts = response.data.products;
			products = products.concat(newProducts);
			if (products.length < response.data.total) {
				offset += limit;
				return fetchProducts(limit, offset, products);
			} else {
				setProducts(products);
			}
		} else {
			alert(`Error fetching products: ${response.status}`);
		}
	};

	const validateAndSetState = (field: keyof Category | string, value: string, validationFunction: (value: string) => boolean) => {
		const trimmedValue = value ? value.trim() : '';
		const isValid = validationFunction(trimmedValue);
		switch (field) {
			case 'name':
				setNameValid(isValid);
				break;
			case 'sku':
				setSkuValid(isValid);
				break;
			case 'metaUrl':
				setMetaUrlValid(isValid);
				break;
			case 'image':
				setImageValid(isValid);
				break;
			default:
				break;
		}
		return isValid;
	};

	const handleFieldChange = (event: any, field: keyof Category | string, isMeta: boolean = false) => {
		const value = isMeta ? event.target.value : event.target.value.trim();
		if (isMeta) {
			setSeo((prevSeo) => ({
				...prevSeo,
				[field]: event.target.value,
			}));
		} else {
			if (field === 'ordinal') {
				const ordinalValue = parseInt(value, 10);
				setCategory({
					...category,
					[field as keyof Category]: ordinalValue,
				});
			} else if (typeof category[field as keyof Category] === 'boolean') {
				setCategory({
					...category,
					[field as keyof Category]: event.target.checked,
				});
			} else {
				setCategory({
					...category,
					[field as keyof Category]: event.target.value,
				});
			}
		}
	};

	const handleProductChange = (event: any, newValue: Product[]) => {
		setCategory((prevCategory) => ({
			...prevCategory,
			products: newValue.map((product) => product.id),
		}));
		setSelectedProducts(newValue);
	};

	const handleLanguageChange = (event: any, newValue: Language[]) => {
		setCategory((prevCategory) => ({
			...prevCategory,
			languages: newValue.map((language) => language.id),
		}));
		setSelectedLangauges(newValue);
	};

	const handleColorChange = (color: ColorResult) => {
		const hex = '#' + rgbHex(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a);
		setSelectedColor({
			h: color.hsl.h,
			s: color.hsl.s,
			l: color.hsl.l,
			a: color.hsl.a || 1,
		});
		setCategory({
			...category,
			color: hex,
		});
	};

	const handleDatabaseCategoryChange = (event: any) => {
		const selectedValue = event.target.value;
		const selectedCategory = databaseCategories.find((category) => category.id === selectedValue);
		const newCategory = {
			...category,
			parentCategory: selectedCategory,
		};
		setCategory(newCategory);
		setSelectedDatabaseCategory(selectedValue);
	};

	// const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>, setImageFunction: React.Dispatch<React.SetStateAction<File[]>>, fileType: 'category' | 'seo') => {
	// 	const file = event.target.files?.[0];
	// 	if (file) {
	// 		setImageFunction([file]);
	// 		if (fileType === 'category') {
	// 			setCategoryFileChanged(true);
	// 		} else if (fileType === 'seo') {
	// 			setSeoFileChanged(true);
	// 		}
	// 	}
	// };

	const handleSelectedCategoryFile = async (file: any) => {
		const resp = await uploadFile({ file: file, selectable: 'true' });
		if (resp.status === 201) {
			setOriginalCategoryImageId(resp.data.id);
			setImageValid(true);
			setCategory({ ...category, image: { ...category.image, id: resp.data.id } });
			setIsAddProductsImageModalOpen(false);
		}
	};

	const handleSelectedFile = (id: any) => {
		setOriginalCategoryImageId(id);
		setImageValid(true);
		setCategory({ ...category, image: { ...category.image, id: id } });
		closeLibraryModal();
	};

	const handleSelectedSeoFile = async (file: any) => {
		const resp = await uploadFile({ file: file, selectable: 'true' });
		if (resp.status === 201) {
			setOriginalSeoImageId(resp.data.id);
			setIsAddProductsImageModalOpenForSeo(false);
		}
	};

	const handleSelectedFileForSeo = (id: any) => {
		setOriginalSeoImageId(id);
		closeLibraryModalForSeo();
	};

	const handleRemoveImage = (imageType: 'category' | 'seo') => {
		if (imageType === 'category') {
			setOriginalCategoryImageId('');
			setImageValid(false);
		} else if (imageType === 'seo') {
			setOriginalSeoImageId('');
		}
	};

	const handleSubmit = async () => {
		if (isSubmitting) {
			return;
		}
		const isCategoryNameValid = validateAndSetState('name', category.name, (value) => !!value);
		const isCategorySkuValid = validateAndSetState('sku', category.sku, (value) => !!value);
		const isMetaUrlValid = validateAndSetState('metaUrl', seo.metaUrl, (value) => !!value);
		const isImageValid = validateAndSetState('image', originalCategoryImageId, (value) => !!value);
		const isLanguageValid = selectedLanguages.length <= 0;
		if (isLanguageValid) {
			setLanguageValid(false);
		} else {
			setLanguageValid(true);
		}

		const isFormValid = isCategoryNameValid && isCategorySkuValid && isMetaUrlValid && isImageValid && !isLanguageValid;
		if (isFormValid) {
			setIsSubmitting(true);
			if (isEditMode) {
				if (!category.name || !category.sku || originalCategoryImageId === '' || !seo.metaUrl) {
					alert('Please fill all required fields (*)');
					return;
				}
				const newSeo = {
					metaTitle: seo.metaTitle,
					metaDescription: seo.metaDescription,
					metaKeywords: seo.metaKeywords,
					urlSlug: seo.metaUrl,
					fileId: originalSeoImageId,
				};
				await updateSeo(seo.id, newSeo);
				const newCategory = {
					name: category.name,
					products: category.products,
					sku: category.sku,
					ordinal: category.ordinal,
					parentCategoryId: category.parentCategory?.id || null,
					web: category.web || true,
					mobile: category.mobile,
					color: category.color,
					description: category.description,
					disabled: category.disabled,
					imageId: originalCategoryImageId,
					languages: selectedLanguages.map((language) => language.id),
				};
				console.log('edit', newCategory);
				console.log(category.id);

				const updateResp = await updateCategory(category.id, newCategory);
				if (updateResp.status == 200) {
					alert('Category edited successfully.');
					setIsSubmitting(false);
					navigate('/categories');
				} else {
					alert(`Error editing category: ${updateResp.status}`);
				}
			} else {
				if (!category.name || !category.sku || originalCategoryImageId === '' || !seo.metaUrl || selectedLanguages.length <= 0) {
					alert('Please fill all required fields (*)');
					return;
				}
				const newSeo = {
					metaTitle: seo.metaTitle,
					metaDescription: seo.metaDescription,
					metaKeywords: seo.metaKeywords,
					urlSlug: seo.metaUrl,
					fileId: originalSeoImageId,
				};

				let seoId;
				const createSeoResult = await createSeo(newSeo);

				if (createSeoResult.status === 201) {
					seoId = createSeoResult.data.id;
				}
				const newCategory = {
					name: category.name,
					products: category.products,
					sku: category.sku,
					ordinal: category.ordinal,
					parentCategoryId: category.parentCategory?.id || null,
					web: category.web || true,
					mobile: category.mobile || false,
					color: category.color,
					disabled: category.disabled || false,
					description: category.description,
					seoId,
					imageId: originalCategoryImageId,
					languages: selectedLanguages.map((language) => language.id),
				};

				const resp = await createCategory(newCategory);
				if (resp.status == 201) {
					alert(`Category created successfully!`);
					setIsSubmitting(false);
					navigate('/categories');
				} else {
					alert(`Error creating category:${resp.status}`);
				}
			}
		} else {
			alert('Please fill all required fields (*) and fix validation errors.');
		}
	};

	const handleMarkdownChange = (content: string | undefined) => {
		setMarkdownContent(content || '');
		setCategory({
			...category,
			description: content || '',
		});
	};

	const openAddProductsImageModal = () => {
		setIsAddProductsImageModalOpen(true);
	};

	const openAddProductsImageModalForSeo = () => {
		setIsAddProductsImageModalOpenForSeo(true);
	};

	const closeLibraryModal = () => {
		setLibraryModalOpen(false);
	};

	const closeLibraryModalForSeo = () => {
		setLibraryModalOpenForSeo(false);
	};

	const goBack = () => {
		navigation(-1);
	};

	return (
		<div className="py-5 px-4 h-full space-y-8">
			{isSubmitting && (
				<div
					style={{
						position: 'fixed',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						backgroundColor: 'rgba(255, 255, 255, 0.5)',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						zIndex: 999,
					}}
				>
					Saving category...
				</div>
			)}
			<div className="flex flex-row items-center gap-5 rounded-md shadow-darkGrey shadow-md mb-8">
				<button onClick={goBack} className="flex flex-row gap-2 items-center ml-5">
					<FaChevronLeft /> Back
				</button>
				<div className="text-2xl font-semibold text-black p-5 ">{!category?.id ? 'Add new category' : 'Edit category'}</div>
			</div>
			<div className="grid grid-cols-5 gap-4 ">
				<TextField label="Name *" variant="standard" value={category.name} onChange={(e: any) => handleFieldChange(e, 'name')} error={!nameValid} helperText={!nameValid && 'Category name is required'} />
				<FormControl variant="standard">
					<Autocomplete multiple value={selectedProducts} onChange={handleProductChange} options={products} getOptionLabel={(option) => option.name} renderInput={(params) => <TextField {...params} variant="standard" label="Products" />} />
				</FormControl>
				<TextField fullWidth label="SKU *" variant="standard" value={category.sku} onChange={(e: any) => handleFieldChange(e, 'sku')} error={!skuValid} helperText={!skuValid && 'SKU is required'} />
				<TextField fullWidth label="Ordinal" variant="standard" type="number" value={category.ordinal} onChange={(e: any) => handleFieldChange(e, 'ordinal')} />

				<FormControl fullWidth variant="standard">
					<InputLabel id="simple-select-label">Parent category</InputLabel>
					<Select labelId="simple-select-label" value={selectedDatabaseCategory || ''} onChange={handleDatabaseCategoryChange}>
						<MenuItem value="">
							<em>None</em>
						</MenuItem>
						{databaseCategories.map((dbCategory) => (
							<MenuItem key={dbCategory.id} value={dbCategory.id}>
								{dbCategory.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>
			<div className="grid grid-cols-1 gap-4 items-start">
				<div className="mr-4">
					<MDEditor className="max-h-[300px]" textareaProps={{ placeholder: 'Category description' }} value={markdownContent} onChange={handleMarkdownChange} />
				</div>

				<div className="grid grid-cols-5 gap-4">
					<div className="col-span-2 ">
						<InputLabel htmlFor="category-image-input" style={{ color: imageValid ? '#aeaeae' : '#ff0000' }}>
							Category Image *
						</InputLabel>
						<div className="flex flex-row gap-2">
							<Button onClick={openAddProductsImageModal} variant="contained" color="primary" size="medium" className="w-full py-2 text-xl font-bold min-h-[61px]">
								Add New Category Image
							</Button>
							<Button onClick={() => setLibraryModalOpen(true)} variant="contained" color="primary" size="medium" className="w-full py-2 text-xl font-bold">
								Add Category Image from library
							</Button>
						</div>
						{/* <Input id="category-image-input" type="file" fullWidth onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleImageChange(e, setCategoryFiles, 'category')} /> */}
						{originalCategoryImageId !== '' && (
							<div>
								<p className="text-lg font-semibold mt-2">Category image preview</p>

								<div className="flex space-x-2 mt-2">
									<img src={!!originalCategoryImageId ? getImage(originalCategoryImageId) : ''} alt="category-file" style={{ width: '150px', height: '150px' }} className="shadow-md border border-[#aeaeae] object-cover object-top" />

									{originalCategoryImageId !== '' && (
										<IconButton style={{ maxHeight: 40 }} onClick={() => handleRemoveImage('category')}>
											<FaTrash />
										</IconButton>
									)}
								</div>
							</div>
						)}
					</div>
					<div className="col-span-2">
						<InputLabel htmlFor="seo-image-input">Icon</InputLabel>
						<div className="flex flex-row gap-2">
							<Button onClick={openAddProductsImageModalForSeo} variant="contained" color="primary" size="medium" className="w-full py-2 text-xl font-bold min-h-[61px]">
								Add New Icon Image
							</Button>
							<Button onClick={() => setLibraryModalOpenForSeo(true)} variant="contained" color="primary" size="medium" className="w-full py-2 text-xl font-bold">
								Add Icon Image from library
							</Button>
						</div>
						{/* <Input id="seo-image-input" type="file" fullWidth onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleImageChange(e, setSeoFiles, 'seo')} /> */}
						{originalSeoImageId !== '' && (
							<div>
								<p className="text-lg font-semibold mt-2">Icon preview</p>

								<div className="flex space-x-2 mt-2 ">
									<img src={!!originalSeoImageId ? getImage(originalSeoImageId) : ''} alt="icon-file" style={{ width: '150px', height: '150px' }} className="shadow-md border border-[#aeaeae] object-cover object-top" />

									{originalSeoImageId !== '' && (
										<IconButton style={{ maxHeight: 40 }} onClick={() => handleRemoveImage('seo')}>
											<FaTrash />
										</IconButton>
									)}
								</div>
							</div>
						)}
					</div>
					<div
						onMouseDown={(e) => {
							e.preventDefault();
							e.stopPropagation();
						}}
					>
						<InputLabel htmlFor="seo-image-input">Color</InputLabel>
						<ChromePicker className="max-h-[300px]" disableAlpha={false} color={selectedColor} onChange={handleColorChange} />
					</div>
				</div>

				<AddProductsImageModal modalIsOpen={isAddProductsImageModalOpen} closeModal={() => setIsAddProductsImageModalOpen(false)} setSelectedFileToList={handleSelectedCategoryFile} />
				<AddFileFromLibraryModal modalIsOpen={libraryModalOpen} closeModal={closeLibraryModal} returnSelectedFile={handleSelectedFile} />
				<AddProductsImageModal modalIsOpen={isAddProductsImageModalOpenForSeo} closeModal={() => setIsAddProductsImageModalOpenForSeo(false)} setSelectedFileToList={handleSelectedSeoFile} />
				<AddFileFromLibraryModal modalIsOpen={libraryModalOpenForSeo} closeModal={closeLibraryModalForSeo} returnSelectedFile={handleSelectedFileForSeo} />
			</div>
			<div className="grid grid-cols-10 gap-4">
				<TextField className="col-span-3" fullWidth label="Meta title" variant="standard" value={seo.metaTitle} onChange={(e: any) => handleFieldChange(e, 'metaTitle', true)} />
				<TextField className="col-span-7" fullWidth label="Meta description" variant="standard" value={seo.metaDescription} onChange={(e: any) => handleFieldChange(e, 'metaDescription', true)} />
			</div>
			<div className="grid grid-cols-2 gap-4">
				<TextField fullWidth label="Meta keywords" variant="standard" value={seo.metaKeywords} onChange={(e: any) => handleFieldChange(e, 'metaKeywords', true)} />
				<TextField fullWidth label="Meta url slug *" variant="standard" value={seo.metaUrl} onChange={(e: any) => handleFieldChange(e, 'metaUrl', true)} error={!metaUrlValid} helperText={!metaUrlValid && 'URL slug is required'} />
			</div>
			<div className="grid grid-cols-10 gap-4">
				<div className="max-w-[350px] w-full col-span-5">
					<Autocomplete
						multiple
						options={languages}
						value={selectedLanguages}
						onChange={handleLanguageChange}
						getOptionLabel={(option) => option.name}
						renderInput={(params) => <TextField helperText={!languageValid && 'Please select a language'} error={!languageValid} label="Select language *" {...params} variant="standard" />}
					/>
				</div>
				<FormControlLabel control={<Switch checked={category.web} onChange={(e: any) => handleFieldChange(e, 'web')} />} label="Web" />
				<FormControlLabel control={<Switch checked={category.mobile} onChange={(e: any) => handleFieldChange(e, 'mobile')} />} label="Mobile" />
			</div>
			<div className="grid grid-cols-4 gap-4">
				<div className="grid grid-cols-4 col-span-4"></div>
			</div>
			<div className="flex w-full justify-end">
				<Button variant="contained" color="primary" size="large" className="py-2 text-xl font-bold" onClick={handleSubmit} disabled={isSubmitting}>
					Submit category
				</Button>
			</div>
		</div>
	);
};

export default ManageCategory;
