import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { useUser } from '../auth/UseUser';

const PublicRoute = ({ Component }: any) => {
	const { isSignedIn } = useUser();
	return isSignedIn ? <Navigate to="/products" /> : <Component />;
};

export default PublicRoute;
