import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import { Box, Button, TextField } from '@mui/material';
import { createGroup, createOption } from '../../../server/server';
import { CreateGroupDto } from '../../../model/group';
import { CreateOptionDto } from '../../../model/option';
import { ColorPicker, ColorValue, createColor } from 'mui-color';

function AddOptionsModal({ modalIsOpen, closeModal, reloadOptions, selectedGroup, selectedOption, optionForModal, product }: any) {
	const [name, setName] = useState('');
	const [color, setColor] = useState<any>(undefined);
	const [selectedRelatedGroup, setSelectedRelatedGroup] = useState<any>(null);
	const [error, setError] = useState<any>({});

	useEffect(() => {
		if (modalIsOpen) {
			ReactModal.setAppElement('#root');
		}
	}, [modalIsOpen]);

	useEffect(() => {
		if (!!optionForModal?.id) {
			setName(optionForModal.name);
			setColor(!!optionForModal?.color ? createColor(optionForModal?.color) : undefined);
		}
	}, [optionForModal]);

	const onSubmit = async () => {
		if (!checkIfIsValid()) return;
		const data: CreateOptionDto = {
			name: name,
			groupId: selectedGroup?.id,
			color: !!color?.hex ? '#' + color?.hex : '',
		};
		if (!optionForModal?.id) {
			createNewOption(data);
		} else {
			data.id = optionForModal.id;
			createNewOption(data);
		}
	};

	const createNewOption = async (data: CreateOptionDto) => {
		const resp = await createOption(data);
		if (resp.status === 201) {
			console.log('optiob ', resp.data);
			closeModalAndReloadOptions();
		}
	};
	const editGroup = async (data: any) => {};

	const closeModalAndReloadOptions = () => {
		reloadOptions(selectedGroup.id);
		closeModal();
	};

	const reset = () => {
		setName('');
		setColor(undefined);
	};
	const checkIfIsValid = () => {
		let valid = true;
		if (!name) {
			setError((e: any) => ({ ...e, name: 'Required filed ' }));
			valid = false;
		}

		return valid;
	};
	const handleNameChange = (event: any) => {
		setError({ ...error, name: !!event.target.value ? '' : 'Required filed' });
		setName(event.target.value);
	};
	return (
		<ReactModal
			ariaHideApp={false}
			//closeTimeoutMS={600}
			isOpen={modalIsOpen}
			onAfterOpen={() => {}}
			onAfterClose={() => reset()}
			onRequestClose={closeModal}
			className="no-scroll"
			style={{
				overlay: {
					zIndex: 5,
					backdropFilter: 'brightness(30%)',
				},
				content: {
					padding: 0,
					opacity: 1,
					width: 600,
					margin: 'auto',
					backgroundColor: 'transparent',
					height: '100%',
					maxHeight: '100%',
					border: 'none',
					boxShadow: 'none',
					overflow: 'auto',
					paddingTop: 15,
					paddingBottom: 15,
				},
			}}
		>
			<div className="   max-h-[600px] w-full  ">
				<div className=" flex flex-1 flex-col gap-5 rounded-[10px] bg-white  px-[40px] py-[30px] ">
					<div className=" ">
						<TextField required className=" background-transparent " value={name} onChange={(e) => handleNameChange(e)} helperText={error?.name ?? ''} error={!!error?.name} style={{ width: '100%' }} label="Option name" variant="filled" />
					</div>
					<div className=" flex flex-row  gap-5 ">
						<div className="flex flex-col">
							<p>Select color</p>
							<p>(press button)</p>
						</div>
						<Box>
							<ColorPicker openAtStart value={color} onChange={(value) => setColor(value)} />
						</Box>
					</div>
					<div className="flex w-full flex-row justify-end gap-8 mt-20  ">
						<Button variant="contained" onClick={() => closeModal()} style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
							Cancel
						</Button>
						<Button variant="contained" onClick={() => onSubmit()} style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
							Submit
						</Button>
					</div>
				</div>
			</div>
		</ReactModal>
	);
}

export default AddOptionsModal;
