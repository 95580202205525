import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import ReactModal from 'react-modal';
import { TextField, MenuItem, Button, List, ListItem, ListItemText, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

function PersonalizationChooseModel({ modalIsOpen, afterOpenModal, closeModal, confirmation, displayDigitalFieldsList, setDisplayDigitalFieldsList }: any) {
    const [name, setName] = useState('');
    const [type, setType] = useState('text');
    const [editIndex, setEditIndex] = useState(-1);

    const handleAdd = () => {
        if (editIndex === -1) { // Add new item
            setDisplayDigitalFieldsList([...displayDigitalFieldsList, { name, type }]);
        } else { // Update existing item
            const updatedItems = displayDigitalFieldsList.map((item: any, index: any) => index === editIndex ? { name, type } : item);
            setDisplayDigitalFieldsList(updatedItems);
            setEditIndex(-1);
        }
        setName('');
        setType('text');
    };

    const handleDelete = (index: any) => {
        const filteredItems = displayDigitalFieldsList.filter((_: any, i: any) => i !== index);
        setDisplayDigitalFieldsList(filteredItems);
    };

    const handleEdit = (item: any, index: any) => {
        setName(item.name);
        setType(item.type);
        setEditIndex(index);
    };

    useEffect(() => {
        ReactModal.setAppElement('#root');
    }, []);
    const submitConfirm = () => {
        confirmation();
        closeModal();
    };

    return (
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            contentLabel="Example Modal"
            style={{
                overlay: {
                    zIndex: 10,
                },
                content: {
                    width: 600,
                    height: 680,
                    padding: 0,
                    opacity: 1,
                    minWidth: 600,
                    margin: 'auto',
                    marginTop: '5%',
                    backgroundColor: 'white',
                    maxHeight: '80%',
                    border: 'none',
                    boxShadow: 'none',
                    overflow: 'hidden',
                },
            }}
        >
            <div className="bg-greyBackground border border-[#81848f] rounded-xl flex flex-col h-full items-center justify-between p-8">
                <div className='w-full flex flex-col items-center gap-2'>
                    <h2 className="text-2xl font-bold">List display product fields</h2>
                    <div className="container mx-auto p-2">
                        <div className="flex gap-2 mb-4">
                            <TextField
                                label="Name"
                                variant="outlined"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <TextField
                                select
                                label="Type"
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                                variant="outlined"
                            >
                                <MenuItem value="text">Text</MenuItem>
                                <MenuItem value="image">Image</MenuItem>
                            </TextField>
                            <Button variant="contained" onClick={handleAdd}>
                                {editIndex === -1 ? 'Add' : 'Update'}
                            </Button>
                        </div>
                        <List>
                            {displayDigitalFieldsList.map((item: any, index: any) => (
                                <ListItem
                                    key={index}
                                    secondaryAction={
                                        <>
                                            <IconButton edge="end" aria-label="edit" onClick={() => handleEdit(item, index)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </>
                                    }
                                >
                                    <ListItemText primary={item.name} secondary={item.type} />
                                </ListItem>
                            ))}
                        </List>
                    </div></div>

                <div className='flex w-full items-center justify-between'>
                    <Button variant="contained" onClick={closeModal} color="error">
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={submitConfirm} >
                        Save
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default PersonalizationChooseModel;
