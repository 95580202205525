export const env: string = process.env.REACT_APP_ENV || 'local';

const urls: any = {
	local: {
		baseUrl: 'http://localhost:3003',
	},
	dev: {
		baseUrl: 'https://layka-api.we-deliver.net',
	},
	prod: {
		baseUrl: 'https://api.laykagift.com',
	},
};

export const baseUrl = urls[env].baseUrl;
