import React, { useEffect, useState } from 'react'
import { getAllLanguages, getAllTopCategoriesByLanguage, getCategoriesChildren, updateCategoriesOrder } from '../../server/server';
import { set } from 'date-fns';
import { Autocomplete, Button, Checkbox, Divider, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FaStar, FaStarOfLife } from 'react-icons/fa';

function Menu() {
    const [languages, setLanguages] = useState<any>([])
    const [selectedLanguage, setSelectedLanguage] = useState<any>(null)
    const [selectedCategory, setSelectedCategory] = useState<any>(null)
    const [items, setItems] = useState<any>([]);
    const [childItems, setChildItems] = useState<any>([]);

    const getData = (arr: any) => {
        return arr.map((item: any, index: any) => {
            return {
                id: item.id,
                ordinal: index,
                featured: item.featured
            }
        })
    }

    const updateCatOrder = async () => {
        if (!selectedLanguage) return alert('Please select a language')
        if (!items.length) return alert('No categories to update')

        const resp = await updateCategoriesOrder({ categories: [...getData(items), ...getData(childItems)] })
        if (resp.status == 201) {
            alert('Order updated')
        }
    }

    const fetchCategoryChildren = async () => {
        const resp = await getCategoriesChildren(selectedCategory.id);
        if (resp.status == 200) {
            setChildItems(resp.data)
        }
    }
    const fetchLanguages = async () => {

        const response = await getAllLanguages()

        if (response.status == 200) {
            setLanguages(response.data)
        };
    }

    const fetchCategoriesByLang = async () => {
        const resp = await getAllTopCategoriesByLanguage(selectedLanguage.id);
        if (resp.status == 200) {
            setItems(resp.data)
        }
    }

    useEffect(() => {
        if (selectedLanguage?.id) {
            fetchCategoriesByLang()
        }
    }, [selectedLanguage])

    useEffect(() => {
        if (selectedCategory?.id) {
            fetchCategoryChildren()
        }
    }, [selectedCategory])

    useEffect(() => {
        fetchLanguages()
    }, [])


    const handleDrop = (result: any) => {
        if (!result.destination) return;
        const newArr = Array.from(items);
        const [reorderedItem] = newArr.splice(result.source.index, 1);
        newArr.splice(result.destination.index, 0, reorderedItem);
        setItems(newArr);
    };
    const handleChildDrop = (result: any) => {
        if (!result.destination) return;
        const newArr = Array.from(childItems);
        const [reorderedItem] = newArr.splice(result.source.index, 1);
        newArr.splice(result.destination.index, 0, reorderedItem);
        setChildItems(newArr);
    };

    const handleCheck = (id: string) => {
        const newArr = items.map((item: any) => {
            if (item.id == id) {
                return { ...item, featured: !item.featured }
            }
            return item
        })
        setItems(newArr)
    }

    return (
        <div className="py-10 px-2 h-full w-full">
            <div className="py-10 h-full w-full">
                <div className="flex items-center gap-8">
                    <h1 className="text-black text-4xl font-bold">Menu</h1>
                </div>
                <div className='flex gap-5'>   <Autocomplete
                    value={selectedLanguage}
                    sx={{ width: 300, mb: 4 }}
                    onChange={(e: any, newValue: any) => setSelectedLanguage(newValue)}
                    options={languages}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} variant="standard" label="Language" />} />

                    <Button variant="contained" color="primary" onClick={updateCatOrder} style={{ backgroundColor: '#21187F', color: 'white', height: 50 }}>Update Order</Button></div>

                {selectedLanguage && <div className='flex gap-6 mt-12'>
                    <div className='w-[300px] '>
                        <p className=' text-2xl font-bold mb-5'>Top Categories</p>
                        <DragDropContext onDragEnd={handleDrop}>
                            <Droppable droppableId="list-container">
                                {(provided) => (
                                    <TableContainer component={Paper} className="mt-5" ref={provided.innerRef} {...provided.droppableProps}>
                                        <Table>
                                            <TableBody>
                                                {items.map((item: any, index: any) => (
                                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                                        {(provided) => (
                                                            <TableRow
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className="cursor-move"
                                                                sx={{
                                                                    backgroundColor: item.id === selectedCategory?.id ? '#f5f5f5' : 'white',
                                                                    width: '100%',
                                                                    '&:hover': {
                                                                        backgroundColor: '#f5f5f5'
                                                                    }
                                                                }}
                                                                onClick={() => setSelectedCategory(selectedCategory?.id === item?.id ? null : item)}
                                                            >
                                                                <TableCell >
                                                                    <div className='flex items-center justify-between'>
                                                                        {item.name}
                                                                        <Checkbox
                                                                            icon={<FaStar />}
                                                                            checkedIcon={<FaStarOfLife />}
                                                                            checked={item.featured}
                                                                            onChange={() => handleCheck(item.id)}
                                                                        /></div>

                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                    <Divider orientation="vertical" flexItem />
                    {selectedCategory && <div className='w-[300px] '>
                        <p className=' text-2xl font-bold mb-5'>Child Categories</p>
                        <DragDropContext onDragEnd={handleChildDrop}>
                            <Droppable droppableId="list-container">
                                {(provided) => (
                                    <TableContainer component={Paper} className="mt-5" ref={provided.innerRef} {...provided.droppableProps}>
                                        <Table>
                                            <TableBody>
                                                {childItems.map((item: any, index: any) => (
                                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                                        {(provided) => (
                                                            <TableRow
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className="cursor-move"
                                                                sx={{
                                                                    backgroundColor: 'white',
                                                                    width: '100%',
                                                                    '&:hover': {
                                                                        backgroundColor: '#f5f5f5'
                                                                    }
                                                                }}
                                                            >
                                                                <TableCell>
                                                                    {item.name}
                                                                </TableCell>
                                                            </TableRow>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>}
                </div>}


            </div>
        </div>
    )
}

export default Menu