import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import { Button, TextField } from '@mui/material';
import { createAllowedFonts, getAvailableAllowedFonts } from '../../../server/server';
import useDebounce from '../../../hooks/useDebounce';
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';

function AddFontsModal({ modalIsOpen, closeModal, product, reloadFonts }: any) {
	const [fonts, setFonts] = useState([]);
	const [rowCountState, setRowCountState] = useState(5);
	const [isLoading, setIsLoading] = useState(true);
	const [searchQuery, setSearchQuery] = useState('');
	const [selectedFonts, setSelectedFonts] = useState<any[][]>([[]]);
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 5,
	});
	const searchDelay = 2000;
	const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);
	const debouncedQuery = useDebounce(searchQuery, searchDelay);

	useEffect(() => {
		if (modalIsOpen) {
			ReactModal.setAppElement('#root');
			loadFontsList(product?.id, paginationModel.page, paginationModel.pageSize, debouncedQuery);
		}
	}, [modalIsOpen]);
	useEffect(() => {
		if (modalIsOpen) {
			loadFontsList(product?.id, paginationModel.page, paginationModel.pageSize, debouncedQuery);
		}
	}, [paginationModel.page, paginationModel.pageSize, debouncedQuery]);

	const loadFontsList = async (productId: string, page?: any, pageSize?: any, searchQuery?: any) => {
		setIsLoading(true);
		const resp = await getAvailableAllowedFonts(productId, pageSize, page * pageSize, searchQuery);
		if (resp.status === 200) {
			setFonts(resp.data?.items);
			setRowCountState(resp.data?.total);
			setIsLoading(false);
		}
	};

	const onSubmit = async () => {
		createAllowedProductFonts(selectedFonts.flat());
	};

	const createAllowedProductFonts = async (fontList: any) => {
		let data = createListOfFonts(fontList);
		const response = await createAllowedFonts(data);
		if (response.status === 201) {
			console.log(' created ', response.data);
			closeModalAndReloadFonts();
		}
	};

	const createListOfFonts = (list: string[]) => {
		let newData: any = { allowedFonts: [] };
		list.forEach((fontId: string) => {
			let allowedFont = {
				productId: product?.id,
				fontId: fontId,
			};
			newData.allowedFonts.push(allowedFont);
		});
		return newData;
	};

	const closeModalAndReloadFonts = () => {
		reloadFonts();
		closeModal();
	};

	const reset = () => {
		setSelectedFonts([[]]);
		setPaginationModel({
			page: 0,
			pageSize: 5,
		});
		setFonts([]);
	};

	const columns: GridColDef[] = [
		{ field: 'name', headerName: 'Design name', width: 200, flex: 1, sortable: false, disableColumnMenu: true },
		{ field: 'sample', headerName: 'Font sample', filterable: false, sortable: false, width: 200, flex: 2, renderCell: (params) => <p style={{ fontFamily: params.row?.name, fontSize: 20 }}>Quick brown fox jumps over the lazy dog</p> },
	];
	return (
		<ReactModal
			ariaHideApp={false}
			//closeTimeoutMS={600}
			isOpen={modalIsOpen}
			onAfterOpen={() => {}}
			onAfterClose={() => reset()}
			onRequestClose={closeModal}
			className="no-scroll"
			style={{
				overlay: {
					zIndex: 5,
					backdropFilter: 'brightness(30%)',
				},
				content: {
					padding: 0,
					opacity: 1,
					width: 950,
					margin: 'auto',
					backgroundColor: 'transparent',
					height: '100%',
					maxHeight: '100%',
					border: 'none',
					boxShadow: 'none',
					overflow: 'auto',
					paddingTop: 15,
					paddingBottom: 15,
				},
			}}
		>
			<div className="   max-h-[600px] w-full  ">
				<div className=" flex flex-1 flex-col gap-5 rounded-[10px] bg-white  px-[40px] py-[30px] ">
					<p className=" text-center text-xl ">Add Fonts for product</p>
					<div className="mt-5 h-fit w-full">
						<DataGrid
							autoHeight
							checkboxSelection
							rowCount={rowCountState}
							loading={isLoading}
							disableRowSelectionOnClick
							pagination
							rows={fonts}
							columns={[...columns]}
							showCellVerticalBorder={false}
							style={{ minHeight: 371 }}
							pageSizeOptions={[5, 10, 15]}
							paginationModel={paginationModel}
							paginationMode="server"
							rowSelectionModel={selectedFonts[paginationModel.page]}
							onRowSelectionModelChange={(newRowSelectionModel: GridRowSelectionModel) => {
								setRowSelectionModel(newRowSelectionModel);
								let list: any[] = [...newRowSelectionModel];
								selectedFonts[paginationModel.page] = list;
							}}
							onPaginationModelChange={(newModel) => {
								if (newModel.pageSize !== paginationModel.pageSize) {
									setSelectedFonts([[]]);
									setPaginationModel({
										page: 0,
										pageSize: newModel.pageSize,
									});
									return;
								} else {
									console.log('pagination model', newModel);
									setPaginationModel(newModel);
									if (newModel.page + 1 > selectedFonts.length) {
										setSelectedFonts([...selectedFonts, []]);
									}
								}
							}}
						/>
					</div>
					<div className="flex w-full flex-row justify-end gap-8 mt-10  ">
						<Button variant="contained" onClick={() => closeModal()} style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
							Cancel
						</Button>
						<Button variant="contained" onClick={() => onSubmit()} style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
							Submit
						</Button>
					</div>
				</div>
			</div>
		</ReactModal>
	);
}

export default AddFontsModal;
