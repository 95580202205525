import { Button, Tab, Tabs, TextField } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridColDef, GridRowId } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { FaAngleDown, FaAngleUp, FaBars, FaEdit, FaTrash } from 'react-icons/fa';
import { deleteSlider, editSliderOrder, getImage, getSliders } from '../../server/server';
import { useNavigate } from 'react-router-dom';
import { Slider as SliderInterface } from './ManageSlider';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const Slider = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [sliders, setSliders] = useState<SliderInterface[]>([]);
	const [filteredSliders, setFilteredSliders] = useState<SliderInterface[]>([]);
	const [rowCountState, setRowCountState] = useState(5);
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 99,
	});

	const [allItemsCount, setAllItemsCount] = useState(0);
	const [activeCount, setActiveCount] = useState(0);
	const [disabledCount, setDisabledCount] = useState(0);

	const [filter, setFilter] = useState('all');

	const navigate = useNavigate();

	useEffect(() => {
		fetchSliders();
	}, []);

	const fetchSliders = async () => {
		setIsLoading(true);
		const resp = await getSliders();

		if (resp.status == 200) {
			const sortedSliders = resp.data.sort((a: any, b: any) => a.ordinal - b.ordinal);
			setSliders(sortedSliders);
			setRowCountState(resp.data.length);

			applyFilter(filter, sortedSliders);

			setAllItemsCount(sortedSliders.length);
			setActiveCount(sortedSliders.filter((slider: any) => !slider.disabled).length);
			setDisabledCount(sortedSliders.filter((slider: any) => slider.disabled).length);

			setIsLoading(false);
		} else {
			alert(`Error fetching sliders:${resp.status}`);
		}
	};

	const applyFilter = (filter: string, slidersToFilter: SliderInterface[]) => {
		let newFilteredSliders;
		switch (filter) {
			case 'all':
				newFilteredSliders = slidersToFilter;
				break;
			case 'active':
				newFilteredSliders = slidersToFilter.filter((slider) => !slider.disabled);
				break;
			case 'disabled':
				newFilteredSliders = slidersToFilter.filter((slider) => slider.disabled);
				break;
			default:
				newFilteredSliders = slidersToFilter;
		}
		setFilteredSliders(newFilteredSliders);
	};

	const handleFilterChange = (event: any, newValue: any) => {
		setFilter(newValue);
		applyFilter(newValue, sliders);
	};

	function handleNewSlider(): void {
		navigate('/sliders/manage');

		fetchSliders();
	}
	function editSlider(id: GridRowId): void {
		const selectedSlider = sliders.find((slide) => slide.id === id);
		navigate(`/sliders/manage/${selectedSlider?.id}`);
	}

	async function deleteRowSlider(id: GridRowId) {
		const isConfirmed = window.confirm('Are you sure you want to delete this slider?');
		if (isConfirmed) {
			setFilteredSliders((prevSlider) => prevSlider.filter((slider: any) => slider.id !== id));

			deleteSlider(id).then(async () => {
				alert('Slider deleted successfully');
				const resp = await fetchSliders();
				console.log(resp);
			});
		} else {
			return;
		}
	}

	const handleDrop = async (droppedItem: any) => {
		// Ignore drop outside droppable container
		if (!droppedItem.destination) return;
		var updatedList = [...filteredSliders];
		// Remove dragged item
		const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
		// Add dropped item
		updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
		let newReorderList = updatedList.map((item, index) => {
			return { ...item, ordinal: index + 1 };
		});
		// Update State
		setFilteredSliders(newReorderList);
		const resp = await editSliderOrder({ sliders: newReorderList });
		if (resp.status !== 200) {
			alert(`Error changing payment type order: ${resp.status}`);
		}
	};

	// const columns: GridColDef[] = [
	// 	{ field: 'name', headerName: 'Name', flex: 1, filterable: true },
	// 	{ field: 'description', headerName: 'Description', flex: 1, maxWidth: 300, filterable: true },
	// 	{
	// 		field: 'color',
	// 		headerName: 'Color',
	// 		renderCell: (params) => (
	// 			<div>
	// 				<div style={{ backgroundColor: params.value, width: 20, height: 20, display: 'inline-block', marginRight: 10 }}></div>
	// 				<span>{params.value}</span>
	// 			</div>
	// 		),
	// 	},
	// 	{
	// 		field: 'image',
	// 		headerName: 'Image',
	// 		filterable: false,
	// 		sortable: false,
	// 		renderCell: (params) => {
	// 			const { id } = params.value;
	// 			return id ? <img src={getImage(id)} alt="image" width="50" /> : null;
	// 		},
	// 	},
	// 	{
	// 		field: 'ordinal',
	// 		headerName: 'Order',
	// 		filterable: true,
	// 	},
	// 	{ field: 'disabled', headerName: 'Disabled', filterable: true },
	// 	{
	// 		field: 'actions',
	// 		type: 'actions',
	// 		headerName: 'Actions',
	// 		getActions: (params) => [
	// 			<GridActionsCellItem icon={<FaEdit style={{ width: 20, height: 20 }} />} label="Edit" onClick={() => editSlider(params?.id)} />,
	// 			<GridActionsCellItem icon={<FaTrash style={{ width: 20, height: 20, color: '#D24D57' }} />} label="Delete" onClick={() => deleteRowSlider(params?.id)} />,
	// 		],
	// 	},
	// ];

	return (
		<div className="py-10 px-2 h-full w-full">
			<div className="flex flex-col md:flex-row gap-6">
				<h1 className="text-2xl md:text-4xl font-bold">Sliders</h1>
				<Button variant="contained" color="primary" onClick={() => handleNewSlider()} style={{ backgroundColor: '#21187F', color: 'white' }}>
					Add New Slider
				</Button>
			</div>
			<div className="rounded-lg border border-[#81848f3d] min-w-full p-8 pb-4 mt-8 overflow-x-auto">
				<div className="flex flex-col md:flex-row justify-between items-center">
					<Tabs value={filter} onChange={handleFilterChange} classes={{ flexContainer: 'flex-wrap sm:flex-nowrap' }}>
						<Tab label={`All (${allItemsCount})`} value="all" />
						<Tab label={`Active (${activeCount})`} value="active" />
						<Tab label={`Disabled(${disabledCount})`} value="disabled" />
					</Tabs>
				</div>
				<div className="w-full mt-5 min-h-[300px] overflow-y-auto small-gray-scroll">
					{sliders.length ? (
						<div className="grid grid-cols-7 p-2 gap-1 border-b-2 border-gray">
							<p className="text-darkGrey text-lg ">Name</p>
							<p className="text-darkGrey text-lg ml-2">Description</p>
							<p className="text-darkGrey text-lg">Color</p>
							<p className="text-darkGrey text-lg">Image</p>
							<p className="text-darkGrey text-lg">Order</p>
							<p className="text-darkGrey text-lg">Disabled</p>
							<p className="text-darkGrey text-lg">Actions</p>
						</div>
					) : (
						<p className="text-red-500">No sliders found.</p>
					)}
					{sliders.length > 0 ? (
						<DragDropContext onDragEnd={handleDrop}>
							<Droppable droppableId="list-container">
								{(provided) => (
									<div {...provided.droppableProps} ref={provided.innerRef}>
										{filteredSliders?.map((item: any, index: number) => {
											const isEven = index % 2 === 0;
											console.log(item);
											return (
												<Draggable key={item.id} draggableId={item.id} index={index}>
													{(provided) => (
														<div ref={provided.innerRef} {...provided.draggableProps}>
															<div className={`grid grid-cols-7 gap-1 transition-colors  mt-2 ${isEven ? 'bg-slate' : 'bg-white'}`} {...provided.dragHandleProps}>
																<div className="flex flex-1 flex-row items-center justify-between col-span-1 p-[6px]">
																	<p className="text-darkGrey text-lg ">{item.name}</p>
																</div>
																<div className="flex flex-row items-center justify-between col-span-1 p-[6px]">
																	<p className="text-darkGrey text-lg ">{item.description}</p>
																</div>
																<div className="flex flex-row items-center justify-between col-span-1 p-[6px]">
																	<p className="text-darkGrey text-lg">{item.color}</p>
																</div>
																<div className="flex flex-row items-center justify-between col-span-1 p-[6px]">
																	<img src={getImage(item?.image?.id)} alt="image" width="50" />
																</div>
																<div className="flex flex-row items-center justify-between col-span-1 p-[6px]">
																	<p className="text-darkGrey text-lg">{item.ordinal}</p>
																</div>
																<div className="flex flex-row items-center justify-between col-span-1 p-[6px]">
																	<p className="text-darkGrey text-lg">{item.disabled.toString()}</p>
																</div>
																<div className="flex flex-row items-center col-span-1 p-[6px]">
																	<FaEdit style={{ width: 20, height: 20, marginRight: '10px', cursor: 'pointer' }} onClick={() => editSlider(item.id)} />
																	<FaTrash style={{ width: 20, height: 20, color: '#D24D57', cursor: 'pointer' }} onClick={() => deleteRowSlider(item.id)} />
																</div>
															</div>
														</div>
													)}
												</Draggable>
											);
										})}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</DragDropContext>
					) : null}
				</div>
				{/* <div style={{ height: filteredSliders.length === 0 || isLoading ? '300px' : 'auto' }} className="mt-5 h-fit w-full">
					<DataGrid
						autoHeight
						rows={filteredSliders}
						columns={columns}
						rowCount={filteredSliders.length}
						loading={isLoading}
						pageSizeOptions={[5, 15, 25]}
						paginationModel={paginationModel}
						paginationMode="client"
						onPaginationModelChange={(newModel) => {
							setPaginationModel(newModel);
						}}
					/>
				</div> */}
			</div>
		</div>
	);
};

export default Slider;
