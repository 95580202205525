import { Button, Collapse } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridColDef, GridRowId } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { deleteLanguage, editLanguage, editLanguageOrder, getImage, getLanguages } from '../../server/server';
import { FaChevronDown, FaChevronUp, FaEdit, FaTrash } from 'react-icons/fa';
import useDebounce from '../../hooks/useDebounce';
import EditLanguageModal from './EditLanguageModal';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

export type Language = {
	id: string;
	languageName: string;
	locale: string;
	languageCode: string;
	textDirection: string;
	flagId: string;
	priority: number;
	disabled: boolean;
	flag: {
		id: string;
	};
};

const Languages = () => {
	const [languages, setLanguages] = useState<Language[]>([]);
	const [selectedLanguage, setSelectedLanguage] = useState<string | undefined>('');
	const [isLoading, setIsLoading] = useState(false);
	const [rowCountState, setRowCountState] = useState(5);
	const [searchQuery, setSearchQuery] = useState('');
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 99,
	});
	const [addLanguageModalVisible, setAddLanguageModalVisible] = useState(false);

	const searchDelay = 2000;

	const debouncedQuery = useDebounce(searchQuery, searchDelay);

	useEffect(() => {
		fetchLanguages(paginationModel.page, paginationModel.pageSize, debouncedQuery);
	}, []);

	const fetchLanguages = async (page: any, pageSize: any, searchQuery?: any) => {
		setIsLoading(true);

		const response = await getLanguages(pageSize, page * pageSize, searchQuery);

		if (response.status == 200) {
			const sortedLanguages = response.data.languages.sort((a: any, b: any) => a.priority - b.priority);

			setLanguages(sortedLanguages);
			setRowCountState(response.data.total);
			setIsLoading(false);
		} else {
			alert(`Error fetching languages`);
			setIsLoading(false);
		}
	};

	const handleClose = () => {
		setAddLanguageModalVisible(false);
		setSelectedLanguage(undefined);

		fetchLanguages(paginationModel.page, paginationModel.pageSize, debouncedQuery);
	};

	const selectEditLanguage = async (languageId?: string) => {
		const langId = languageId ? languages.find((lang) => lang.id === languageId) : undefined;

		if (langId) {
			setSelectedLanguage(langId.id);
			setAddLanguageModalVisible(true);
		}
	};

	const deleteRowLanguage = async (id: string) => {
		if (window.confirm('Do you want to delete this language?')) {
			const resp = await deleteLanguage(id);

			if (resp.status == 200) {
				alert('Language deleted successfully');
			} else {
				alert(`Language deletion failed: ${resp.status}`);
			}
			setLanguages((prevLang) => prevLang.filter((category: any) => category.id !== id));

			fetchLanguages(paginationModel.page, paginationModel.pageSize, debouncedQuery);
		}
	};

	const handleAddLanguageClick = () => {
		setAddLanguageModalVisible(true);
	};

	const handleDrop = async (droppedItem: any) => {
		// Ignore drop outside droppable container
		if (!droppedItem.destination) return;
		var updatedList = [...languages];
		// Remove dragged item
		const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
		// Add dropped item
		updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
		let newReorderList = updatedList.map((item, index) => {
			return { ...item, ordinal: index + 1 };
		});
		// Update State
		setLanguages(newReorderList);
		const resp = await editLanguageOrder({ languages: newReorderList });
		if (resp.status !== 200) {
			alert(`Error changing payment type order: ${resp.status}`);
		} else {
			fetchLanguages(paginationModel.page, paginationModel.pageSize, debouncedQuery);
		}
	};

	// const columns: GridColDef[] = [
	// 	{ field: 'name', headerName: 'Name', flex: 1 },
	// 	{ field: 'locale', headerName: 'Locale' },
	// 	{ field: 'code', headerName: 'Code' },
	// 	{ field: 'priority', headerName: 'Order', type: 'number' },
	// 	{
	// 		field: 'flag',
	// 		headerName: 'Flag',
	// 		filterable: false,
	// 		sortable: false,
	// 		renderCell: (params) => {
	// 			const { id } = params?.value || '';
	// 			return <img src={getImage(id)} alt="flag" width="50" />;
	// 		},
	// 	},
	// 	{ field: 'points', headerName: 'Points', type: 'number' },
	// 	{
	// 		field: 'actions',
	// 		type: 'actions',
	// 		headerName: 'Actions',
	// 		width: 100,
	// 		getActions: (params) => [
	// 			<GridActionsCellItem icon={<FaEdit style={{ width: 20, height: 20 }} />} label="Edit" onClick={() => selectEditLanguage(params.id, undefined)} />,
	// 			<GridActionsCellItem icon={<FaTrash style={{ width: 20, height: 20, color: '#D24D57' }} />} label="Delete" onClick={() => deleteRowLanguage(params.id)} />,
	// 		],
	// 	},
	// ];

	return (
		<div className="py-10 px-2 h-full w-full">
			<div className="flex flex-col md:flex-row gap-6">
				<h1 className="text-black text-4xl font-bold">Languages options</h1>
				<Button variant="contained" color="primary" onClick={handleAddLanguageClick} style={{ backgroundColor: '#21187F', color: 'white' }}>
					Add New Language
				</Button>
			</div>
			<div className="py-10 px-2">
				<div className="w-full mt-5 min-h-[300px] overflow-y-auto small-gray-scroll">
					{languages.length ? (
						<div className="grid grid-cols-7 p-2 gap-1 border-b-2 border-gray">
							<p className="text-darkGrey text-lg flex-1">Name</p>
							<p className="text-darkGrey text-lg ml-2">Locale</p>
							<p className="text-darkGrey text-lg">Code</p>
							<p className="text-darkGrey text-lg">Image</p>
							<p className="text-darkGrey text-lg">Order</p>
							<p className="text-darkGrey text-lg">Points</p>
							<p className="text-darkGrey text-lg">Actions</p>
						</div>
					) : (
						<p className="text-red-500">No languages found.</p>
					)}
					{languages.length > 0 ? (
						<DragDropContext onDragEnd={handleDrop}>
							<Droppable droppableId="list-container">
								{(provided) => (
									<div {...provided.droppableProps} ref={provided.innerRef}>
										{languages?.map((item: any, index: number) => {
											const isEven = index % 2 === 0;
											console.log('item', item);
											return (
												<Draggable key={item.id} draggableId={item.id} index={index}>
													{(provided) => (
														<div ref={provided.innerRef} {...provided.draggableProps}>
															<div className={`grid grid-cols-7 gap-1 transition-colors  mt-2 ${isEven ? 'bg-slate' : 'bg-white'}`} {...provided.dragHandleProps}>
																<div className="flex flex-1 flex-row items-center justify-between col-span-1 p-[6px]">
																	<p className="text-darkGrey text-lg ">{item.name}</p>
																</div>
																<div className="flex flex-row items-center justify-between col-span-1 p-[6px]">
																	<p className="text-darkGrey text-lg ">{item.locale}</p>
																</div>
																<div className="flex flex-row items-center justify-between col-span-1 p-[6px]">
																	<p className="text-darkGrey text-lg">{item.code}</p>
																</div>
																<div className="flex flex-row items-center justify-between col-span-1 p-[6px]">
																	<img src={getImage(item?.flag?.id)} alt="image" width="50" />
																</div>
																<div className="flex flex-row items-center justify-between col-span-1 p-[6px]">
																	<p className="text-darkGrey text-lg">{item.priority}</p>
																</div>
																<div className="flex flex-row items-center justify-between col-span-1 p-[6px]">
																	<p className="text-darkGrey text-lg">{item.points}</p>
																</div>
																<div className="flex flex-row items-center col-span-1 p-[6px]">
																	<FaEdit style={{ width: 20, height: 20, marginRight: '10px', cursor: 'pointer' }} onClick={() => selectEditLanguage(item.id)} />
																	<FaTrash style={{ width: 20, height: 20, color: '#D24D57', cursor: 'pointer' }} onClick={() => deleteRowLanguage(item.id)} />
																</div>
															</div>
														</div>
													)}
												</Draggable>
											);
										})}
										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</DragDropContext>
					) : null}
				</div>
				{/* <div style={{ height: languages.length === 0 || isLoading ? '300px' : 'auto' }}>
					<DataGrid
						autoHeight
						className="rounded-lg border border-[#81848f3d] min-3/5 p-8 pb-4 mt-8 overflow-x-auto table-fixed"
						rows={languages}
						columns={columns}
						rowCount={rowCountState}
						loading={isLoading}
						pageSizeOptions={[5, 15, 25]}
						paginationModel={paginationModel}
						paginationMode="server"
						onPaginationModelChange={(newModel) => {
							setPaginationModel(newModel);
							fetchLanguages(newModel.page, newModel.pageSize, debouncedQuery);
						}}
					/>
				</div> */}
			</div>
			<EditLanguageModal open={addLanguageModalVisible} onClose={handleClose} languageId={selectedLanguage} />
		</div>
	);
};

export default Languages;
