import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { useForm } from 'react-hook-form';
import { Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import { createShippingOption, editShippingOption } from '../../server/server';
import { useUser } from '../../auth/UseUser';
import { ShippingOptionType } from '../../model/enums';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

function AddShippingOptionModal({ modalIsOpen, closeModal, zone, option, reloadOptions }: any) {
	const { user } = useUser();
	const [processing, setProcessing] = useState(false);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
		getValues,
		setValue,
		watch,
	} = useForm({
		defaultValues: {
			name: '',
			description: '',
			estimatedTime: '',
			type: 0,
			shipping_cost: undefined,
			min_amount: undefined,
			min_condition: undefined,
			max_condition: undefined,
			used: true,
		},
	});

	const optionType = watch('type');

	useEffect(() => {
		if (modalIsOpen) {
			ReactModal.setAppElement('#root');
		}
	}, [modalIsOpen]);

	useEffect(() => {
		if (!!option?.id) {
			setValue('type', option.type);
			setValue('name', option.name);
			setValue('description', option.description);
			setValue('estimatedTime', option.estimatedTime);
			setValue('shipping_cost', option.shipping_cost);
			setValue('min_amount', option.min_amount);
			setValue('max_condition', option.max_condition);
			setValue('min_condition', option.min_condition);
		}
	}, [option]);

	const onSubmitModal = async () => {
		let formValues = getValues();
		let newOption: any = {};
		newOption.zoneId = zone?.id;
		newOption.name = formValues.name;
		newOption.description = formValues.description;
		newOption.estimatedTime = formValues.estimatedTime;
		newOption.type = formValues.type;
		newOption.used = formValues.used;
		if (formValues.type === ShippingOptionType['FLAT RATE']) {
			newOption.shipping_cost = formValues.shipping_cost;
			newOption.max_condition = formValues.max_condition;
			newOption.min_condition = formValues.min_condition;
		} else if (formValues.type === ShippingOptionType['FREE SHIPPING']) {
			newOption.min_amount = formValues.min_amount;
		}
		console.log('new option ', newOption);
		if (!option?.id) {
			newOption.ordinal = !!zone?.shippingOptions ? zone?.shippingOptions?.length + 1 : 1;
			createNewOption(newOption);
		} else {
			newOption.id = option.id;
			newOption.ordinal = option.ordinal;
			editOption(newOption);
		}
	};
	const createNewOption = async (data: any) => {
		const resp = await createShippingOption(data);
		if (resp.status === 201) {
			closeModalAndReloadOptions();
		}
	};
	const editOption = async (data: any) => {
		const resp = await editShippingOption(data);
		if (resp.status === 201) {
			closeModalAndReloadOptions();
		}
	};

	const closeModalAndReloadOptions = () => {
		reloadOptions();
		closeModal();
	};

	return (
		<ReactModal
			ariaHideApp={false}
			//closeTimeoutMS={600}
			isOpen={modalIsOpen}
			onAfterOpen={() => {}}
			onAfterClose={() => reset()}
			onRequestClose={closeModal}
			className="no-scroll"
			style={{
				overlay: {
					zIndex: 5,
					backdropFilter: 'brightness(30%)',
				},
				content: {
					padding: 0,
					opacity: 1,
					width: 600,
					margin: 'auto',
					backgroundColor: 'transparent',
					height: '100%',
					maxHeight: '100%',
					border: 'none',
					boxShadow: 'none',
					overflow: 'auto',
					paddingTop: 15,
					paddingBottom: 15,
				},
			}}
		>
			<div className="   max-h-[600px] w-full  ">
				<form onSubmit={handleSubmit(onSubmitModal)} className=" flex flex-1 flex-col gap-5 rounded-[10px] bg-white  px-[40px] py-[30px] ">
					<div className="flex w-full flex-col ">
						<p className="text-[30px] font-semibold leading-[38px] text-black">{!!option?.id ? 'Edit option' : 'Add new option'}</p>
					</div>
					<div>
						<FormControl sx={{ width: '100%' }}>
							<InputLabel id="demo-multiple-name-label">Shipping method</InputLabel>
							<Select labelId="demo-multiple-name-label" value={optionType} id="demo-multiple-name" {...register('type', { required: true })} input={<OutlinedInput label="Shipping method" />} MenuProps={MenuProps}>
								<MenuItem value={ShippingOptionType['FLAT RATE']}>Flat rate</MenuItem>
								<MenuItem value={ShippingOptionType['FREE SHIPPING']}>Free shipping</MenuItem>
								<MenuItem value={ShippingOptionType['LOCAL PICKUP']}>Local pickup</MenuItem>
							</Select>
						</FormControl>
					</div>
					<div className="">
						<TextField {...register('name', { required: 'Field is required' })} helperText={errors.name?.message} error={!!errors.name} className="background-transparent w-full" label="Option name" variant="filled" />
					</div>
					<div className="">
						<TextField {...register('description', { required: 'Field is required' })} helperText={errors.description?.message} error={!!errors.description} className="background-transparent w-full" label="Description" variant="filled" />
					</div>
					<div className="">
						<TextField {...register('estimatedTime', { required: 'Field is required' })} helperText={errors.estimatedTime?.message} error={!!errors.estimatedTime} className="background-transparent w-full" label="Estimated delivery time" variant="filled" />
					</div>
					{optionType === ShippingOptionType['FLAT RATE'] && (
						<div className="mt-2">
							<div className="">
								<TextField
									type="text"
									{...register('shipping_cost', {
										required: 'Field is required',
										pattern: {
											value: /^[+-]?\d+(\.\d+)?$/, // Dozvoljava decimalni zapis broja
											message: 'Must be a numeric value',
										},
										validate: {
											greaterThanZero: (value: any) => parseFloat(value) > 0 || 'Must be greater than zero',
										},
									})}
									helperText={errors.shipping_cost?.message}
									error={!!errors.shipping_cost}
									className="background-transparent w-full"
									label="Cost"
									variant="filled"
								/>
							</div>
							<p className="my-2">Condition</p>
							<div className="">
								<TextField
									type="text"
									{...register('min_condition', {
										required: 'Field is required',
										pattern: {
											value: /^[+-]?\d+(\.\d+)?$/, // Dozvoljava decimalni zapis broja
											message: 'Must be a numeric value',
										},
										validate: {
											greaterThanZero: (value: any) => parseFloat(value) > 0 || 'Must be greater than zero',
										},
									})}
									helperText={errors.min_condition?.message}
									error={!!errors.min_condition}
									className="background-transparent w-full"
									label="More than:"
									variant="filled"
								/>
							</div>
							<div className="">
								<TextField
									type="text"
									{...register('max_condition', {
										required: 'Field is required',
										pattern: {
											value: /^[+-]?\d+(\.\d+)?$/, // Dozvoljava decimalni zapis broja
											message: 'Must be a numeric value',
										},
										validate: {
											greaterThanZero: (value: any) => parseFloat(value) > 0 || 'Must be greater than zero',
										},
									})}
									helperText={errors.max_condition?.message}
									error={!!errors.max_condition}
									className="background-transparent w-full"
									label="Less than:"
									variant="filled"
								/>
							</div>
						</div>
					)}
					{optionType === ShippingOptionType['FREE SHIPPING'] && (
						<div className="mt-2">
							<div className="">
								<TextField
									type="text"
									{...register('min_amount', {
										required: 'Field is required',
										pattern: {
											value: /^[+-]?\d+(\.\d+)?$/, // Dozvoljava decimalni zapis broja
											message: 'Must be a numeric value',
										},
										validate: {
											greaterThanZero: (value: any) => parseFloat(value) > 0 || 'Must be greater than zero',
										},
									})}
									helperText={errors.min_amount?.message}
									error={!!errors.min_amount}
									className="background-transparent w-full"
									id="filled-required"
									label="Minimum order amount"
									variant="filled"
								/>
							</div>
						</div>
					)}

					<div className="flex w-full flex-row justify-end gap-8 mt-10  ">
						<Button variant="contained" onClick={() => closeModal()} style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
							Cancel
						</Button>
						<Button type="submit" variant="contained" style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
							Submit
						</Button>
					</div>
				</form>
			</div>
		</ReactModal>
	);
}

export default AddShippingOptionModal;
