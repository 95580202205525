import React, { useEffect } from 'react';
import { Link, Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useUser } from '../../auth/UseUser';
import NavBar from '../NavBar';
import Footer from '../Footer';

function Layout() {
	let navigate = useNavigate();
	const { isSignedIn } = useUser();
	useEffect(() => {
		//	navigate('/products');
	}, []);

	return (
		<div className="home h-screen overflow-y-hidden overflow-x-hidden w-full">
			<div className="home-container w-full h-full flex">
				{isSignedIn && <NavBar />}
				<div className="outlet-container overflow-auto gray-small-scroll w-full h-full px-6">
					<Outlet />
				</div>
			</div>
		</div>
	);
}

export default Layout;
