import React, { useEffect, useState } from 'react';
import { Container, Button, Grid, TextField } from '@mui/material';
import MDEditor from '@uiw/react-md-editor';
import { createSsp, editSsp, getSsp } from '../../server/server';
import QuestionsAndAnswers from './QuestionsAndAnswers';

interface QA {
	id: string;
	question: string;
	answer: string;
	ordinal: number;
}

const SingleStaticPages = () => {
	const [terms, setTerms] = useState<string | undefined>('');
	const [aboutUs, setAboutUs] = useState<string | undefined>('');
	const [QA, setQA] = useState<QA[]>([]);
	const [privacy, setPrivacy] = useState<string | undefined>('');
	const [id, setId] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const [isTermsActive, setIsTermsActive] = useState(true);
	const [isAboutActive, setIsAboutActive] = useState(false);
	const [isFaqActive, setIsFaqActive] = useState(false);
	const [isPrivacyActive, setIsPrivacyActive] = useState(false);

	const [isEdit, setIsEdit] = useState(false);

	const [dropdownActive, setDropDownActive] = useState<{ [key: string]: boolean }>({});

	useEffect(() => {
		fetchSsp();
	}, []);

	const fetchSsp = async () => {
		setIsLoading(true);
		const resp = await getSsp();

		if (resp.status === 200 && Array.isArray(resp.data) && resp.data.length > 0) {
			setTerms(resp.data[0].terms || '');
			setAboutUs(resp.data[0].aboutUs || '');
			let sortedQA = [...resp.data[0].questionsAndAnswers].sort((a, b) => a.ordinal - b.ordinal);
			setQA(sortedQA);
			setPrivacy(resp.data[0].privacyPolicy || '');
			setId(resp.data[0].id);

			setIsEdit(true);
		} else if (resp.status === 200) {
			setTerms('');
			setAboutUs('');
			setQA([]);
			setPrivacy('');
		} else {
			alert(`Error fetching information: ${resp.status}`);
		}
		setIsLoading(false);
	};

	const saveChanges = async () => {
		if (isFaqActive && QA.some((qa) => qa.question.trim() === '' || qa.answer.trim() === '')) {
			alert('Each question must have a non-empty question and answer.');
			return;
		}

		if (isEdit) {
			const payload = {
				terms: isTermsActive ? terms || '' : undefined,
				aboutUs: isAboutActive ? aboutUs || '' : undefined,
				questionAndAnswer: isFaqActive ? QA.map((item) => ({ id: item.id, question: item.question, answer: item.answer })) : undefined,
				privacyPolicy: isPrivacyActive ? privacy || '' : undefined,
			};
			const resp = await editSsp(id, payload);
			if (resp.status === 200) {
				alert('Changes saved successfully.');
				fetchSsp();
			} else {
				alert(`Error saving changes: ${resp.status}`);
			}
		} else {
			const payload = {
				terms: isTermsActive ? terms || '' : undefined,
				aboutUs: isAboutActive ? aboutUs || '' : undefined,
				questionAndAnswer: isFaqActive ? QA.map((item) => ({ question: item.question, answer: item.answer })) : undefined,
				privacyPolicy: isPrivacyActive ? privacy || '' : undefined,
			};

			const resp = await createSsp(payload);
			if (resp.status === 201) {
				alert('New record created successfully.');
				fetchSsp();
			} else {
				alert(`Error creating a new record: ${resp.status}`);
			}
		}
	};

	const handleValueChange = (value: any) => {
		if (isTermsActive) {
			setTerms(value);
		} else if (isAboutActive) {
			setAboutUs(value);
		} else if (isPrivacyActive) {
			setPrivacy(value);
		}
	};

	const clearField = () => {
		if (isTermsActive) {
			setTerms('');
		} else if (isAboutActive) {
			setAboutUs('');
		} else if (isPrivacyActive) {
			setPrivacy('');
		}
	};

	const handleAddNewQA = () => {
		const newOrdinal = QA.length > 0 ? QA[QA.length - 1].ordinal + 1 : 1;
		const newQA: QA = {
			id: Date.now().toString(),
			question: '',
			answer: '',
			ordinal: newOrdinal,
		};

		setQA((prevState) => [...prevState, newQA]);
		setDropDownActive((prevState) => ({ ...prevState, [newQA.id]: true }));
	};

	return (
		<div>
			{isLoading && (
				<div
					style={{
						position: 'fixed',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						backgroundColor: 'rgba(255, 255, 255, 0.5)',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						zIndex: 999,
					}}
				>
					Loading...
				</div>
			)}
			<div className="grid grid-rows-2 py-10 h-full px-2 overflow-auto gray-small-scroll ">
				<div className="h-full flex justify-center">
					<div className="h-full flex">
						<div className="flex flex-row h-full items-center">
							<div className="m-5">
								<Button
									variant="contained"
									color={isTermsActive ? 'secondary' : 'primary'}
									onClick={() => {
										setIsTermsActive(true);
										setIsAboutActive(false);
										setIsFaqActive(false);
										setIsPrivacyActive(false);
									}}
								>
									Terms
								</Button>
							</div>
							<div className="m-5">
								<Button
									variant="contained"
									color={isAboutActive ? 'secondary' : 'primary'}
									className="m-5"
									onClick={() => {
										setIsTermsActive(false);
										setIsAboutActive(true);
										setIsFaqActive(false);
										setIsPrivacyActive(false);
									}}
								>
									About Us
								</Button>
							</div>
							<div className="m-5">
								<Button
									variant="contained"
									color={isFaqActive ? 'secondary' : 'primary'}
									className="m-5"
									onClick={() => {
										setIsTermsActive(false);
										setIsAboutActive(false);
										setIsFaqActive(true);
										setIsPrivacyActive(false);
									}}
								>
									FAQ
								</Button>
							</div>
							<div className="m-5">
								<Button
									variant="contained"
									color={isPrivacyActive ? 'secondary' : 'primary'}
									className="m-5"
									onClick={() => {
										setIsTermsActive(false);
										setIsAboutActive(false);
										setIsFaqActive(false);
										setIsPrivacyActive(true);
									}}
								>
									Privacy Policy
								</Button>
							</div>
						</div>
					</div>
				</div>
				<hr />

				<div>
					<div className="mb-2 flex justify-between">
						{isFaqActive ? (
							<Button variant="contained" onClick={handleAddNewQA}>
								Add New QA Pair
							</Button>
						) : (
							<Button onClick={clearField} variant="contained">
								Clear field
							</Button>
						)}

						{!isFaqActive ? (
							<Button variant="contained" onClick={saveChanges}>
								Save changes
							</Button>
						) : (
							''
						)}
					</div>
					<div>
						{isTermsActive && <MDEditor value={terms} onChange={handleValueChange} style={{ backgroundColor: 'transparent', color: 'rgb(94, 99, 155)', minHeight: '500px' }} />}
						{isAboutActive && <MDEditor value={aboutUs} onChange={handleValueChange} style={{ backgroundColor: 'transparent', color: 'rgb(94, 99, 155)', minHeight: '500px' }} />}
						{isFaqActive && <QuestionsAndAnswers QA={QA} setQA={setQA} fetchSsp={fetchSsp} sspId={id} />}
						{isPrivacyActive && <MDEditor value={privacy} onChange={handleValueChange} style={{ backgroundColor: 'transparent', color: 'rgb(94, 99, 155)', minHeight: '500px' }} />}
					</div>
				</div>
			</div>
		</div>
	);
};

export default SingleStaticPages;
