import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';

function PrintingLayerSelect({ layers = [], value, changeValue = () => {} }: { layers: string[]; value: string; changeValue: (e: string) => void }) {
	return (
		<FormControl variant="outlined" className="max-w-[300px] w-full">
			<InputLabel id="alignment">Printing layer</InputLabel>
			<Select labelId="alignment" label="Printing layer" value={value} onChange={(e) => changeValue(e.target.value)}>
				{layers.map((layer) => (
					<MenuItem key={layer} value={layer}>
						{layer}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}

export default PrintingLayerSelect;
