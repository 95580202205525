import { Button, TextField } from '@mui/material';
import { GridColDef, GridActionsCellItem, DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import AddFontsModal from './AddFontsModal';
import { deleteFontById, getAllFonts, getFonts, getFontsPagination, getImage } from '../../server/server';
import useDebounce from '../../hooks/useDebounce';
import { createGlobalStyle } from 'styled-components';

function Fonts() {
	const [rowCountState, setRowCountState] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [addNewFontsModalOpen, setAddNewFontsModalOpen] = useState(false);
	const [selectedFont, setSelectedFont] = useState(null);
	const [fonts, setFonts] = useState([]);
	const [searchQuery, setSearchQuery] = useState('');
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10,
	});
	const searchDelay = 700;
	const debouncedQuery = useDebounce(searchQuery, searchDelay);
	const [allFonts, setAllFonts] = useState([]);

	useEffect(() => {
		if (fonts.length !== 0) loadFonts();
	}, [fonts]);

	useEffect(() => {
		loadFontsList(paginationModel.page, paginationModel.pageSize, debouncedQuery);
	}, [paginationModel.page, paginationModel.pageSize, debouncedQuery]);

	const closeAddNewFontsModal = () => {
		setAddNewFontsModalOpen(false);
		setSelectedFont(null);
	};

	const openEditFontModal = (item: any) => {
		setSelectedFont(item);
		setAddNewFontsModalOpen(true);
	};

	const loadFontsList = async (page: any, pageSize: any, searchQuery?: any) => {
		setIsLoading(true);
		const resp = await getFontsPagination(pageSize, page * pageSize, searchQuery);
		if (resp.status === 200) {
			setFonts(resp.data.data);
			setRowCountState(resp.data.size);
			setIsLoading(false);
		} else {
			setIsLoading(false);
		}
	};

	const deleteFont = async (data: any) => {
		if (window.confirm('Do you want to delete this font?')) {
			const respSymbol = await deleteFontById(data);
			if (respSymbol.status === 200) {
				reloadFonts();
			}
		}
	};

	const reloadFonts = () => {
		if (paginationModel.page === 0) {
			loadFontsList(paginationModel.page, paginationModel.pageSize, debouncedQuery);
		} else {
			setPaginationModel({
				page: 0,
				pageSize: 10,
			});
		}
	};

	const handleSearchQueryChange = (e: any) => {
		setSearchQuery(e.target.value);
	};

	const loadFonts = async () => {
		const resp = await getAllFonts();
		if (resp.status === 200) {
			setAllFonts(resp.data);
			console.log('fonts are loaded.');
		} else {
			console.log('fonts are not loaded.');
		}
	};

	const GlobalStyle2 = createGlobalStyle`
	body { ${allFonts.map((font: any) => {
		return `
	@font-face {
		font-family: ${font.name};
		src: url(${getImage(font.imageId)}) format('truetype');
		font-style: normal;
		font-weight: 500;
	}
	
	`;
	})} }
	`;

	const columns: GridColDef[] = [
		{ field: 'name', headerName: 'Name', width: 200, flex: 1 },
		{ field: 'sample', headerName: 'Font sample', width: 200, flex: 2, renderCell: (params) => <p style={{ fontFamily: params.row?.name, fontSize: 20 }}>Quick brown fox jumps over the lazy dog</p> },
		{
			field: 'actions',
			headerName: 'Actions',
			type: 'actions',
			width: 100,
			getActions: (params) => [
				<GridActionsCellItem icon={<FaEdit style={{ width: 20, height: 20 }} />} label="Edit" onClick={() => openEditFontModal(params.row)} />,
				<GridActionsCellItem
					icon={<FaTrash style={{ width: 20, height: 20, color: '#D24D57' }} />}
					label="Delete"
					onClick={() => {
						deleteFont(params.id);
					}}
				/>,
			],
		},
	];

	return (
		<div>
			<GlobalStyle2 />
			<div className="flex flex-row items-center justify-between border border-[#aeaeae] p-2 mt-5 shadow-md">
				<TextField
					label="Search"
					value={searchQuery}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						handleSearchQueryChange(event);
					}}
				/>
				<Button variant="contained" onClick={() => setAddNewFontsModalOpen(true)} style={{ backgroundColor: '#2ec4b6', color: 'white', marginRight: 20 }}>
					Add new font
				</Button>
			</div>
			<div className="rounded-lg border border-[#81848f3d] min-w-fit px-8 py-5 mt-5">
				<div className=" h-fit w-full">
					<DataGrid
						autoHeight
						rowCount={rowCountState}
						loading={isLoading}
						disableRowSelectionOnClick
						disableColumnFilter
						disableColumnMenu
						pagination
						rows={fonts}
						columns={[...columns]}
						showCellVerticalBorder={false}
						pageSizeOptions={[10, 20, 30]}
						paginationModel={paginationModel}
						paginationMode="server"
						onPaginationModelChange={(newModel) => {
							if (newModel.pageSize !== paginationModel.pageSize) {
								setPaginationModel({
									page: 0,
									pageSize: newModel.pageSize,
								});
								return;
							} else {
								setPaginationModel(newModel);
							}
						}}
					/>
				</div>
			</div>
			<AddFontsModal modalIsOpen={addNewFontsModalOpen} closeModal={closeAddNewFontsModal} font={selectedFont} reloadFonts={reloadFonts} />
		</div>
	);
}

export default Fonts;
