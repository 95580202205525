import React, { useState } from 'react';
import { WithContext as ReactTags } from 'react-tag-input';

const KeyCodes = {
	comma: 188,
	enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

function ProductTagsForm({ tags, setTags, product, setProduct }: { product: any; setProduct: any; tags: any; setTags: any }) {
	const handleDelete = (i: number) => {
		const newTags = tags.filter((tag: any, index: number) => index !== i);
		setTags(newTags);
		setProduct({ ...product, tags: newTags.map((item: any) => item.text) });
	};

	const handleAddition = (tag: any) => {
		const newTags = [...tags, tag].map((item: any) => item.text);
		setProduct({ ...product, tags: newTags });
	};

	const handleDrag = (tag: any, currPos: number, newPos: number) => {
		const newTags = tags.slice();

		newTags.splice(currPos, 1);
		newTags.splice(newPos, 0, tag);

		// re-render
		setTags(newTags);
		setProduct({ ...product, tags: newTags.map((item: any) => item.text) });
	};

	const handleTagClick = (index: number) => {
		console.log('The tag at index ' + index + ' was clicked');
	};

	return (
		<div className="flex  w-full flex-col mt-6 border border-mediumGrey rounded-md p-[30px] gap-5">
			<p className="text-darkGrey text-lg  px-2">Tags</p>
			<ReactTags
				tags={tags}
				//suggestions={suggestions}
				delimiters={delimiters}
				handleDelete={handleDelete}
				handleAddition={handleAddition}
				handleDrag={handleDrag}
				handleTagClick={handleTagClick}
				inputFieldPosition="top"
				autofocus={false}
				allowUnique
			/>
		</div>
	);
}

export default ProductTagsForm;
