import { Button, TextField } from '@mui/material';
import MDEditor from '@uiw/react-md-editor';
import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp, FaTrash } from 'react-icons/fa';
import { createQAPair, deleteQAPair, editQAPair, editQAPairOrder } from '../../server/server';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

interface QA {
	id: string;
	question: string;
	answer: string;
	ordinal: number;
}

interface QuestionsAndAnswersProps {
	QA: QA[];
	setQA: React.Dispatch<React.SetStateAction<QA[]>>;
	fetchSsp: () => void;
	sspId: string;
}

const QuestionsAndAnswers: React.FC<QuestionsAndAnswersProps> = ({ QA, setQA, fetchSsp, sspId }) => {
	const [dropdownActive, setDropDownActive] = useState<{ [key: string]: boolean }>({});

	if (!QA) {
		return <div>No questions available</div>;
	}

	const handleDeleteQaPair = async (id: string) => {
		const isConfirmed = window.confirm('Are you sure you want to delete this QA pair?');
		if (isConfirmed) {
			if (id !== '') {
				const resp = await deleteQAPair(id);

				if (resp.status == 200) {
					alert('QA pair deleted successfully ');
					fetchSsp();
				}
			} else {
				setQA(QA.filter((item) => item.id !== ''));
			}
		}
	};

	const saveQAPair = async (id: string, question: string, answer: string, ordinal: number) => {
		if (id.length <= 13) {
			const payload = {
				sspId,
				question,
				answer,
				ordinal,
			};

			const response = await createQAPair(payload);
			if (response.status === 201) {
				alert('New QA pair created successfully');
				fetchSsp();
			}
		} else {
			const payload = {
				id,
				question,
				answer,
			};

			const response = await editQAPair(payload);
			if (response.status === 200) {
				alert('QA pair updated successfully');
				fetchSsp();
			}
		}
	};

	const handleDrop = async (droppedItem: any) => {
		// Ignore drop outside droppable container
		if (!droppedItem.destination) return;
		var updatedList = [...QA];

		// Remove dragged item
		const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
		// Add dropped item
		updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
		let newReorderList = updatedList.map((item, index) => {
			return { ...item, ordinal: index + 1 };
		});
		// Update State
		setQA(newReorderList);
		const resp = await editQAPairOrder({ questionsAndAnswers: newReorderList });
		console.log(resp);
		if (resp.status !== 200) {
			alert(`Error changing payment QA pair order: ${resp.status}`);
		}
	};

	const toggleDropdown = (id: string) => {
		console.log(id);
		setDropDownActive((prevState) => ({
			...prevState,
			[id]: !prevState[id],
		}));

		console.log(dropdownActive);
	};

	return (
		<div className="p-[10px]">
			<DragDropContext onDragEnd={handleDrop}>
				<Droppable droppableId="list-container">
					{(provided) => (
						<div {...provided.droppableProps} ref={provided.innerRef}>
							{QA.map((item, index) => {
								return (
									<Draggable key={index.toString()} draggableId={index.toString()} index={index}>
										{(provided) => (
											<div ref={provided.innerRef} {...provided.draggableProps} key={item.id} onClick={() => toggleDropdown(item.id)}>
												<div {...provided.dragHandleProps} className={`flex flex-col justify-between justify-items-center w-full  cursor-pointer bg-babyBlue rounded-[12px] mt-[20px] border border-black`} style={{ userSelect: 'none' }}>
													<div className={`flex flex-row justify-between items-center min-h-[64px] p-[20px] ${dropdownActive[item.id] ? 'border-b border-black' : ''}`}>
														{dropdownActive[item.id] ? (
															<div className="w-full flex items-center justify-between">
																<TextField
																	className="text-base font-notoSans font-normal text-black border-none outline-none flex-1"
																	type="text"
																	style={{ marginRight: '6px' }}
																	placeholder="Question:"
																	value={item?.question}
																	onClick={(e) => e.stopPropagation()}
																	onChange={(e) => {
																		const newValue = e.target.value;
																		setQA((prevQA) => {
																			const updatedQA = [...prevQA];
																			updatedQA[index] = { ...updatedQA[index], question: newValue };
																			return updatedQA;
																		});
																	}}
																/>

																<Button className="mr-[6px] h-[40px]" variant="contained" onClick={() => saveQAPair(item.id, item.question, item.answer, item.ordinal)}>
																	Save QA pair
																</Button>

																<Button className="mr-[6px]" style={{ marginRight: '6px' }} onClick={() => handleDeleteQaPair(item.id)}>
																	<FaTrash style={{ width: 30, height: 30, color: '#D24D57' }} />
																</Button>
															</div>
														) : (
															<span className="text-base font-notoSans font-normal text-black">{item?.question}</span>
														)}
														<div className="">{!dropdownActive[item.id] ? <FaChevronDown /> : <FaChevronUp />}</div>
													</div>

													{dropdownActive[item.id] && (
														<div className="p-[20px]" onClick={(e) => e.stopPropagation()}>
															<MDEditor
																value={item?.answer}
																placeholder="Answer:"
																onChange={(value: string | undefined, _event?: React.ChangeEvent<HTMLTextAreaElement> | undefined, _state?: any | undefined) => {
																	const newValue = value || '';
																	setQA((prevQA) => {
																		const updatedQA = [...prevQA];
																		if (updatedQA[index]) {
																			updatedQA[index] = { ...updatedQA[index], answer: newValue };
																		}
																		return updatedQA;
																	});
																}}
															/>
														</div>
													)}
												</div>
											</div>
										)}
									</Draggable>
								);
							})}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</div>
	);
};

export default QuestionsAndAnswers;
