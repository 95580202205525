import React, { useState } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import ConnectProductToOptionMapModal from './ConnectProductToOptionMapModal';

function OptionMap({ optionMaps, product, selectedOption, reloadMaps }: { optionMaps: any; product: any; selectedOption: any; reloadMaps: any }) {
	const [selectedMap, setSelectedMap] = useState<any>(null);
	const [connectModalOpen, setConnectModalOpen] = useState(false);

	const connectOptionMap = (option: any) => {
		setConnectModalOpen(true);
		setSelectedMap(option);
	};

	const closeConnectModal = () => {
		setConnectModalOpen(false);
	};

	return (
		<div className="flex flex-col">
			<p className="text-black text-xl ">Options maps</p>
			<div className="h-[200px] w-full mt-2 overflow-auto gray-small-scroll pr-2">
				{optionMaps?.map((option: any, index: number) => (
					<div
						className={`flex w-full flex-row items-center justify-between p-2 mb-1 gap-5 border border-gray rounded-lg cursor-pointer ${selectedMap?.id === option?.id ? 'bg-babyBlue' : 'bg-white'}`}
						onClick={() => {
							connectOptionMap(option);
						}}
						key={index}
					>
						<div>
							<p className="text-black text-base">Map name: {option?.name}</p>
							<p className="text-black text-sm">Product name: {!!option?.map ? option.map?.product?.name : 'Not connected'}</p>
						</div>
						<div className="flex flex-row items-center gap-3">
							{!!option?.color && <div className={`w-5 h-5 rounded-md border border-gray`} style={{ backgroundColor: option.color }}></div>}
							<div>
								<FaEdit style={{ width: 20, height: 20 }} />
							</div>
						</div>
					</div>
				))}
			</div>

			<ConnectProductToOptionMapModal modalIsOpen={connectModalOpen} closeModal={closeConnectModal} product={product} selectedOption={selectedOption} reloadMaps={reloadMaps} selectedMap={selectedMap} />
		</div>
	);
}

export default OptionMap;
