import React, { useState } from 'react';
import { Button, Card, CardContent, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { Order, OrderComment as OrderNote, User } from '../OrderInterfaces';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { createOrderComment, deleteOrderComment } from '../../../server/server';

interface OrderNotesProps {
	onFetchOrderDetails: () => void;
	order: Order;
	user: User;
}

const OrderNotes: React.FC<OrderNotesProps> = ({ order, onFetchOrderDetails, user }) => {
	const [latestCommentIndex, setLatestCommentIndex] = useState<number>(0);

	const [isPrivateNote, setIsPrivateNote] = useState<string>('true');
	const [noteText, setNoteText] = useState<string>('');

	const sortedComments = [...(order?.comments || [])].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

	const latestComment = sortedComments[latestCommentIndex];

	const handleUpArrowClick = () => {
		const totalComments = order?.comments?.length || 0;
		if (latestCommentIndex < totalComments - 1) {
			setLatestCommentIndex((prevIndex) => prevIndex + 1);
		}
	};

	const handleDownArrowClick = () => {
		if (latestCommentIndex > 0) {
			setLatestCommentIndex((prevIndex) => prevIndex - 1);
		}
	};

	const handleDeleteComment = async (commentId: any) => {
		if (!commentId) return;
		const resp = await deleteOrderComment(commentId);

		if (resp.status == 200) {
			const deletedCommentIndex = sortedComments.findIndex((comment) => comment.id === commentId);

			if (deletedCommentIndex > -1) {
				setLatestCommentIndex((prevIndex) => {
					return deletedCommentIndex === prevIndex && deletedCommentIndex > 0 ? deletedCommentIndex - 1 : prevIndex;
				});
			}

			onFetchOrderDetails();
		} else {
			alert(`Error deleting comment: ${resp.status}`);
		}
	};

	const handleSelectChange = (event: SelectChangeEvent<string>) => {
		setIsPrivateNote(event.target.value);
	};

	const handleSubmit = async (event: any) => {
		event.preventDefault();
		let bool: boolean;

		if (isPrivateNote === 'true') {
			bool = true;
		} else {
			bool = false;
		}

		const currentDate = new Date();
		const newComment = {
			writtenByUserId: user.id,
			commentText: noteText,
			commentedAt: currentDate.toISOString(),
			isPrivate: bool,
			orderId: order.id,
		};
		const resp = await createOrderComment(newComment);
		if (resp.status === 201) {
			setNoteText('');
			onFetchOrderDetails();
		}
	};

	return (
		<Card>
			<div>
				<div className="flex justify-between items-center mt-2">
					<h3 className="ml-2">Order notes</h3>
					<div className="flex mr-4">
						<FaAngleUp className={`mr-2 cursor-pointer`} onClick={handleUpArrowClick} />
						<FaAngleDown className={`cursor-pointer`} onClick={handleDownArrowClick} />
					</div>
				</div>
				<hr className="ml-2 mr-2" />
				{latestComment && (<div className="p-4">
					<div className="bg-gray p-3 rounded-lg">
						<p className="text-sm text-gray-700">{latestComment?.commentText}</p>
					</div>
					<div className="flex">

						<p className="text-disabled text-xs mt-3">
							Commented on:{' '}
							{!!latestComment?.createdAt &&
								new Intl.DateTimeFormat('en-US', {
									year: 'numeric',
									month: 'long',
									day: 'numeric',
									hour: 'numeric',
									minute: 'numeric',
								}).format(new Date(latestComment?.createdAt))}
						</p>
						<div className="mt-[2px]">
							<Button onClick={() => handleDeleteComment(latestComment?.id)} className="text-red">
								Delete note
							</Button>
						</div>
					</div>
				</div>)}

				<hr className="ml-2 mr-2" />
				<div className="ml-2 mt-2 w-5/6">
					<div>
						<div className="mb-2">
							<h3>Add note:</h3>
						</div>
						<div className="ml-2">
							<textarea value={noteText} placeholder="Note..." onChange={(e) => setNoteText(e.target.value)} className="w-full border rounded p-2 resize-none"></textarea>
						</div>
					</div>
					<div className="flex p-2">
						<div>
							<Select value={isPrivateNote} placeholder="Private Note" onChange={handleSelectChange} className="h-9 m-2">
								<MenuItem value="true">Private Note</MenuItem>
								<MenuItem value="false">Public Note</MenuItem>
							</Select>
						</div>
						<div className="m-2">
							<Button variant="contained" onClick={handleSubmit} disabled={!noteText}>
								Add note
							</Button>
						</div>
					</div>
				</div>
			</div>
		</Card>
	);
};

export default OrderNotes;
