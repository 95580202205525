import React from 'react';
import { TextPosition } from './ManageSlider';
import { getImage } from '../../server/server';

interface Slider {
	id?: string;
	name: string;
	description: string;
	color: string;
	image: string;
	link: string;
	textPosition: TextPosition;
}

const SliderPreview = ({ slider }: { slider: Slider }) => {
	return (
		<div className={`relative flex ${slider.textPosition === 'right' ? 'justify-end' : 'justify-start'} min-h-[119px] max-h-[120px] min-w-[300px] max-w-[350px] bg-white shadow-md rounded`}>
			<img src={getImage(slider.image)} alt={slider.name} className="absolute h-full w-full object-fill" />
			<div className="absolute p-4">
				<h2 className="text-lg font-bold" style={{ color: slider.color }}>
					{slider.name}
				</h2>
				<p className="text-sm" style={{ color: slider.color }}>
					{slider.description}
				</p>
			</div>
		</div>
	);
};

export default SliderPreview;
