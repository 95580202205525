import { Button, FormControlLabel, FormHelperText, IconButton, Input, InputLabel, Switch, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ChromePicker, ColorResult } from 'react-color';
import { FaTrash } from 'react-icons/fa';
import { createSlider, uploadFile, editSlider, getSliderById, getSliders, editSliderOrder, getImage } from '../../server/server';
import { useNavigate, useParams } from 'react-router-dom';
import SliderPreview from './SliderPreview';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FaChevronLeft } from 'react-icons/fa';
import rgbHex from 'rgb-hex';
import { URL } from 'url';
import AddProductsImageModal from '../products/new-product/AddProductsImageModal';

export enum TextPosition {
	left = 'left',
	right = 'right',
}

export interface Slider {
	id?: string;
	name: string;
	description: string;
	color: string;
	link: string;
	imageId: string;
	textPosition: TextPosition;
	disabled: boolean;
	ordinal: number | null;
}

let initialSlider: Slider = {
	id: '',
	name: '',
	description: '',
	link: '',
	color: '#FFFFFF',
	imageId: '',
	textPosition: TextPosition.left,
	disabled: false,
	ordinal: null,
};

const ManageSlider = () => {
	const [selectedColor, setSelectedColor] = useState({
		h: 250,
		s: 0,
		l: 0.2,
		a: 1,
	});
	const [sliderFiles, setSliderFiles] = useState<File[]>([]);
	const [sliders, setSliders] = useState<Slider[]>([]);
	const [slider, setSlider] = useState<Slider>(initialSlider);
	const [fileModalOpen, setFileModalOpen] = useState(false);
	const [imageId, setImageId] = useState('');

	const { id } = useParams();
	const [isEdit, setIsEdit] = useState(!!id);

	const [nameValid, setNameValid] = useState(true);
	const [descriptionValid, setDescriptionValid] = useState(true);
	const [linkValid, setLinkValid] = useState(true);
	const [ordinalValid, setOrdinalValid] = useState(true);
	const [imageValid, setImageValid] = useState(true);

	const navigate = useNavigate();

	useEffect(() => {
		if (isEdit) {
			fetchSliderInformation(id);
			fetchAllSliders();
		}
	}, [id]);

	useEffect(() => {
		fetchAllSliders();
	}, []);

	function hexToHSLA(H: string): { h: number; s: number; l: number; a: number } {
		let r = 0,
			g = 0,
			b = 0,
			a = 1;
		if (H.length == 5) {
			r = parseInt(H[1] + H[1], 16);
			g = parseInt(H[2] + H[2], 16);
			b = parseInt(H[3] + H[3], 16);
			a = parseInt(H[4] + H[4], 16);
		} else if (H.length == 9) {
			r = parseInt(H[1] + H[2], 16);
			g = parseInt(H[3] + H[4], 16);
			b = parseInt(H[5] + H[6], 16);
			a = parseInt(H[7] + H[8], 16);
		}
		r /= 255;
		g /= 255;
		b /= 255;
		a /= 255;
		let max = Math.max(r, g, b),
			min = Math.min(r, g, b);
		let h = 0,
			s: number,
			l = (max + min) / 2;

		if (max == min) {
			h = s = 0;
		} else {
			let d = max - min;
			s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
			switch (max) {
				case r:
					h = (g - b) / d + (g < b ? 6 : 0);
					break;
				case g:
					h = (b - r) / d + 2;
					break;
				case b:
					h = (r - g) / d + 4;
					break;
			}
			h /= 6;
		}
		return { h: h * 360, s: s * 100, l: l * 100, a: a };
	}
	const fetchAllSliders = async () => {
		const resp = await getSliders();

		if (resp.status == 200) {
			setSliders(resp.data);
		} else {
			alert(`Error fetching sliders: ${resp.status}`);
		}
	};

	const fetchSliderInformation = async (id: any) => {
		const resp = await getSliderById(id);
		const sliderData = resp.data;
		console.log('sliderData:', sliderData);

		if (resp.status == 200) {
			const hsla = hexToHSLA(sliderData.color);

			setSlider({
				id: sliderData.id,
				name: sliderData.name,
				description: sliderData.description,
				imageId: sliderData.image?.id,
				link: sliderData.link,
				color: sliderData.color,
				textPosition: sliderData.textPosition,
				disabled: sliderData.disabled,
				ordinal: sliderData.ordinal,
			});

			setSelectedColor(hsla);

			if (sliderData.image?.id === null) {
				setImageId('');
			} else {
				setImageId(sliderData.image.id);
			}

			setSelectedColor(sliderData.color);
		} else alert(`Error fetching slider: ${resp.status}`);
	};

	const handleColorChange = (color: ColorResult) => {
		const hex = '#' + rgbHex(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a);
		console.log(hex);
		setSelectedColor({
			h: color.hsl.h,
			s: color.hsl.s,
			l: color.hsl.l,
			a: color.hsl.a || 1,
		});
		setSlider({
			...slider,
			color: hex,
		});
	};

	function handleRemoveImage() {
		setSliderFiles([]);
		const inputElement = document.getElementById('slider-image-input');
		if (inputElement) {
			(inputElement as HTMLInputElement).value = '';
		}
	}

	const openAddFileModal = () => {
		setFileModalOpen(true);
	};

	const handleSubmit = async () => {
		if (nameValid && descriptionValid && linkValid && imageId !== '') {
			if (isEdit) {
				const updatedSlider = {
					name: slider.name,
					description: slider.description,
					color: slider.color,
					link: slider.link,
					textPosition: slider.textPosition,
					ordinal: slider.ordinal,
					disabled: slider.disabled,
					imageId: imageId,
				};

				await editSlider(slider.id, updatedSlider)
					.then((resp) => {
						if (resp.status === 200) {
							alert('Slider updated successfully.');
							navigate('/sliders');
						} else {
							alert(`Error editing the slider: ${resp}`);
						}
					})
					.catch((error) => {
						alert('Error updating slider: ' + error.message);
					});
			} else {
				const newSlider: Slider = {
					name: slider.name,
					description: slider.description,
					color: slider.color,
					link: slider.link,
					imageId: imageId,
					textPosition: slider.textPosition,
					ordinal: slider.ordinal,
					disabled: slider.disabled,
				};

				await createSlider(newSlider)
					.then((resp) => {
						if (resp.status == 201) {
							alert('Slider created successfully.');
							navigate('/sliders');
						} else {
							alert(`Error creating the slider: ${resp.status}`);
						}
					})
					.catch((error) => {
						alert('Error creating slider: ' + error.message);
					});
			}
		} else {
			alert('Please fill the fields marked with *');
			setNameValid(false);
			setDescriptionValid(false);
			setLinkValid(false);
			setImageValid(false);
		}
	};

	const handleFieldChange = (event: any, field: keyof Slider | string) => {
		if (field === 'disabled') {
			setSlider((prevSlider) => ({
				...prevSlider,
				[field]: event.target.checked,
			}));
		} else if (field === 'textPosition') {
			setSlider((prevSlider) => ({
				...prevSlider,
				[field]: event.target.checked ? TextPosition.right : TextPosition.left,
			}));
		} else if (field === 'ordinal') {
			const ordinalValue = parseInt(event.target.value, 10);
			const isValidOrdinal = !isNaN(ordinalValue) && ordinalValue > 0;
			setOrdinalValid(isValidOrdinal);

			if (event.target.value === '') {
				setSlider((prevSlider) => ({
					...prevSlider,
					[field]: null,
				}));
			} else if (isValidOrdinal) {
				setSlider((prevSlider) => ({
					...prevSlider,
					[field]: ordinalValue,
				}));
			}
		} else {
			setSlider((prevSlider) => ({
				...prevSlider,
				[field]: event.target.value,
			}));
		}

		switch (field) {
			case 'name':
				setNameValid(event.target.value !== '');
				break;
			case 'description':
				setDescriptionValid(event.target.value !== '');
				break;
			case 'link':
				setLinkValid(event.target.value !== '');
				break;
			case 'ordinal':
				setOrdinalValid(event.target.value !== '');
				break;
			default:
				break;
		}
	};
	const handleImageChange = async (file: any) => {
		const resp = await uploadFile({ file: file });
		if (resp.status === 201) {
			setFileModalOpen(false);
			console.log(resp.data.id);
			setImageId(resp.data.id);
		}
	};

	const goBack = () => {
		navigate(-1);
	};

	return (
		<div className="py-10 px-4 overflow-auto h-full space-y-6 grid grid-cols-2">
			<div>
				<div className="flex flex-row items-center gap-5 rounded-md shadow-darkGrey shadow-md mb-8">
					<button onClick={goBack} className="flex flex-row gap-2 items-center ml-5">
						<FaChevronLeft /> Back
					</button>
					<div className="text-2xl font-semibold text-black p-5 ">{isEdit ? 'Edit slider' : ' Add new slider'}</div>
				</div>
				<div className="grid grid-rows-7 gap-y-2">
					<div className="w-4/5">
						<TextField label="Name *" className="w-full" value={slider.name} onChange={(e: any) => handleFieldChange(e, 'name')} error={!nameValid} helperText={!nameValid && 'Name is required'} />
					</div>
					<div className="w-4/5">
						<TextField label="Description *" className="w-full" value={slider.description} onChange={(e: any) => handleFieldChange(e, 'description')} error={!descriptionValid} helperText={!descriptionValid && 'Description is required'} />
					</div>
					<div className="w-4/5">
						<TextField label="Link *" className="w-full" value={slider.link} onChange={(e: any) => handleFieldChange(e, 'link')} error={!linkValid} helperText={!linkValid && 'Link is required'} />
					</div>
					<div className="w-4/5 flex">
						<div className="ml-10 mr-5">
							<InputLabel>Slider color *</InputLabel>
							<ChromePicker className="w-full" disableAlpha={false} color={selectedColor} onChange={handleColorChange} />
						</div>
						<div className="m-5">
							<TextField label="Ordinal *" value={slider.ordinal} InputLabelProps={{ shrink: !!slider.ordinal }} onChange={(e: any) => handleFieldChange(e, 'ordinal')} />
						</div>
					</div>
					<div className="w-4/5">
						<div className="mt-[5px]">
							<InputLabel htmlFor="slider-image-input" style={{ color: imageValid ? '#aeaeae' : '#ff0000' }}>
								Slider Image *
							</InputLabel>
							<Button onClick={openAddFileModal} variant="contained" color="primary" size="medium" className="w-full py-2 text-xl font-bold min-h-[61px]">
								Add image
							</Button>
							<AddProductsImageModal modalIsOpen={fileModalOpen} closeModal={() => setFileModalOpen(false)} setSelectedFileToList={handleImageChange} isWithinLanguageModal={true} />
							{!imageValid && <FormHelperText style={{ color: '#ff0000' }}>Image is required</FormHelperText>}
						</div>
						<div className="flex space-x-2 mt-2">{imageId && <img src={getImage(imageId)} alt="slider-file" style={{ width: '100px', height: '60%' }} />}</div>
						{sliderFiles.length > 0 && (
							<IconButton onClick={() => handleRemoveImage()}>
								<FaTrash />
							</IconButton>
						)}
					</div>
					<div>
						<FormControlLabel control={<Switch checked={slider.disabled} onChange={(e: any) => handleFieldChange(e, 'disabled')} />} label="Disabled?" />
						<FormControlLabel control={<Switch checked={slider.textPosition === 'right'} onChange={(e: any) => handleFieldChange(e, 'textPosition')} />} label="Text left or right" />
					</div>
					<Button className="w-4/5" color="primary" variant="contained" onClick={handleSubmit}>
						Save changes
					</Button>
				</div>
			</div>
			<div className="col-start-2 col-end-3">
				<div className="flex items-center justify-center h-full">
					{imageId && (
						<SliderPreview
							slider={{
								name: slider.name,
								description: slider.description,
								color: slider.color,
								image: imageId,
								textPosition: slider.textPosition,
								link: 'slider.link',
							}}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default ManageSlider;
